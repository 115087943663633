import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { Store } from '@ngrx/store';

import { AppState } from '@app/store/app.state';
import { AuthSelectors } from '@app/store/selectors';

import { CP_PERMISSIONS } from '../app.constants';

@Directive({ selector: '[appFeature]' })
export class FeatureDirective {
  constructor(
    private store: Store<AppState>,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) {}

  @Input() set appFeature(featureName: string) {
    this.store
      .select(AuthSelectors.getUserPermissions)
      .subscribe((userPermissions: string[]) => {
        this.viewContainer.clear();
        if (this.shouldDisplayFeature(featureName, userPermissions)) {
          this.viewContainer.createEmbeddedView(this.templateRef);
        }
      });
  }

  private shouldDisplayFeature(
    featureName: string,
    userPermissions: string[]
  ): boolean {
    switch (featureName) {
      case 'Dashboard':
        return userPermissions.includes(CP_PERMISSIONS.DASHBOARD_READ);

      case 'SiteMap':
        return (
          userPermissions.includes(CP_PERMISSIONS.DASHBOARD_READ) &&
          userPermissions.includes(CP_PERMISSIONS.SITES_SITEINFO_READ)
        );

      case 'CasesSummary':
        return (
          userPermissions.includes(CP_PERMISSIONS.DASHBOARD_READ) &&
          (userPermissions.includes(CP_PERMISSIONS.SUPPORTREQUEST_READ) ||
            userPermissions.includes(CP_PERMISSIONS.SERVICEREQUEST_READ))
        );

      case 'ServicesCalendar':
        return (
          userPermissions.includes(CP_PERMISSIONS.DASHBOARD_READ) &&
          userPermissions.includes(CP_PERMISSIONS.SITES_SERVICESCHEDULE_READ)
        );

      case 'Sites':
        return (
          userPermissions.includes(CP_PERMISSIONS.SITES_SITEINFO_READ) &&
          userPermissions.includes(CP_PERMISSIONS.SITES_SERVICESCHEDULE_READ)
        );

      case 'AllSites':
        return (
          userPermissions.includes(CP_PERMISSIONS.SITES_SITEINFO_READ) &&
          userPermissions.includes(CP_PERMISSIONS.SITES_SERVICESCHEDULE_READ)
        );

      case 'OnetimeServices':
        return userPermissions.includes(CP_PERMISSIONS.ONETIME_READ);

      case 'Sensors':
        return (
          userPermissions.includes(CP_PERMISSIONS.SENSORS_PELLODATA_READ) ||
          userPermissions.includes(CP_PERMISSIONS.SENSORS_SEQUOIADATA_READ)
        );
      case 'Operations':
        return false;
      case 'Analytics':
        return true;

      case 'AnalyticsReports':
        return userPermissions.includes(CP_PERMISSIONS.ANALYTICS_REPORTS_READ);

      case 'AnalyticsSustainability':
        return userPermissions.includes(
          CP_PERMISSIONS.ANALYTICS_SUSTAINABILITY_READ
        );

      case 'AnalyticsSensors':
        return userPermissions.includes(CP_PERMISSIONS.ANALYTICS_SENSORS_READ);

      case 'Support':
      case 'ViewCases':
        return (
          userPermissions.includes(CP_PERMISSIONS.SUPPORTREQUEST_READ) ||
          userPermissions.includes(CP_PERMISSIONS.SERVICEREQUEST_READ)
        );

      case 'Invoices':
        return userPermissions.includes(CP_PERMISSIONS.INVOICES_READ);

      case 'RequestService':
        return userPermissions.includes(CP_PERMISSIONS.SERVICEREQUEST_WRITE);

      case 'RequestSupport':
        return userPermissions.includes(CP_PERMISSIONS.SUPPORTREQUEST_WRITE);

      case 'ServiceChange':
        return userPermissions.includes(CP_PERMISSIONS.SERVICECHANGE_WRITE);

      case 'FollowUp':
        return (
          userPermissions.includes(CP_PERMISSIONS.SUPPORTREQUEST_WRITE) ||
          userPermissions.includes(CP_PERMISSIONS.SERVICEREQUEST_WRITE)
        );

      case 'Create - Select Schedule A info':
        return userPermissions.includes(CP_PERMISSIONS.SITES_SCHEDULE_READ);

      case 'Documents':
      case 'ViewDocuments':
        return userPermissions.includes(CP_PERMISSIONS.DOCUMENTS_READ);
      case '118100006': // Missed Service
      case '118100002': // Damaged/Replace Equipment
      case '118100007': // Incidents/Accidents
        return true;
      case '118100000': // Finance
        return userPermissions.includes(CP_PERMISSIONS.INVOICES_READ);
      case '118100014': // Sustainability
        return userPermissions.includes(
          CP_PERMISSIONS.SUPPORTREQUEST_SUSTAINABILITY_WRITE
        );
      case '118100016': //Reporting
        return userPermissions.includes(
          CP_PERMISSIONS.SUPPORTREQUEST_REPORTING_WRITE
        );
      case 'SiteDetailsHaulerName':
        return userPermissions.includes(CP_PERMISSIONS.HAULERINVOICES_READ);
      case 'HaulerContractDocuments':
        return userPermissions.includes(CP_PERMISSIONS.HAULERCONTRACT_READ);
      case 'VendorInvoice':
        return userPermissions.includes(CP_PERMISSIONS.VENDORINVOICE_READ);
      default:
        return false;
    }
  }
}
