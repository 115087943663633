<footer class="footer-main">
  <div class="container">
    <div class="row">
      <div class="col text-center">
        <a class="header-logo" [routerLink]="'/'">
          <img src="../assets/images/Logo.png" />
        </a>
        <p>&#169; {{ todaysDate | date: 'y' }}</p>
      </div>
    </div>
  </div>
</footer>
