import { createAction, props } from '@ngrx/store';
import { BillingAccountsReqObj } from '@app/core/models/UserDataModel';
import { WorkOrderModel, WorkOrderDetailsModel } from '@app/core/models/workorder.model';


export const getWorkOrderDetails = createAction(
  '[WorkOrder] Get WorkOrder Details',
  props<{ workOrderId: string; }>()
);

export const getWorkOrderDetailsSuccess = createAction(
  '[WorkOrder] Get WorkOrder Details Success',
  props<{ currentWorkOrder: WorkOrderDetailsModel }>()
);

export const getWorkOrderDetailsFailure = createAction(
  '[WorkOrder] Get WorkOrder Details Failure',
  props<{ errorMsg: string }>()
);


export const getWorkOrders = createAction(
  '[WorkOrder] Get Work Orders',
  props<{ requestBody: BillingAccountsReqObj }>()
);

export const getWorkOrdersSuccess = createAction(
  '[WorkOrder] Get Work Orders Success',
  props<{ allWorkOrder: WorkOrderModel[] }>()
);

export const getWorkOrdersFailure = createAction(
  '[WorkOrder] Get Work Orders Failure',
  props<{ errorMsg: string }>()
);

export const workOrderStartLoading = createAction('[WorkOrder] Start Work Order Loading');

export const workOrderStopLoading = createAction('[WorkOrder] Stop Work Order Loading');

export const currentWorkOrderStartLoading = createAction(
  '[WorkOrder] Current Work Order Start Loading'
);


export const getWorkOrdersWithProducts = createAction(
  '[WorkOrder] Get Work Orders With Products',
  props<{ requestBody: BillingAccountsReqObj }>()
);

export const getWorkOrdersWithProductsSuccess = createAction(
  '[WorkOrder] Get Work Orders With Products Success',
  props<{ allWorkOrdersWithProducts: WorkOrderModel[] }>()
);

export const getWorkOrdersWithProductsFailure = createAction(
  '[WorkOrder] Get Work Orders with Products Failure',
  props<{ errorMsg: string }>()
);