import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '@app/store/app.state';
import { WorkOrderDetailsModel } from '@app/core/models/workorder.model';
import { WorkOrderSelectors } from '@app/store';

@Component({
  selector: 'app-workorder-details-modal',
  templateUrl: './workorder-details-modal.component.html',
  styleUrls: ['./workorder-details-modal.component.scss'],
})
export class WorkorderDetailsModalComponent implements OnInit, OnDestroy {
  constructor(private store: Store<AppState>) {
  }
  subscriptions: Subscription = new Subscription();

  workOrder$: Observable<WorkOrderDetailsModel> = this.store.select(
    WorkOrderSelectors.getCurrentWorkOrderForMap
  );

  isCurrentWorkOrderLoading$: Observable<boolean> = this.store.select(
    WorkOrderSelectors.isCurrentWorkOrderLoading
  );


  workOrder: WorkOrderDetailsModel;
  ngOnInit(): void {
    this.subscriptions.add(
      this.workOrder$.subscribe(workOrder => {
        if (workOrder && workOrder.workOrderId != null)
          this.workOrder = workOrder;
      })
    );
  }

  closeDialog(): void {
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
