import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ContentfulState } from '@app/store';

export const selectContentfulSlice =
  createFeatureSelector<ContentfulState>('contentful');

export const getWhatsNew = createSelector(
  selectContentfulSlice,
  (state: ContentfulState) => state.whatsNew
);

export const getTermsAndConditions = createSelector(
  selectContentfulSlice,
  (state: ContentfulState) => state.termsAndConditions
);

export const getPrivacyPolicy = createSelector(
  selectContentfulSlice,
  (state: ContentfulState) => state.privacyPolicy
);

export const hasSeenWhatsNew = createSelector(
  selectContentfulSlice,
  (state: ContentfulState) => state.whatsNew.hasSeen
);
