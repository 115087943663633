import { TwilioActions } from '@app/store';
import { createReducer, on } from '@ngrx/store';

export const twilioFeatureKey = 'twilio';

export interface TwilioState {
  isPhoneValidationSent?: boolean;
  isPhoneValidated?: boolean;
  errorMsg?: any;
}

const initialState: TwilioState = {
  isPhoneValidationSent: false,
  isPhoneValidated: false,
  errorMsg: null,
};

export const twilioReducer = createReducer(
  initialState,
  on(TwilioActions.verifyPhoneNumberSuccess, (state, { response }) => ({
    ...state,
    isPhoneValidationSent: response.isValid,
    errorMsg: response.errors,
  })),
  on(TwilioActions.verifyPhoneNumberFailure, (state, { errorMsg }) => ({
    ...state,
    isPhoneValidationSent: false,
    errorMsg: errorMsg,
  })),
  on(TwilioActions.verifyOTPSuccess, (state, { response }) => ({
    ...state,
    isPhoneValidated: response.isValid,
    errorMsg: response.errors,
  })),
  on(TwilioActions.verifyOTPFailure, (state, { errorMsg }) => ({
    ...state,
    isPhoneValidated: false,
    errorMsg: errorMsg,
  }))
);
