<div class="dialog-container">
  <mat-dialog-content class="row">
    <a href="#" tabindex="0"></a
    ><!--fix for chrome auto scrolling to the first link on the popup-->
    <div class="col-12 modal-title text-center">
      RTS Terms & Conditions and Privacy Policy
    </div>
    <app-legal-document type="terms"> </app-legal-document>
    <app-legal-document type="privacy"> </app-legal-document>
  </mat-dialog-content>
  <button class="button-center" (click)="closeDialog()" mat-dialog-close>
    I agree to the above terms
  </button>
</div>
