import { Injectable } from '@angular/core';
import { environment as env } from '@env/environment';
import { createClient } from 'contentful';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { ContentfulModel } from '@app/core/models/contentful.model';

// link about contentful query options: https://www.contentful.com/developers/docs/references/content-delivery-api/#/reference/search-parameters

@Injectable({ providedIn: 'root' })
export class ContentfulService {
  private cdaClient = createClient({
    space: env.contentfulSpaceId,
    accessToken: env.contentfulToken,
    environment: env.contentfulEnvironment,
  });

  constructor() {}

  // returns the most recent UPDATED entry that contains the customerPortal tag
  getLatestByContentType(contentType: string): Promise<ContentfulModel> {
    return this.cdaClient
      .getEntries({
        content_type: contentType,
        order: '-sys.updatedAt',
        limit: 1,
        'metadata.tags.sys.id[in]': 'customerPortal',
      })
      .then(response => {
        type Document = {
          nodeType?: any;
          title?: any;
          content?: any;
          data?: any;
        };

        let content: ContentfulModel;

        if (response.items[0]) {
          content = {
            // compilation error with dot-notation but works fine when calling by key. Likely related to unknown object type
            // eslint-disable-next-line dot-notation
            title: response.items[0].fields['title'],
            content: documentToHtmlString(
              (response.items[0].fields as Document).content
            ),
            contentPostId: response.items[0].sys.id,
            updatedDate: response.items[0].sys.updatedAt,
          } as ContentfulModel;
        } else {
          content = null;
        }

        return content;
      });
  }
}
