<div class="container-fluid p-0 background-wrapper" [ngClass]="styleClass">
  <div class="row flex-nowrap no-gutters">
    <div
      id="hero-image-div"
      class="d-none d-md-flex hero-image-div"
      [ngClass]="heroImageCol ? 'col-'+heroImageCol : ''">
      <img
        id="hero-image"
        [src]="heroImageCol ? selectedCase?.id === '118100000' ? 'assets/images/ticket_internal_finance.png' : 'assets/images/ticket_internal.png' : 'assets/images/log-in-img.png'"
        alt="Recycle Track Systems" />
    </div>
    <div
      id="logo-and-content"
      class="logo-and-content d-flex justify-content-center align-items-center">
      <div class="logo-and-content-container">
        <img
          class="logo"
          [src]="showSubNav ? (heroImageCol ? 'assets/images/rts-logo.svg' : 'assets/images/support-desk.svg') : 'assets/images/logo-how-help.svg'"
          alt="Recycle Track Systems"
          [ngStyle]="{
            'width': styleClass?.includes('selectTicket') ? '22rem' : styleClass?.includes('createTicket') ? '6rem' : '22rem'
          }" />
        <app-top-sub-nav
          [title]="title"
          [showBackButton]="showBackButton"
          [backButtonUrl]="backButtonUrl"
          [backButtonQueryParams]="backButtonQueryParams"
          [hideSupportButtons]="hideSupportButtons"
          [styleClass]="styleClass"
          *ngIf="showSubNav">
        </app-top-sub-nav>
        <ng-content class="page-content"></ng-content>
      </div>
    </div>
  </div>
</div>
