import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss'],
})
export class DateRangePickerComponent implements OnInit {
  @Input() dateRangeForm: UntypedFormGroup;
  @Input() maxDate: Date;
  @Input() startDate: Date;
  @Input() endDate: Date;
  @Input() label: string;
  @Input() startPlaceholder: string;
  @Input() endPlaceholder: string;
  @Input() isDateRangeValid: boolean = false;

  constructor() {}

  ngOnInit(): void {}
}
