import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '@app/store/app.state';
import { GlobalActions } from '@app/store';
import { Router, RoutesRecognized } from '@angular/router';
import { filter, pairwise } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class RouteHistoryService {
  constructor(
    public store: Store<AppState>,
    private router: Router
  ) {}

  watchRoutes() {
    this.router.events
      .pipe(
        filter((evt: any) => evt instanceof RoutesRecognized),
        pairwise()
      )
      .subscribe((events: RoutesRecognized[]) => {
        // first item in events is always the previousURL, second would be current
        const previousURL = events[0].urlAfterRedirects;

        this.store.dispatch(GlobalActions.setPreviousURL({ previousURL }));
      });
  }
}
