import { WorkOrderActions } from '@app/store/actions';
import { createReducer, on } from '@ngrx/store';
import { WorkOrderModel, WorkOrderDetailsModel } from '@app/core/models/workorder.model';

export const workOrderFeatureKey = 'workorder';

export interface WorkOrderState {
    currentWorkOrder: WorkOrderDetailsModel | null;
    allWorkOrder: WorkOrderModel[] | null;
    currentWorkOrderLoading: boolean;
    workOrderLoading: boolean;
    workOrderError: string | null;
    allWorkOrdersWithProducts: WorkOrderModel[] | null;
}

const initialState: WorkOrderState = {
    currentWorkOrder: null,
    allWorkOrder: null,
    workOrderLoading: true,
    workOrderError: null,
    currentWorkOrderLoading: true,
    allWorkOrdersWithProducts: null
};

export const workOrderReducer = createReducer(
    initialState,
    on(WorkOrderActions.getWorkOrderDetailsSuccess, (state, { currentWorkOrder }) => ({
        ...state,
        currentWorkOrderLoading: false,
        currentWorkOrder,
    })),
    on(WorkOrderActions.getWorkOrdersSuccess, (state, { allWorkOrder }) => ({
        ...state,
        workOrderLoadingLoading: false,
        allWorkOrder,
    })),
    on(WorkOrderActions.getWorkOrdersFailure, (state, { errorMsg }) => ({
        ...state,
        workOrderLoadingLoading: false,
        workOrderError: errorMsg,
    })),
    on(WorkOrderActions.workOrderStartLoading, state => ({
        ...state,
        workOrderLoading: true,
    })),
    on(WorkOrderActions.workOrderStopLoading, state => ({
        ...state,
        workOrderLoading: false,
    })),
    on(WorkOrderActions.currentWorkOrderStartLoading, state => ({
        ...state,
        currentWorkOrderLoading: true,
    })),
    on(WorkOrderActions.getWorkOrdersWithProductsSuccess, (state, { allWorkOrdersWithProducts }) => ({
        ...state,
        allWorkOrdersWithProducts,
    })),
    on(WorkOrderActions.getWorkOrdersWithProductsFailure, (state, { errorMsg }) => ({
        ...state,
        workOrderError: errorMsg,
    })),
)