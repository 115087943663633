import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment as env } from '@env/environment';

@Component({
  selector: 'app-error-model',
  templateUrl: './error-modal.component.html',
  styleUrls: ['./error-modal.component.scss'],
})
export class ErrorModalComponent {
  error: any;
  errorMessage: string;
  buttonText = 'OK';
  customerServiceEmail = env.customerServiceEmail;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.error = data.error;
    if (this.error) {
      this.errorMessage =
        typeof this.error === 'string' ? this.error : this.error.message;
      this.buttonText = 'OK';
    }
  }
}
