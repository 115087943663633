import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AzureBaseService } from '@app/core/services/azure-functions/azure-base.service';

@Injectable({ providedIn: 'root' })
export class SendEmailService extends AzureBaseService {
  constructor(protected http: HttpClient) {
    super(http, '/func-customerportal-sendgrid-');
  }

  // Commented: enable if required for future
  // sendEmail(body: Record<string, unknown>): Observable<HttpResponse<any>> {
  //   const url =
  //     this.azureBaseUrl + this.functionUri + this.azureEnv + '/SendEmail';

  //   return this.http.post<any>(url, body, this.httpOptions);
  // }

  sendSensorsDemoRequest(email: string): Observable<HttpResponse<any>> {
    const url = `${this.azureBaseUrl}${this.functionUri}${this.azureEnv}/SendPelloDemoRequestEmail`;

    return this.http.post<HttpResponse<any>>(url, [email], this.httpOptions);
  }

  sendAnalyticsDemoRequest(email: string): Observable<HttpResponse<any>> {
    const url = `${this.azureBaseUrl}${this.functionUri}${this.azureEnv}/SendAnalyticsReportingAccessEmail`;

    return this.http.post<HttpResponse<any>>(url, [email], this.httpOptions);
  }
}
