<div class="collapsible-container">
  <button
    type="button"
    class="collapsible"
    [ngClass]="{ active: isOpen }"
    (click)="toggleSection()">
    {{ buttonTitle }}
  </button>
  <div
    id="{{ contentId }}"
    class="content"
    [@animateCollapsible]="isOpen ? 'show' : 'hide'">
    <ng-container *ngIf="title">
      <h4 class="title">{{ title }}</h4>
      <br />
    </ng-container>

    <ng-container *ngIf="date">
      <div>Last Modified: {{ datePipe.transform(date, 'longDate') }}</div>
      <br />
    </ng-container>

    <div [innerHTML]="content"></div>
  </div>
</div>
