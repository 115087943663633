import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';

import { ContentfulService } from '@app/core/services/contentful/contentful.service';
import { ContentfulActions } from '@app/store';
import { ContentfulModel } from '@app/core/models/contentful.model';

@Injectable()
export class ContentfulEffects {
  constructor(
    private actions$: Actions,
    private contentfulService: ContentfulService
  ) {}

  getWhatsNew$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContentfulActions.getWhatsNew),
      switchMap(() =>
        from(this.contentfulService.getLatestByContentType('whatsNew')).pipe(
          map((whatsNew: ContentfulModel) =>
            ContentfulActions.getWhatsNewSuccess({ whatsNew })
          ),
          catchError(err =>
            of(
              ContentfulActions.getWhatsNewFailure({ errorMsg: err.message() })
            )
          )
        )
      )
    )
  );

  getTermsAndConditions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContentfulActions.getTermsAndConditions),
      switchMap(() =>
        from(
          this.contentfulService.getLatestByContentType('termsAndConditions')
        ).pipe(
          map((termsAndConditions: ContentfulModel) =>
            ContentfulActions.getTermsAndConditionsSuccess({
              termsAndConditions,
            })
          ),
          catchError(err =>
            of(
              ContentfulActions.getTermsAndConditionsFailure({
                errorMsg: err.message(),
              })
            )
          )
        )
      )
    )
  );

  getPrivacyPolicy$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContentfulActions.getPrivacyPolicy),
      switchMap(() =>
        from(
          this.contentfulService.getLatestByContentType('privacyPolicy')
        ).pipe(
          map((privacyPolicy: ContentfulModel) =>
            ContentfulActions.getPrivacyPolicySuccess({ privacyPolicy })
          ),
          catchError(err =>
            of(
              ContentfulActions.getPrivacyPolicyFailure({
                errorMsg: err.message(),
              })
            )
          )
        )
      )
    )
  );
}
