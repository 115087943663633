export interface ServiceSchedule {
  customerId?: string;
  accountId?: string;
  scheduleId?: string;
  rtsCompanyId?: string;
  accountName?: string;
  expirationDate?: string;
  effectiveDate?: string;
  monday?: boolean;
  tuesday?: boolean;
  wednesday?: boolean;
  thursday?: boolean;
  friday?: boolean;
  saturday?: boolean;
  sunday?: boolean;
  debris?: string;
  accountServiceEquipment?: string;
  status?: string;
  isRecurringService?: boolean;
  serviceFrequency?: ServiceSchedule.ServiceFrequencyEnum;
  quantity?: number;
  //dayPart?: string;
  targetIn?: string;
  targetOut?: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zip?: string;
  lastScheduled?: string;
  nextScheduled?: string;

  // Recurring
  serviceType: string;
  recurringFrequency?: number;
  repeat: 'Weekly' | 'Monthly' | 'Yearly' | null;
  repeatByCode:
    | 'Day of the Week'
    | 'Day of the Month'
    | 'Day of the Week and Month'
    | null;
  repeatOn: string | null;

  // Weekly
  everyWeeklySpecfiedDay: string | null;
  everySpecifiedDays: string | null;

  //Month
  everySpecifiedMonths: string | null;
  dayOfWeek: string | null;

  //Year
  dayOfMonth: string | null;
  monthOfYear: string | null;
}
export namespace ServiceSchedule {
  export type ServiceFrequencyEnum = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
  export const ServiceFrequencyEnum = {
    NUMBER_0: 0 as ServiceFrequencyEnum,
    NUMBER_1: 1 as ServiceFrequencyEnum,
    NUMBER_2: 2 as ServiceFrequencyEnum,
    NUMBER_3: 3 as ServiceFrequencyEnum,
    NUMBER_4: 4 as ServiceFrequencyEnum,
    NUMBER_5: 5 as ServiceFrequencyEnum,
    NUMBER_6: 6 as ServiceFrequencyEnum,
    NUMBER_7: 7 as ServiceFrequencyEnum,
    NUMBER_8: 8 as ServiceFrequencyEnum,
    NUMBER_9: 9 as ServiceFrequencyEnum,
  };
}
