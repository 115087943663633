import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class MuniSupportService {
  private wasteStreamOptions: { value: string; text: string }[] = [
    { text: 'Bulk Waste Pickup', value: 'Bulk Waste Pickup' },
    { text: 'Commingled Recycling', value: 'Commingled Recycling' },
    { text: 'Composting', value: 'Composting' },
    { text: 'Paper Recycling', value: 'Paper Recycling' },
    { text: 'Recycle Yard Waste', value: 'Recycle Yard Waste' },
    { text: 'Trash', value: 'Trash' },
  ];

  private equipmentTypeOptions: {
    value: string;
    text: string;
    options: string[];
  }[] = [
    {
      text: 'Bags',
      value: 'Bags',
      options: ['1/2 yd', '1 yd', '32 gal', '64 gal', '96 gal'],
    },
    {
      text: 'Totes',
      value: 'Totes',
      options: ['16 gal', '32 gal', '64 gal', '96 gal'],
    },
  ];

  private options = ['Missed Service', 'Bulk Pickup', 'ETA Request'];

  private municipalities: {
    value: number;
    text: string;
    state: string;
    options: string[];
  }[] = [
    {
      text: 'Livingston',
      value: 1,
      state: 'NJ',
      options: [...this.options, 'Schedule a Rear Yard Pickup'],
    },
    { text: 'Mendham', value: 2, state: 'NJ', options: this.options },
    { text: 'Hawthorne', value: 3, state: 'NJ', options: this.options },
    { text: 'Pennsville', value: 4, state: 'NJ', options: this.options },
    { text: 'Matawan', value: 5, state: 'NJ', options: this.options },
    { text: 'Kingsford Heights', value: 6, state: 'IN', options: this.options },
  ];

  constructor() {}

  getWasteStreamOptions() {
    return this.wasteStreamOptions;
  }

  getEquipmentTypeOptions() {
    return this.equipmentTypeOptions;
  }

  getMunicipalities() {
    return this.municipalities;
  }
}
