import { AzureBaseService } from '@app/core/services/azure-functions/azure-base.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  PaymentIntentRequestModel,
  PaymentIntentUpdateModel,
} from '@app/core/models/payment-intent.model';
import { PaymentIntent } from '@stripe/stripe-js';

@Injectable({ providedIn: 'root' })
export class BillingService extends AzureBaseService {
  constructor(protected http: HttpClient) {
    super(http, '/func-portal-guest-billing-api-');
    this.httpOptions.headers = (this.httpOptions.headers as HttpHeaders).delete(
      'Ocp-Apim-Subscription-Key'
    );
  }

  createStripePaymentIntent(
    paymentIntent: PaymentIntentRequestModel,
    token: string
  ): Observable<PaymentIntent> {
    const requestUrl = `${this.azureBaseUrl}${this.functionUri}${this.azureEnv}/payment-intent`;

    this.httpOptions.headers = (this.httpOptions.headers as HttpHeaders).set(
      'Authorization',
      `Bearer ${token}`
    );

    return this.http
      .post<HttpResponse<any>>(requestUrl, paymentIntent, this.httpOptions)
      .pipe(map(response => JSON.parse(response.body) as PaymentIntent));
  }

  updateStripePaymentIntent(
    paymentIntent: PaymentIntentUpdateModel,
    token: string
  ): Observable<PaymentIntent> {
    const requestUrl = `${this.azureBaseUrl}${this.functionUri}${this.azureEnv}/payment-intent/${paymentIntent.paymentIntentId}`;

    this.httpOptions.headers = (this.httpOptions.headers as HttpHeaders).set(
      'Authorization',
      `Bearer ${token}`
    );

    return this.http
      .post<HttpResponse<any>>(requestUrl, paymentIntent, this.httpOptions)
      .pipe(map(response => JSON.parse(response.body) as PaymentIntent));
  }
}
