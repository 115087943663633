import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  PhoneVerification,
  PhoneVerificationResponse,
} from '@app/core/models/twilio.model';
import { AzureBaseService } from '@app/core/services/azure-functions/azure-base.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class TwilioService extends AzureBaseService {
  constructor(protected http: HttpClient) {
    super(http, '/func-portal-api-');
    this.httpOptions.headers = (this.httpOptions.headers as HttpHeaders).delete(
      'Ocp-Apim-Subscription-Key'
    );
  }

  verifyPhoneNumber(
    requestObj: PhoneVerification
  ): Observable<PhoneVerificationResponse> {
    const requestUrl = `${this.azureBaseUrl}${this.functionUri}${this.azureEnv}/phoneverification`;

    return this.http
      .post<HttpResponse<any>>(requestUrl, requestObj, this.httpOptions)
      .pipe(
        map(response => JSON.parse(response.body) as PhoneVerificationResponse)
      );
  }

  verifyOTP(
    requestObj: PhoneVerification
  ): Observable<PhoneVerificationResponse> {
    const requestUrl = `${this.azureBaseUrl}${this.functionUri}${this.azureEnv}/checkphoneverificationcode`;

    return this.http
      .post<HttpResponse<any>>(requestUrl, requestObj, this.httpOptions)
      .pipe(
        map(response => JSON.parse(response.body) as PhoneVerificationResponse)
      );
  }
}
