import { AnalyticsEffects } from './analytics.effects';
import { AuthEffects } from './auth.effects';
import { BillingEffects } from './billing.effects';
import { CaseEffects } from './case.effects';
import { ContainerDeviceEffects } from './container-device.effects';
import { ContentfulEffects } from './contentful.effects';
import { CorporateEffects } from './corporate.effects';
import { DocumentsEffects } from './documents.effects';
import { InvoicesEffects } from './invoices.effects';
import { ServiceEffects } from './service.effects';
import { SiteEffects } from './site.effects';
import { UserDataEffects } from './user-data.effects';
import { WorkOrderEffects } from './workorder.effects';

// used in main app.module
export const effects: any[] = [
  AuthEffects,
  CorporateEffects,
  ServiceEffects,
  SiteEffects,
  ContainerDeviceEffects,
  BillingEffects,
  ContentfulEffects,
  UserDataEffects,
  CaseEffects,
  DocumentsEffects,
  InvoicesEffects,
  AnalyticsEffects,
  WorkOrderEffects,
];

export * from './analytics.effects';
export * from './auth.effects';
export * from './billing.effects';
export * from './case.effects';
export * from './container-device.effects';
export * from './contentful.effects';
export * from './corporate.effects';
export * from './documents.effects';
export * from './invoices.effects';
export * from './service.effects';
export * from './site.effects';
export * from './user-data.effects';
export * from './workorder.effects';
