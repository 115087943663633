import { BillingAccount, Site } from '@app/core/models/UserDataModel';
import { createAction, props } from '@ngrx/store';

export const showSpinner = createAction('[Global] Show Spinner');

export const hideSpinner = createAction('[Global] Hide Spinner');

export const setPreviousURL = createAction(
  '[Global] Set Previous URL',
  props<{ previousURL: string }>()
);

export const toggleSideNav = createAction('[Global] Toggle Side Navigation');

export const showError = createAction(
  '[Global] Show Error',
  props<{ errorMsg: string }>()
);

export const hideError = createAction('[Global] Hide Error');

export const setGlobalFilters = createAction(
  '[Global] Set Global Filters',
  props<{ billingAccounts: BillingAccount[] }>()
);

export const toggleBillingAccountSelection = createAction(
  '[Menu] Toggle Billing Account Selection',
  props<{ billingAccount: BillingAccount; site: Site }>()
);
