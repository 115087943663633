import { createAction, props } from '@ngrx/store';
import { ServiceDetailResponse } from '@app/core/api';

export const getServices = createAction(
  '[Service] Get Services',
  props<{ mitAccessAccountId: string }>()
);

export const getServicesSuccess = createAction(
  '[Service] Get Services Success',
  props<{ allServices: ServiceDetailResponse[] }>()
);

export const getServicesFailure = createAction(
  '[Service] Get Services Failure',
  props<{ errorMsg: string }>()
);
