<mat-dialog-content style="padding: 0">
  <button
    class="close"
    mat-icon-button
    aria-label="close dialog"
    mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
  <iframe
    [src]="msformUrl"
    width="100%"
    [height]="height"
    frameborder="0"></iframe>
</mat-dialog-content>
