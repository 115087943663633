import { Component, Input } from '@angular/core';
import { ErrorStateMatcher } from '@angular/material/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-text-area-input',
  templateUrl: './text-area-input.component.html',
  styleUrls: ['./text-area-input.component.scss'],
})
export class TextAreaInputComponent {
  @Input() parentForm: UntypedFormGroup;
  @Input() controlName: string;
  @Input() placeholder: string;
  @Input() optional: boolean;
  @Input() maxLength: number = 4999;
  @Input() showErrors: boolean = false;
  @Input() errorStateMatcher: ErrorStateMatcher = null;
  @Input() label?: string = null;

  constructor() {}
}
