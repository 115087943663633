import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { SerializedRouterStateSnapshot } from '@ngrx/router-store';

import { AppState } from '@app/store/app.state';
import { RouterSelectors, AnalyticsActions } from '@app/store';
import { PageLink } from '@app/core/models/page-link';
import { onSideNavChange, animateText } from '@app/_animations/animations';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
  animations: [onSideNavChange, animateText],
})
export class SideNavComponent implements OnInit, OnDestroy {
  @Input() pages: PageLink[];
  @Input() isSideNavOpen: boolean;
  activecMenu: string = '';
  activeSubItem: string = '';
  activeSubItemsList: string = '';

  routerState$: Observable<SerializedRouterStateSnapshot> = this.store.select(
    RouterSelectors.getRouterState
  );

  constructor(
    private store: Store<AppState>,
    public auth: MsalService,
    @Inject(DOCUMENT) private doc: Document
  ) {}

  ngOnInit(): void {
    this.fetchReportsList();
  }

  logout(e) {
    this.auth.logout({ postLogoutRedirectUri: this.doc.location.origin });

    // allows auth to actually end user session and redirect
    // without immediately logging the user back in
    e.preventDefault();
  }
  private fetchReportsList() {
    this.store.dispatch(AnalyticsActions.getReportList());
  }
  showPageSubLink(menuName: string) {
    if (this.activecMenu == menuName) {
      this.activecMenu = '';
    } else this.activecMenu = menuName;
  }
  activeSubMenu(subItem: string) {
    if (this.activeSubItem == subItem) {
      this.activeSubItem = '';
    } else this.activeSubItem = subItem;
  }
  showSubItem(subItem: string) {
    if (this.activeSubItemsList == subItem) {
      this.activeSubItemsList = '';
    } else this.activeSubItemsList = subItem;
  }
  ngOnDestroy(): void {
    // this.subscriptions.unsubscribe();
  }
}
