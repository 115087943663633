import { createFeatureSelector, createSelector } from '@ngrx/store';
import { BillingState } from '@app/store';

export const selectBillingSlice =
  createFeatureSelector<BillingState>('billing');

export const getStripePaymentIntent = createSelector(
  selectBillingSlice,
  (state: BillingState) => {
    if (state.stripePaymentIntent) {
      return state.stripePaymentIntent;
    }
  }
);
