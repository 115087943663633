import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { filter, flatMap } from 'rxjs/operators';

import { AppState } from '@app/store/app.state';
import {
  AuthActions,
  CaseActions,
  ContentfulActions,
  GlobalActions,
  UserDataActions,
} from '@app/store/actions';
import { AuthSelectors, GlobalSelectors, UserDataSelectors } from '@app/store';
import { RouteHistoryService } from '@app/core/services/routing/route-history.service';
import { PageLink } from '@app/core/models/page-link';
import { MatDialog } from '@angular/material/dialog';
import { LegalDocumentModalComponent } from '@app/shared/components/legal-document-modal/legal-document-modal.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  // handles all component subscriptions
  subscriptions: Subscription = new Subscription();
  showLoadingVisual$: Observable<boolean> = this.store.select(
    GlobalSelectors.showLoadingVisual
  );
  isSideNavOpen = true;
  isShowSpinner = false;
  isUserLoggedIn = false;
  timer = null;
  pages: PageLink[] = [];

  constructor(
    private store: Store<AppState>,
    public modal: MatDialog,
    private routeHistoryService: RouteHistoryService,
    public router: Router
  ) {
    this.checkIfUserAccepted();
    this.setNavState();
  }

  ngOnInit(): void {
    // watch/save previous route
    this.routeHistoryService.watchRoutes();
    this.store.dispatch(AuthActions.checkAuth());
    // we need all of these available at application startup
    // should look to move somewhere else possibly
    this.initStoreValues();
    this.setNavItems();
    this.getOrCreateUser();
  }

  ngAfterViewInit(): void {
    this.store.dispatch(GlobalActions.hideError()); // clear any old error message modals on page load
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  setNavState(): void {
    this.subscriptions.add(
      this.store.select(GlobalSelectors.isSideNavOpen).subscribe(isOpen => {
        this.isSideNavOpen = isOpen;
      })
    );
  }

  getOrCreateUser(): void {
    this.subscriptions.add(
      this.store.select(AuthSelectors.getUser).subscribe(b2cAccountInfo => {
        if (b2cAccountInfo) {
          this.isUserLoggedIn = true;
        } else {
          this.isUserLoggedIn = false;
        }
      })
    );
  }

  checkIfUserAccepted(): void {
    this.subscriptions.add(
      this.router.events
        .pipe(
          filter(e => e instanceof NavigationEnd), // filter browser navigation events
          flatMap(() => this.store.select(UserDataSelectors.getUserInfo)),
          filter(user => user?.hasAcceptedCustomerPrivacyAndTerms !== null) // filter out nulls
        )
        .subscribe(user => {
          if (!user.hasAcceptedCustomerPrivacyAndTerms) {
            this.modal.open(LegalDocumentModalComponent, {
              disableClose: true,
            });
          }
        })
    );
  }

  initStoreValues(): void {
    // contentful data
    this.store.dispatch(ContentfulActions.getWhatsNew());
    this.store.dispatch(ContentfulActions.getTermsAndConditions());
    this.store.dispatch(ContentfulActions.getPrivacyPolicy());
    // dynamics case types
    this.store.dispatch(CaseActions.getCaseTypes());

    this.subscriptions.add(
      this.store
        .select(AuthSelectors.getUser)
        .pipe(filter(user => !!user))
        .subscribe(b2cAccountInfo => {
          this.store.dispatch(
            UserDataActions.userGetByAdObjectId({
              adObjectId: b2cAccountInfo.localAccountId,
            })
          );
          //save the user logged in time
          this.store.dispatch(
            UserDataActions.userSaveLoggedInTime({
              adObjectId: b2cAccountInfo.localAccountId,
            })
          );
        })
    );
  }

  onSideNavToggle() {
    this.store.dispatch(GlobalActions.toggleSideNav());
  }

  setNavItems(): void {
    this.pages = [
      {
        name: 'Dashboard',
        routerLink: '/dashboard',
        icon: 'assets/images/dashboard.svg',
        featureName: 'Dashboard',
      },
      {
        name: 'Sites',
        routerLink: '/organization/sites',
        icon: 'assets/images/sites.svg',
        featureName: 'Sites',
      },
      // {
      //   name: 'Operations',
      //   routerLink: '/operations/collection-status',
      //   icon: 'assets/images/operations.svg',
      //   featureName: 'Operations',
      // },
      {
        name: 'Support',
        routerLink: '/support-signed-in/support-ticket-list',
        icon: 'assets/images/headphones.svg',
        featureName: 'Support',
      },
      {
        name: 'Invoices',
        routerLink: '/invoices',
        icon: 'assets/images/invoices.svg',
        featureName: 'Invoices',
      },
      {
        name: 'Analytics',
        routerLink: '/analytics',
        icon: 'assets/images/analytics.svg',
        featureName: 'Analytics',
        pageSubLink: [
          {
            subItemName: 'General Report',
            featureName: 'GeneralReport',
            subItem: [
              {
                name: 'General',
                routerLink: '/analytics/reports/general',
                cyName: '',
                linkMinWidth: '',
                featureName: 'General',
              },
              {
                name: 'Finance',
                routerLink: '/analytics/reports/finance',
                cyName: '',
                linkMinWidth: '',
                featureName: 'Finance',
              },
              {
                name: 'Sustainability',
                routerLink: '/analytics/reports/sustainability',
                cyName: '',
                linkMinWidth: '',
                featureName: 'Sustainability',
              },
              {
                name: 'IoT',
                routerLink: '/analytics/reports/iot',
                cyName: '',
                linkMinWidth: '',
                featureName: 'IoT',
              },
            ],
          },
          {
            subItemName: 'Advance Report',
            featureName: 'AdvanceReport',
            subItem: [
              {
                name: 'Material Analysis',
                routerLink:
                  '/analytics/powerbireports/materialanalysis/8f6265f3-9b17-48d4-945e-baf67c150f23',
                cyName: '',
                linkMinWidth: '',
                featureName: 'MaterialAnalysis',
                id: 'dbe73616-db87-458f-a2b9-963f15a82a25',
              },
              {
                name: 'Diversion Analysis',
                routerLink: '',
                cyName: '',
                linkMinWidth: '',
                featureName: 'DiversionAnalysis',
                id: '',
              },
              {
                name: 'Roll Off Analysis',
                routerLink: '',
                cyName: '',
                linkMinWidth: '',
                featureName: 'RollOffAnalysis',
                id: '',
              },
              {
                name: 'CostTrendingAnalysis',
                routerLink: '',
                cyName: '',
                linkMinWidth: '',
                featureName: 'CostTrendingAnalysis',
                id: '',
              },
            ],
          },
        ],
      },
      {
        name: 'Sensors',
        routerLink: '/sensors',
        icon: 'assets/images/sensors.svg',
        featureName: 'Sensors',
      },
      {
        name: 'Documents',
        routerLink: '/documents',
        icon: 'assets/images/documents.svg',
        featureName: 'Documents',
      },
    ];
  }
}
