import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { SerializedRouterStateSnapshot } from '@ngrx/router-store';
import { RouterSelectors } from '@app/store';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent {
  routerState$: Observable<SerializedRouterStateSnapshot> = this.store.select(
    RouterSelectors.getRouterState
  );

  constructor(private store: Store) {}
}
