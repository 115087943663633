import { createReducer, on } from '@ngrx/store';
import { BillingActions } from '@app/store/actions';
import { PaymentIntent } from '@stripe/stripe-js';

export const billingFeatureKey = 'billing';

export interface BillingState {
  stripePaymentIntent: PaymentIntent | null;
}

const initialState: BillingState = {
  stripePaymentIntent: null,
};

export const billingReducer = createReducer(
  initialState,
  on(
    BillingActions.createStripePaymentIntentSuccess,
    (state, { response }) => ({ ...state, stripePaymentIntent: response })
  )
);
