import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  RegionSite,
  Report,
  ReportPostData,
  ReportRegion,
} from '@app/core/models/analytics.model';
import { AzureBaseService } from '@app/core/services/azure-functions/azure-base.service';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AnalyticsService extends AzureBaseService {
  constructor(protected http: HttpClient) {
    super(http, '/func-portal-api-');
  }

  getReportsList(): Observable<Report[]> {
    const requestUrl = `${this.azureBaseUrl}${this.functionUri}${this.azureEnv}/reportcollections`;

    return this.http
      .get<HttpResponse<any>>(requestUrl, this.httpOptions)
      .pipe(map(response => JSON.parse(response.body) as Report[]));
  }

  getReportToken(requestBody: ReportPostData): Observable<string> {
    if (!requestBody) {
      return;
    }

    const requestUrl = `${this.azureBaseUrl}${this.functionUri}${this.azureEnv}/reportToken`;

    return this.http
      .post<HttpResponse<any>>(requestUrl, requestBody, this.httpOptions)
      .pipe(map(response => response.body));
  }

  getReportRegionByBillingAccount(requestBody: {
    billingAccountGuid: string;
  }): Observable<ReportRegion[]> {
    const requestUrl = `${this.azureBaseUrl}${this.functionUri}${this.azureEnv}/dataLakeRegions?ceAccountId=${requestBody.billingAccountGuid}`;

    return this.http
      .get<HttpResponse<any>>(requestUrl, this.httpOptions)
      .pipe(map(response => JSON.parse(response.body) as ReportRegion[]));
  }

  getSitesByRegion(requestBody: {
    regionId: number;
  }): Observable<RegionSite[]> {
    const requestUrl = `${this.azureBaseUrl}${this.functionUri}${this.azureEnv}/dataLakeRegionSites?regionId=${requestBody.regionId}`;

    return this.http
      .get<HttpResponse<any>>(requestUrl, this.httpOptions)
      .pipe(map(response => JSON.parse(response.body) as RegionSite[]));
  }
}
