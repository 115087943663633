import { createAction, props } from '@ngrx/store';
import { UserDataModel } from '@app/core/models/UserDataModel';

export const userGetByUserId = createAction(
  '[User Data] User Get By User Id',
  props<{ userId: string }>()
);

export const userGetByUserIdSuccess = createAction(
  '[User Data] User Get By User Id Success',
  props<{ user: UserDataModel }>()
);

export const userGetByUserIdFailure = createAction(
  '[User Data] User Get By User Id Failure',
  props<{ errorMsg: string }>()
);

export const userGetByAdObjectId = createAction(
  '[User Data] User Get By AdObject Id',
  props<{ adObjectId: string }>()
);

export const userGetByAdObjectIdSuccess = createAction(
  '[User Data] User Get By AdObject Id Success',
  props<{ user: UserDataModel }>()
);

export const userGetByAdObjectIdFailure = createAction(
  '[User Data] User Get By AdObject Id Failure',
  props<{ errorMsg: string }>()
);

export const userUpdateById = createAction(
  '[User Data] User Update By Id',
  props<{ user: UserDataModel }>()
);

export const userUpdateByIdSuccess = createAction(
  '[User Data] User Update By Id Success',
  props<{ user: UserDataModel }>()
);

export const userUpdateByIdFailure = createAction(
  '[User Data] User Update By Id Failure',
  props<{ errorMsg: string }>()
);

export const userSaveLoggedInTime = createAction(
  '[User Data] User Save Logged-in Time By AdObject Id',
  props<{ adObjectId: string }>()
)

export const userSaveLoggedInTimeSuccess = createAction(
  '[User Data] User Save Logged-in Time By AdObject Id Success',
  props<{ isSuccess: boolean }>()
);

export const userSaveLoggedInTimeFailure = createAction(
  '[User Data] User Save Logged-in Time By AdObject Id Failure',
  props<{ errorMsg: string }>()
);

