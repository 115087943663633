<app-wrapper-landing-login
  class="container-fluid p-0"
  [heroImageCol]="heroImageCol"
  [styleClass]="styleClass"
  [title]="subNavTitle"
  [showBackButton]="showBackButton"
  [backButtonUrl]="backButtonUrl"
  [hideSupportButtons]="hideSupportButtons"
  [selectedCase]="selectedCase">
  <router-outlet></router-outlet>
</app-wrapper-landing-login>
