import { Inject, Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { switchMap, map, catchError, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';

import { AuthActions } from '@app/store/actions';
import { AuthTokenClaims, UserIds } from '@app/core/models';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { EventType } from '@azure/msal-browser';
import { protectedResources } from '@app/core/auth-config';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    public msalBroadcastService: MsalBroadcastService,
    public msalService: MsalService,
    @Inject(DOCUMENT) private doc: Document
  ) {}

  checkAuth$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.checkAuth),
      switchMap(() =>
        // if user is not authenticated, canActivate on the routes handles
        this.msalBroadcastService.msalSubject$.pipe(
          // if user is authenticated, get/set user, tenantId, accountId, userPermissions
          map(msg =>
            msg.eventType === EventType.LOGIN_SUCCESS ||
            msg.eventType === EventType.HANDLE_REDIRECT_END
              ? AuthActions.getAllAuths()
              : null
          ),
          catchError(err =>
            of(AuthActions.checkAuthFailure({ errorMsg: err.message }))
          )
        )
      )
    )
  );

  getAuthUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.getAllAuths),
      map(() => {
        const msalService = this.msalService.instance;
        let activeAccount = msalService.getActiveAccount();

        if (!activeAccount && msalService.getAllAccounts().length > 0) {
          const accounts = msalService.getAllAccounts();

          msalService.setActiveAccount(accounts[0]);

          // eslint-disable-next-line prefer-destructuring
          activeAccount = accounts[0];
        }

        return AuthActions.getAuthUserSuccess({ user: activeAccount });
      })
    )
  );

  getAuthAccessToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.getAllAuths),
      switchMap(() =>
        this.msalService
          .acquireTokenSilent({
            scopes: [
              'openid',
              'profile',
              'email',
              ...protectedResources.tokenAuthApim.scopes,
            ],
          })
          .pipe(
            mergeMap(authResult => {
              const tokenClaims = authResult.idTokenClaims as AuthTokenClaims;
              const userIds: UserIds = {
                tenantId: tokenClaims.extension_BillingId,
                accountId: tokenClaims.extension_BillingId,
                id: tokenClaims.extension_CosmosUserId,
              };
              const userPermissions: string[] =
                tokenClaims.extension_RolePermissions
                  ? tokenClaims.extension_RolePermissions.split(',')
                  : [];

              return [
                AuthActions.setAuthUserIds({ userIds }),
                AuthActions.setAuthUserPermissions({ userPermissions }),
              ];
            }),
            catchError(err => {
              // If error in getting token clear session and navigate to origin
              if (err.message.includes('AADB2C90077')) {
                sessionStorage.clear();
                window.location.href = this.doc.location.origin;
              }

              return of(
                AuthActions.getAndDecodeAccessTokenFailure({
                  errorMsg: err.message,
                })
              );
            })
          )
      )
    )
  );
}
