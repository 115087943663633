<div class="row">
  <app-text-input
    class="col-12 col-lg-6"
    *ngIf="supportType !== 'muni-support'"
    [parentForm]="parentForm"
    controlName="clientName"
    label="Account Name"
    placeholder="Account Name"
    [showErrors]="true"
    [errorStateMatcher]="errorStateMatcher">
  </app-text-input>
  <app-text-input
    class="col-12 col-lg-6"
    [parentForm]="parentForm"
    controlName="address"
    label="Site Address"
    placeholder="Site Address"
    [showErrors]="true"
    [errorStateMatcher]="errorStateMatcher">
  </app-text-input>
  <app-text-input
    class="col-12 col-lg-3"
    [parentForm]="parentForm"
    controlName="city"
    label="City"
    placeholder="City"
    [showErrors]="true"
    [errorStateMatcher]="errorStateMatcher">
  </app-text-input>
  <app-dropdown-select
    class="col-12 col-lg-3"
    [parentForm]="parentForm"
    controlName="state"
    [labelText]="country === 'us' ? 'State' : 'Province'"
    [options]="statesDropdownOptions"
    [showErrors]="true"
    [errorStateMatcher]="errorStateMatcher">
  </app-dropdown-select>
  <app-text-input
    class="col-12 col-lg-6"
    [parentForm]="parentForm"
    controlName="zipcode"
    [label]="country === 'us' ? 'Zip Code' : 'Postal Code'"
    [placeholder]="country === 'us' ? 'Zip Code' : 'Postal Code'"
    [showErrors]="true"
    [errorStateMatcher]="errorStateMatcher">
  </app-text-input>
  <app-dropdown-select
    class="col-12 col-lg-6"
    [parentForm]="parentForm"
    controlName="wasteStream"
    labelText="Waste Stream"
    [options]="wasteStreamDropdownOptions"
    [showErrors]="true"
    [errorStateMatcher]="errorStateMatcher"
    *ngIf="caseTypeId !== '118100014'">
  </app-dropdown-select>
  <app-dropdown-select
    class="col-12 col-lg-6 no-translate"
    [parentForm]="parentForm"
    controlName="equipmentType"
    labelText="Equipment Type"
    [options]="equipmentTypeDropdownOptions"
    [showErrors]="true"
    [errorStateMatcher]="errorStateMatcher"
    *ngIf="caseTypeId !== '118100014'">
  </app-dropdown-select>
  <app-dropdown-select
    class="col-12 col-lg-6"
    [parentForm]="parentForm"
    controlName="equipmentSize"
    labelText="Equipment Size"
    [options]="equipmentSizeDropdownOptions"
    [showErrors]="true"
    [errorStateMatcher]="errorStateMatcher"
    *ngIf="caseTypeId !== '118100014'">
  </app-dropdown-select>
  <app-date-picker
    class="col-12 col-lg-6"
    [parentForm]="parentForm"
    controlName="pickupDate"
    type="date"
    placeholder="mm/dd/yyyy"
    [minDate]="caseTypeId === '3' ? todayDate : null"
    [maxDate]="caseTypeId === '118100006' ? todayDate : null"
    [label]="
      caseTypeId === '3'
        ? 'Requested Pickup Date'
        : caseTypeId === '118100006'
          ? 'Missed Pickup Date'
          : ''
    "
    *ngIf="caseTypeId === '3' || caseTypeId === '118100006'"
    [showErrors]="true"
    [errorStateMatcher]="errorStateMatcher">
  </app-date-picker>
  <app-text-input
    class="col-12 col-lg-6"
    [parentForm]="parentForm"
    controlName="name"
    placeholder="Contact Name"
    [showErrors]="true"
    [errorStateMatcher]="errorStateMatcher">
  </app-text-input>
  <app-text-input
    class="col-12 col-lg-6"
    [parentForm]="parentForm"
    controlName="email"
    placeholder="Email Address"
    [showErrors]="true"
    [errorStateMatcher]="errorStateMatcher">
  </app-text-input>
  <app-multiple-email-input
    class="col-12 col-lg-6"
    [parentForm]="parentForm"
    controlName="additionalEmailAddress"
    label="Additional Emails"
    placeholder="Additional Emails"
    maxLength="255"
    [errorStateMatcher]="errorStateMatcher">
  </app-multiple-email-input>
  <app-text-input
    class="col-12 col-lg-6"
    [parentForm]="parentForm"
    controlName="phone"
    type="tel"
    placeholder="Phone Number"
    [showErrors]="true"
    [errorStateMatcher]="errorStateMatcher">
  </app-text-input>
</div>

<div class="row">
  <div class="col-12 col-lg-6">
    <app-file-upload
      [showValidationError]="
        parentForm?.controls.fileUpload.touched &&
        parentForm?.controls.fileUpload.invalid
      "
      [label]="'Upload Image'"
      [isRequired]="parentForm.controls.fileUpload.errors?.required"
      [placeholder]="'Drag and drop an image file here or click'"
      (onFileChange)="setFile($event)">
    </app-file-upload>
  </div>
  <div class="col">
    <app-text-area-input
      [parentForm]="parentForm"
      [controlName]="'description'"
      label="Description"
      [placeholder]="descriptionPlaceholderText"
      [showErrors]="true"
      [errorStateMatcher]="errorStateMatcher">
    </app-text-area-input>
  </div>
</div>
