<mat-form-field class="rts-search-input" appearance="outline">
  <mat-icon matPrefix>search</mat-icon>
  <input
    matInput
    type="text"
    [(ngModel)]="searchText"
    (keyup)="handleKeyUp()"
    placeholder="Search" />
  <button
    *ngIf="searchText"
    matSuffix
    mat-icon-button
    aria-label="Clear"
    (click)="clearSearch()">
    <mat-icon>close</mat-icon>
  </button>
</mat-form-field>
