import { Component } from '@angular/core';

@Component({
  selector: 'app-form-loading-visual',
  templateUrl: './form-loading-visual.component.html',
  styleUrls: ['./form-loading-visual.component.scss'],
})
export class FormLoadingVisualComponent {
  constructor() {}
}
