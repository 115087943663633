import {
  BreakpointObserver,
  BreakpointState,
  Breakpoints,
} from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { environment as env } from '@env/environment';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-chatbot-dialog',
  templateUrl: './chatbot-dialog.component.html',
  styleUrls: ['./chatbot-dialog.component.scss'],
})
export class ChatbotDialogComponent implements OnInit, OnDestroy {
  chatbotUrl: SafeResourceUrl;
  subscriptions: Subscription = new Subscription();
  height: string = '493';
  isExtraSmall: Observable<BreakpointState> = this.breakpointObserver.observe(
    Breakpoints.XSmall
  );

  constructor(
    public dialogRef: MatDialogRef<ChatbotDialogComponent>,
    private sanitizer: DomSanitizer,
    private breakpointObserver: BreakpointObserver
  ) {
    this.chatbotUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
      env.chatbotUrl
    );
  }

  ngOnInit() {
    const smallDialogSubscription = this.isExtraSmall.subscribe(size => {
      if (size.matches) {
        this.height = `${window.innerHeight - 20.8}`;
      }
    });

    this.subscriptions.add(smallDialogSubscription);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
