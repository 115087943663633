<div id="{{ controlName }}-text-area-container" [formGroup]="parentForm">
  <mat-form-field class="text-area-input" appearance="outline">
    <mat-label>{{ label ?? placeholder }}</mat-label>
    <textarea
      matInput
      #textAreaInput
      id="{{ controlName }}-input"
      [formControlName]="controlName"
      [placeholder]="placeholder"
      [maxLength]="maxLength">
    </textarea>
    <mat-hint align="end"
      >{{ textAreaInput.value?.length || 0 }}/{{ maxLength }}</mat-hint
    >
    <mat-error
      *ngIf="
        showErrors &&
        !parentForm.controls[controlName].hasError('required') &&
        parentForm.controls[controlName].errors
      ">
      Please enter a valid {{ placeholder }}
    </mat-error>
    <mat-error
      *ngIf="
        showErrors && parentForm.controls[controlName].hasError('required')
      ">
      {{ placeholder }} is <strong>required</strong>
    </mat-error>
  </mat-form-field>
</div>
