import { Component, Input } from '@angular/core';
import { ErrorStateMatcher } from '@angular/material/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.scss'],
})

export class TextInputComponent {
  @Input() parentForm: UntypedFormGroup;
  @Input() controlName: string;
  @Input() type: string = 'text';
  @Input() label: string;
  @Input() placeholder: string;
  @Input() min: string = null;
  @Input() max: string = null;
  @Input() appearance: string = 'outline';
  @Input() showErrors: boolean = false;
  @Input() errorStateMatcher: ErrorStateMatcher = null;

  constructor() { }
}
