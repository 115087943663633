import {
  BillingAccount,
  UserMetadataModel,
} from '@app/core/models/UserDataModel';
import { UserDataActions } from '@app/store';
import { createReducer, on } from '@ngrx/store';

export const userFeatureKey = 'userData';

export interface UserDataState {
  adObjectId?: string;
  hasAcceptedCustomerPrivacyAndTerms?: boolean;
  userMetadata?: UserMetadataModel;
  billingAccounts?: BillingAccount[];
  reportIds?: string[];
}

const initialState: UserDataState = {
  adObjectId: null,
  hasAcceptedCustomerPrivacyAndTerms: null,
  userMetadata: null,
};

export const userDataReducer = createReducer(
  initialState,
  on(UserDataActions.userGetByAdObjectIdSuccess, (_state, { user }) => user),
  on(UserDataActions.userGetByUserIdSuccess, (_state, { user }) => user),
  on(UserDataActions.userUpdateByIdSuccess, (_state, { user }) => user)
);
