import {
  BillingAccount,
  UserMetadataModel,
  Notification,
} from '@app/core/models/UserDataModel';
import { UserDataActions } from '@app/store';
import { createReducer, on } from '@ngrx/store';

export const userFeatureKey = 'userData';

export interface UserDataState {
  adObjectId?: string;
  hasAcceptedCustomerPrivacyAndTerms?: boolean;
  userMetadata?: UserMetadataModel;
  billingAccounts?: BillingAccount[];
  phoneNumber?: string;
  notificationSettings?: Notification;
  reportIds?: string[];
}

const initialState: UserDataState = {
  adObjectId: null,
  hasAcceptedCustomerPrivacyAndTerms: null,
  userMetadata: null,
  phoneNumber: null,
  notificationSettings: null,
};

export const userDataReducer = createReducer(
  initialState,
  on(UserDataActions.userGetByAdObjectIdSuccess, (_state, { user }) => user),
  on(UserDataActions.userGetByUserIdSuccess, (_state, { user }) => user),
  on(UserDataActions.userUpdateByIdSuccess, (_state, { user }) => user),
  on(
    UserDataActions.userUpdateAcccountSettingsByIdSuccess,
    (_state, { user }) => user
  ),
  on(
    UserDataActions.userUpdateNotifSettingsByIdSuccess,
    (_state, { user }) => user
  )
);
