import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

import { SharepointDocumentsService } from '@app/core/services/azure-functions/sharepoint-documents.service';
import { DocumentActions } from '@app/store';
import { DocumentDetailsModel } from '@app/core/models/document-details.model';

@Injectable()
export class DocumentsEffects {
  constructor(
    private actions$: Actions,
    private sharepointDocumentsService: SharepointDocumentsService
  ) {}

  getDocuments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocumentActions.getDocuments),
      switchMap(({ requestBody }) =>
        this.sharepointDocumentsService.getDocumentDetails(requestBody).pipe(
          map((documents: DocumentDetailsModel[]) =>
            DocumentActions.getDocumentsSuccess({ documents })
          ),
          catchError(err =>
            of(DocumentActions.getDocumentsFailure({ errorMsg: err.message }))
          )
        )
      )
    )
  );
}
