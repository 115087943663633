/**
 * OpenAPI Document on Azure Functions
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface Account {
  id?: string;
  customerId?: string;
  accountId?: string;
  rtsCompanyId?: string;
  createdBy?: string;
  createdDate?: string;
  name?: string;
  accountNumber?: string;
  addressId?: string;
  phoneNumber?: number;
  faxNumber?: number;
  status?: string;
  facilitiesManager?: string;
  facilitiesManagerCell?: number;
  wasteAuditScheduledDate?: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zip?: string;
  longitude?: number;
  latitude?: number;
}
