import { createFeatureSelector, createSelector } from '@ngrx/store';
import { TwilioState } from '../reducers/twilio.reducer';

export const twilioSlice = createFeatureSelector<TwilioState>('twilio');

export const selectIsPhoneValidationSent = createSelector(
  twilioSlice,
  (state: TwilioState) => state.isPhoneValidationSent
);

export const selectIsPhoneValidated = createSelector(
  twilioSlice,
  (state: TwilioState) => state.isPhoneValidated
);

export const selectError = createSelector(
  twilioSlice,
  (state: TwilioState) => state.errorMsg
);
