import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '@app/store/app.state';
import { GlobalActions, GlobalSelectors } from '@app/store';
import { animateError } from '@app/_animations/animations';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
  animations: [animateError],
})
export class ErrorComponent implements OnDestroy {
  errorSubscription: Subscription;
  errorMsgSubscription: Subscription;
  isErrorShowing: boolean;
  errorMsg: string;

  constructor(private store: Store<AppState>) {
    this.errorSubscription = this.store
      .select(GlobalSelectors.isErrorShowing)
      .subscribe(isErrorShowing => (this.isErrorShowing = isErrorShowing));
    this.errorMsgSubscription = this.store
      .select(GlobalSelectors.errorMsg)
      .subscribe(errorMsg => (this.errorMsg = errorMsg));
  }

  ngOnDestroy() {
    this.errorSubscription.unsubscribe();
    this.errorMsgSubscription.unsubscribe();
  }

  closeOnClick() {
    this.store.dispatch(GlobalActions.hideError());
  }
}
