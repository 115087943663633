import {
  InvoiceDetail,
  InvoiceFile,
} from '@app/core/api-azure-functions/dataverse-functions/model/invoice';
import { InvoiceResponse } from '@app/core/api/model/invoiceResponse';
import { InvoicesActions } from '@app/store/actions';
import { createReducer, on } from '@ngrx/store';

export const invoicesFeatureKey = 'invoices';

export interface InvoicesState {
  invoices: InvoiceResponse[] | null;
  invoiceDetails: InvoiceDetail | null;
  invoiceFile: InvoiceFile | null;
  tableState: any;
  invoiceFileLoading: boolean;
}

const initialState: InvoicesState = {
  invoices: null,
  invoiceDetails: null,
  invoiceFile: null,
  tableState: {
    searchText: '',
    pageNumber: null,
  },
  invoiceFileLoading: true,
};

export const invoicesReducer = createReducer(
  initialState,
  on(InvoicesActions.getInvoicesSuccess, (state, { invoices }) => ({
    ...state,
    invoices,
  })),
  on(
    InvoicesActions.setInvoicesListTableState,
    (state, { searchText, pageNumber }) => ({
      ...state,
      tableState: {
        searchText,
        pageNumber,
      },
    })
  ),
  on(InvoicesActions.getInvoiceDetailsSuccess, (state, { invoiceDetails }) => ({
    ...state,
    invoiceDetails,
  })),
  on(InvoicesActions.resetInvoiceDetails, state => ({
    ...state,
    invoiceDetails: null,
  })),
  on(InvoicesActions.getInvoiceFileSuccess, (state, { invoiceFile }) => ({
    ...state,
    invoiceFile,
    invoiceFileLoading: false
  })),
  on(InvoicesActions.invoiceFileLoadingStart, state => ({
    ...state,
    invoiceFileLoading: true,
  })),
);
