import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AzureBaseService } from '@app/core/services/azure-functions/azure-base.service';
import { SequoiaDeviceModel } from '@app/core/models';
import { SequoiaFullnessVisualizationModel } from '@app/core/models/sequoia-fullness-visualization.model';

@Injectable({ providedIn: 'root' })
export class SequoiaService extends AzureBaseService {
  constructor(protected http: HttpClient) {
    super(http, '/func-customerportal-sequoia-');
  }

  getSequoiaDevices(requestBody: string[]): Observable<SequoiaDeviceModel[]> {
    if (!requestBody.length) {
      return;
    }

    const requestUrl = `${this.azureBaseUrl}${this.functionUri}${this.azureEnv}/GetSequoiaDevices`;

    return this.http
      .post<HttpResponse<any>>(requestUrl, requestBody, this.httpOptions)
      .pipe(map(response => JSON.parse(response.body) as SequoiaDeviceModel[]));
  }

  getSequoiaDeviceLoggedFullness(
    deviceId: number,
    startDate: string,
    endDate: string
  ): Observable<SequoiaFullnessVisualizationModel[]> {
    const requestUrl = `${this.azureBaseUrl}${this.functionUri}${this.azureEnv}/GetFullnessData?deviceId=${deviceId}&beginningDate=${startDate}&endDate=${endDate}`;

    return this.http
      .get<HttpResponse<any>>(requestUrl, this.httpOptions)
      .pipe(
        map(
          response =>
            JSON.parse(response.body) as SequoiaFullnessVisualizationModel[]
        )
      );
  }

  getSequoiaDeviceForecastedFullness(
    deviceId: number
  ): Observable<SequoiaFullnessVisualizationModel[]> {
    const requestUrl = `${this.azureBaseUrl}${this.functionUri}${this.azureEnv}/GetForcastedFullnessData?deviceId=${deviceId}`;

    return this.http
      .get<HttpResponse<any>>(requestUrl, this.httpOptions)
      .pipe(
        map(
          response =>
            JSON.parse(response.body) as SequoiaFullnessVisualizationModel[]
        )
      );
  }
}
