<div
  id="{{ controlName }}-input-container"
  [formGroup]="parentForm"
  [ngClass]="showErrors ? 'mb-2' : null">
  <mat-form-field class="text-input" [appearance]="appearance">
    <mat-label>{{ label ? label : placeholder }}</mat-label>
    <input
      matInput
      id="{{ controlName }}-input"
      [formControlName]="controlName"
      [type]="type"
      [placeholder]="placeholder"
      [min]="min"
      [max]="max"
      [errorStateMatcher]="errorStateMatcher" />
    <mat-error
      *ngIf="
        showErrors &&
        !parentForm.controls[controlName].hasError('required') &&
        parentForm.controls[controlName].errors
      ">
      Please enter a valid {{ label ? label : placeholder }}
    </mat-error>
    <mat-error
      *ngIf="
        showErrors && parentForm.controls[controlName].hasError('required')
      ">
      {{ label ? label : placeholder }} is <strong>required</strong>
    </mat-error>
  </mat-form-field>
</div>
