import { createAction, props } from '@ngrx/store';
import { Account } from '@app/core/api-azure-functions/dataverse-functions';
import { UserIds } from '@app/core/models';

export const getCorporateAccount = createAction(
  '[Corporate] Get Corporate Account',
  props<{ userIds: UserIds }>()
);

export const getCorporateAccountNumber = createAction(
  '[Corporate] Get Corporate Account',
  props<{ corporateAccount: Account }>()
);

export const getCorporateAccountSuccess = createAction(
  '[Corporate] Get Corporate Account Success',
  props<{ corporateAccount: Account }>()
);

export const getCorporateAccountFailure = createAction(
  '[Corporate] Get Corporate Account Failure',
  props<{ errorMsg: string }>()
);
