/**
 * OpenAPI Document on Azure Functions
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface Equipment {
  accountId?: string;
  equipmentId?: string;
  createdOn?: string;
  modifiedOn?: string;
  status?: string;
  state?: string;
  equipmentImage?: string;
  statusCode?: number;
  stateCode?: number;
  startDate?: string;
  alertCountState?: number;
  lastAlertTimeState?: number;
  name?: string;
  alertCount?: number;
  deviceId?: number;
  truckTypes?: number;
  latitude?: number;
  longitude?: number;
  functionalLocationValue?: string;
}
