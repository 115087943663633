import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UserIds } from '@app/core/models/auth-user';
import { AuthState } from '@app/store';

export const selectAuth = createFeatureSelector<AuthState>('auth');

// specific user info
export const getUser = createSelector(
  selectAuth,
  (state: AuthState) => state.user
);

// tenantId and accountId
export const getUserIds = createSelector(
  selectAuth,
  (state: AuthState) => state.userIds
);

export const getUserPermissions = createSelector(
  selectAuth,
  (state: AuthState) => state.userPermissions
);

export const getTenantId = createSelector(
  getUserIds,
  (userIds: UserIds) => userIds.tenantId
);

export const getSequoiaId = createSelector(
  selectAuth,
  (state: AuthState) => state.sequoiaId
);

export const getCompologyId = createSelector(
  selectAuth,
  (state: AuthState) => state.compologyId
);

export const getPelloId = createSelector(
  selectAuth,
  (state: AuthState) => state.pelloId
);
