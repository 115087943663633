<div class="global-filter-wrapper" style="display: flex">
  <button
    #matMenuTrigger
    [matMenuTriggerFor]="repm"
    (menuOpened)="onMenuOpened()"
    (menuClosed)="onMenuClosed()"
    mat-button
    mat-flat-button
    disableRipple
    class="global-filter-trigger">
    <span class="selected-billing-account-names">
      {{ selectedBillingAccountNames }}
    </span>
    <mat-icon fontIcon="expand_more"></mat-icon>
  </button>
  <mat-chip-list class="selections">
    <mat-chip class="billingAccountsCount" disableRipple highlighted>
      <mat-icon fontSet="material-icons-outlined" matChipAvatar>
        location_on
      </mat-icon>
      {{ selectedBillingAccounts.length || 0 }}
    </mat-chip>
    <mat-chip class="sitesCount" disableRipple>
      <mat-icon fontSet="material-icons-outlined" matChipAvatar>
        fire_truck
      </mat-icon>
      {{ getSelectedSites.length + selectedBAWithFullPortfolio | number }}
    </mat-chip>
  </mat-chip-list>
  <mat-menu
    #repm="matMenu"
    class="global-filter"
    panelClass="global-filter-panel"
    overlayPanelClass="global-filter-overlay-panel"
    yPosition="below">
    <mat-accordion displayMode="flat">
      <mat-expansion-panel
        *ngFor="let billingAccount of billingAccounts$ | async"
        [disabled]="!billingAccount.sites.length"
        [ngClass]="billingAccount.selected ? 'selected' : 'not-selected'">
        <mat-expansion-panel-header (click)="$event.stopPropagation()">
          <mat-checkbox
            disableRipple
            [checked]="isBillingAccChecked(billingAccount)"
            [indeterminate]="isBillingAccountIndeterminate(billingAccount)"
            (change)="toggleBillingAccountSelection(billingAccount)"
            (click)="$event.stopPropagation()"
            [ngClass]="billingAccount.selected ? 'selected' : 'not-selected'"
            [disabled]="
              selectedBillingAccounts.length === 1 && billingAccount.selected
            ">
            {{ billingAccount.name }}
          </mat-checkbox>
        </mat-expansion-panel-header>
        <div class="sites" *ngIf="billingAccount.sites">
          <mat-checkbox
            *ngFor="let site of billingAccount.sites"
            [checked]="site.selected"
            (change)="toggleBillingAccountSelection(billingAccount, site)"
            (click)="$event.stopPropagation()"
            [ngClass]="site.selected ? 'selected' : 'not-selected'"
            [disabled]="
              selectedBillingAccounts.length === 1 &&
              getSelectedSites.length === 1 &&
              site.selected
            "
            [matTooltipDisabled]="
              !(
                selectedBillingAccounts.length === 1 &&
                getSelectedSites.length === 1 &&
                site.selected
              )
            "
            disableRipple>
            {{ site.name }}
          </mat-checkbox>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </mat-menu>
</div>
