<div class="dialog-error">
  <div class="row no-gutters">
    <div class="col-12">
      <div *ngIf="error" class="error-wrapper">
        <h3 class="dialog-heading">Sorry - There's been a problem!</h3>
        <span class="error-detail">{{ errorMessage }}</span>
        <span class="error-detail">
          Please try again. If you still experience issues please contact RTS
          Customer Service (<a href="mailto:{{ customerServiceEmail }}">{{
            customerServiceEmail
          }}</a
          >).
        </span>
      </div>
      <div class="row">
        <div class="col-12 close-button-wrapper">
          <button class="rts-filled-button" alt="close" mat-dialog-close>
            {{ buttonText }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
