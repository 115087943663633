<ng-container *ngIf="isErrorShowing">
  <div id="error-body" [@animateError]="isErrorShowing ? 'show' : 'hide'">
    <img id="error-icon" src="assets/images/info.svg" alt="info" />
    <div id="error-text">{{ errorMsg }}</div>
    <div id="error-close-icon">
      <img
        src="assets/images/XCloseIcon.svg"
        alt="close"
        (click)="closeOnClick()" />
    </div>
  </div>
</ng-container>
