import { createAction, props } from '@ngrx/store';
import {
  PaymentIntentRequestModel,
  PaymentIntentUpdateModel,
} from '@app/core/models/payment-intent.model';
import { PaymentIntent } from '@stripe/stripe-js';

export const createStripePaymentIntent = createAction(
  '[Billing] Create Stripe Payment Intent',
  props<{ intentRequest: PaymentIntentRequestModel; token: string }>()
);

export const createStripePaymentIntentSuccess = createAction(
  '[Billing] Create Stripe Payment Intent Success',
  props<{ response: PaymentIntent }>()
);

export const createStripePaymentIntentFailure = createAction(
  '[Billing] Create Stripe Payment Intent Failure',
  props<{ errorMsg: string }>()
);

export const updateStripePaymentIntent = createAction(
  '[Billing] Update Stripe Payment Intent',
  props<{ intentUpdate: PaymentIntentUpdateModel; token: string }>()
);
