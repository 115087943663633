import { ErrorHandler, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxStripeModule } from 'ngx-stripe';

import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { CustomRouteSerializer } from '@app/store/custom-route-serializer';
import { environment as env } from '@env/environment';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';

import { ErrorLoggerHandlerService } from './core/services/handlers/error-logger-handler.service';
import { TelemetryService } from './core/services/app-insights/telemetry.service';

import { effects } from './store';
import { ROOT_REDUCERS, metaReducers } from './store/app.state';

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    CoreModule,
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    StoreModule.forRoot(ROOT_REDUCERS, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
        strictStateSerializability: true,
        strictActionSerializability: true,
      },
    }),
    EffectsModule.forRoot(effects),
    StoreRouterConnectingModule.forRoot({ serializer: CustomRouteSerializer }),
    NgxStripeModule.forRoot(env.stripeKey),
    env.production === 'false'
      ? StoreDevtoolsModule.instrument(
          { maxAge: 50 } // Retains last 50 states
        )
      : [],
  ],
  exports: [],
  providers: [
    TelemetryService,
    ErrorLoggerHandlerService,
    {
      provide: ErrorHandler,
      useClass: ErrorLoggerHandlerService,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
