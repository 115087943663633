import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

import { BillingActions } from '@app/store/actions';
import { BillingService } from '@app/core/services/azure-functions/billing.service';

@Injectable()
export class BillingEffects {
  constructor(
    private actions$: Actions,
    private billingService: BillingService
  ) {}

  createStripePaymentIntent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BillingActions.createStripePaymentIntent),
      switchMap(({ intentRequest, token }) =>
        this.billingService
          .createStripePaymentIntent(intentRequest, token)
          .pipe(
            map(response =>
              BillingActions.createStripePaymentIntentSuccess({ response })
            ),
            catchError(err =>
              of(
                BillingActions.createStripePaymentIntentFailure({
                  errorMsg: err.message,
                })
              )
            )
          )
      )
    )
  );

  updateStripePaymentIntent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BillingActions.updateStripePaymentIntent),
      switchMap(({ intentUpdate, token }) =>
        this.billingService.updateStripePaymentIntent(intentUpdate, token).pipe(
          map(response =>
            BillingActions.createStripePaymentIntentSuccess({ response })
          ),
          catchError(err =>
            of(
              BillingActions.createStripePaymentIntentFailure({
                errorMsg: err.message,
              })
            )
          )
        )
      )
    )
  );
}
