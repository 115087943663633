import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ContainerDeviceResponse } from '@app/core/api';
import { SequoiaDeviceModel } from '@app/core/models/sequoia-device.model';
import { SequoiaFullnessVisualizationModel } from '@app/core/models/sequoia-fullness-visualization.model';
import { PelloService } from '@app/core/services/azure-functions/pello.service';
import { SequoiaService } from '@app/core/services/azure-functions/sequoia.service';
import { ContainerDeviceActions } from '@app/store/actions';
import { SendEmailService } from '@app/core/services/azure-functions/send-email.service';
import { HttpResponse } from '@angular/common/http';

@Injectable()
export class ContainerDeviceEffects {
  constructor(
    private actions$: Actions,
    private sequoiaService: SequoiaService,
    private pelloService: PelloService,
    private sendEmailService: SendEmailService
  ) {}

  getSequoiaDevices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContainerDeviceActions.getSequoiaDevices),
      switchMap(({ requestBody }) =>
        this.sequoiaService.getSequoiaDevices(requestBody).pipe(
          map((allSequoiaDevices: SequoiaDeviceModel[]) =>
            ContainerDeviceActions.getSequoiaDevicesSuccess({
              allSequoiaDevices,
            })
          ),
          catchError(err =>
            of(
              ContainerDeviceActions.getSequoiaDevicesFailure({
                errorMsg: err.message,
              })
            )
          )
        )
      )
    )
  );

  getSequoiaDeviceLoggedFullness$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContainerDeviceActions.getSequoiaDeviceLoggedFullness),
      switchMap(({ id, startDate, endDate }) =>
        this.sequoiaService
          .getSequoiaDeviceLoggedFullness(id, startDate, endDate)
          .pipe(
            map(response => response || []),
            map((sequoiaLoggedFullness: SequoiaFullnessVisualizationModel[]) =>
              ContainerDeviceActions.getSequoiaDeviceLoggedFullnessSuccess({
                sequoiaLoggedFullness,
              })
            ),
            catchError(err =>
              of(
                ContainerDeviceActions.getSequoiaDeviceLoggedFullnessFailure({
                  errorMsg: err.message,
                })
              )
            )
          )
      )
    )
  );

  getSequoiaDeviceForecastedFullness$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContainerDeviceActions.getSequoiaDeviceForecastedFullness),
      switchMap(({ id }) =>
        this.sequoiaService.getSequoiaDeviceForecastedFullness(id).pipe(
          map(response => response || []),
          map(
            (sequoiaForecastedFullness: SequoiaFullnessVisualizationModel[]) =>
              ContainerDeviceActions.getSequoiaDeviceForecastedFullnessSuccess({
                sequoiaForecastedFullness: sequoiaForecastedFullness,
              })
          ),
          catchError(err =>
            of(
              ContainerDeviceActions.getSequoiaDeviceForecastedFullnessFailure({
                errorMsg: err.message,
              })
            )
          )
        )
      )
    )
  );

  getPelloDevices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContainerDeviceActions.getPelloDevices),
      switchMap(({ requestBody }) =>
        this.pelloService.getPelloDevicesByCustomerId(requestBody).pipe(
          map((allPelloDevices: ContainerDeviceResponse[]) =>
            ContainerDeviceActions.getPelloDevicesSuccess({ allPelloDevices })
          ),
          catchError(err =>
            of(
              ContainerDeviceActions.getPelloDevicesFailure({
                errorMsg: err.message,
              })
            )
          )
        )
      )
    )
  );

  createDemoRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContainerDeviceActions.createDemoRequest),
      switchMap(({ email }) =>
        this.sendEmailService.sendSensorsDemoRequest(email).pipe(
          map((response: HttpResponse<any>) => {
            if (response.status === 200) {
              return ContainerDeviceActions.createDemoRequestSuccess();
            }
          }),
          catchError(err =>
            of(
              ContainerDeviceActions.createDemoRequestFailure({
                errorMsg: err.message,
              })
            )
          )
        )
      )
    )
  );
}
