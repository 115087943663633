import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { SerializedRouterStateSnapshot } from '@ngrx/router-store';
import { RouterSelectors } from '@app/store';
import { Store } from '@ngrx/store';

import { AppState } from '@app/store/app.state';
import { PageLink } from '@app/core/models/page-link';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss'],
})
export class ServicesComponent {
  routerState$: Observable<SerializedRouterStateSnapshot> = this.store.select(
    RouterSelectors.getRouterState
  );
  subNavItems: PageLink[] = [
    {
      name: 'All Sites',
      routerLink: '/organization/sites',
      cyName: 'nav-all-sites-serviced',
      featureName: 'AllSites',
    },
    {
      name: 'One-time Services',
      routerLink: '/organization/one-time-services',
      cyName: 'nav-all-one-time-services',
      featureName: 'OnetimeServices',
    }
  ];

  constructor(private store: Store<AppState>) { }
}
