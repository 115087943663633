import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-wrapper-landing-login',
  templateUrl: './landing-login.html',
  styleUrls: ['./landing-login.scss'],
})
export class LandingLoginComponent {
  @Input() showSubNav = true;
  @Input() heroImageCol = null;
  @Input() styleClass = null;
  @Input() title = '';
  @Input() showBackButton = true;
  @Input() backButtonUrl = '';
  @Input() backButtonQueryParams = '';
  @Input() hideSupportButtons = true;
  @Input() selectedCase = null;

  constructor() {}
}
