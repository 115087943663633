import { Component, Input } from '@angular/core';
import { ErrorStateMatcher } from '@angular/material/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-dropdown-select',
  templateUrl: './dropdown-select.component.html',
  styleUrls: ['./dropdown-select.component.scss'],
})
export class DropdownSelectComponent {
  @Input() parentForm: UntypedFormGroup;
  @Input() controlName: string;
  @Input() labelText: string;
  @Input() placeholderText: string;
  @Input() options: { value: string; text: string }[];
  @Input() isMultiple = false;
  @Input() maxSelected;
  @Input() showErrors: boolean = false;
  @Input() errorStateMatcher: ErrorStateMatcher = null;
  selectedValues: { value: string; text: string }[] = [];

  constructor() {}

  selectionChanged(option) {
    if (!this.isMultiple) {
      return;
    }
    // add or remove items from the selectedValues array
    // this is strictly visual to create the selected green pill options
    // the form control value is handled like the other mat input/selects
    if (this.selectedValues.indexOf(option) < 0) {
      this.selectedValues.push(option);
    } else {
      this.selectedValues = this.selectedValues.filter(value => {
        return value !== option;
      });
    }
  }
}
