<div class="container-fluid px-0 main-nav">
  <mat-toolbar>
    <mat-toolbar-row class="main-nav row-toolbar">
      <span class="d-none d-md-flex col-6 company-name pl-0">
        <ng-container *ngIf="billingAccounts$">
          <app-global-billing-filter> </app-global-billing-filter>
        </ng-container>
      </span>

      <span class="d-flex d-md-none col-3 nav-logo">
        <a [routerLink]="'/dashboard'">
          <img
            src="assets/images/rts-logo-white.png"
            alt="Recycle Track Systems" />
        </a>
      </span>

      <div class="d-none d-md-flex col justify-content-end">
        <div
          class="dropdown-md notifications-dropdown"
          *ngIf="whatsNew$ | async as whatsNew">
          <button
            mat-icon-button
            class="notification-toggle-wrapper"
            [matMenuTriggerFor]="notificationsMenu">
            <img src="assets/images/notification.svg" alt="Notifications" />
            <div
              *ngIf="(hasSeenWhatsNew$ | async) === false"
              class="notification-circle">
              <div class="notification-circle-inner"></div>
            </div>
          </button>
          <mat-menu #notificationsMenu="matMenu" class="dropdown-menu-md">
            <a
              mat-menu-item
              (click)="redirectToWhatsNew()"
              class="dropdown-nav-item-md"
              >What's New</a
            >
          </mat-menu>
        </div>
      </div>

      <div class="d-flex col-9 col-md-auto justify-content-end" id="user-name">
        <ng-container *ngIf="user$ | async as user">
          <a
            [matMenuTriggerFor]="hamburgerMenu"
            class="username d-none d-md-flex">
            <ngx-avatar
              size="32"
              [value]="user.name | userInitialsDisplayPipe"
              class="md-avatar"></ngx-avatar>
            {{ user.name | usernameDisplayPipe }}
          </a>
          <button
            class="hamburger-menu col-auto d-block d-md-none"
            [matMenuTriggerFor]="hamburgerMenu">
            <span></span>
            <span></span>
            <span></span>
            <div
              *ngIf="(hasSeenWhatsNew$ | async) === false"
              class="mobile-notification-circle"></div>
          </button>
          <mat-menu #hamburgerMenu="matMenu">
            <ng-container *ngFor="let page of pages">
              <ng-container *appFeature="page.featureName">
                <a
                  mat-menu-item
                  [routerLink]="page.routerLink"
                  class="d-flex d-md-none">
                  {{ page.name }}
                </a>
              </ng-container>
            </ng-container>
            <a
              mat-menu-item
              *ngFor="let item of dropdownNavItems"
              [routerLink]="item.routerLink">
              {{ item.name }}
            </a>
            <a mat-menu-item class="d-md-none" (click)="redirectToWhatsNew()">
              What's New
              <div
                *ngIf="(hasSeenWhatsNew$ | async) === false"
                class="red-notification-circle"></div>
            </a>
            <a mat-menu-item (click)="logout($event)">Log Out</a>
          </mat-menu>
        </ng-container>
      </div>
    </mat-toolbar-row>
    <mat-toolbar-row class="d-flex d-md-none mobile-company-name-container p-0">
      <div class="col-12 company-name">
        <ng-container *ngIf="billingAccounts$ | async as billingAccounts">
          <app-global-billing-filter> </app-global-billing-filter>
        </ng-container>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>
</div>
