import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { SerializedRouterStateSnapshot } from '@ngrx/router-store';
import { RouterSelectors } from '@app/store';
import { Store } from '@ngrx/store';

import { AppState } from '@app/store/app.state';
import { PageLink } from '@app/core/models/page-link';

@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.scss'],
})
export class AnalyticsComponent {
  routerState$: Observable<SerializedRouterStateSnapshot> = this.store.select(
    RouterSelectors.getRouterState
  );

  subNavItems: PageLink[] = [
    {
      name: 'Reports',
      routerLink: '/analytics/reports',
      cyName: 'nav-reports',
      featureName: 'AnalyticsReports',
    },
    {
      name: 'Sustainability',
      routerLink: '/analytics/sustainability',
      cyName: 'nav-reports-sustainability',
      featureName: 'AnalyticsSustainability',
    },
    {
      name: 'Sensors',
      routerLink: '/analytics/sensors',
      cyName: 'nav-reports-sensors',
      featureName: 'AnalyticsSensors',
    },
  ];

  constructor(private store: Store<AppState>) {}
}
