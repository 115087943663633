import { createReducer, on } from '@ngrx/store';
import { GlobalActions } from '@app/store/actions';
import { BillingAccount, Site } from '@app/core/models/UserDataModel';

export const globalFeatureKey = 'global';

export interface GlobalState {
  spinnerCounter: number;
  showLoadingVisual: boolean;
  previousURL: string;
  isSideNavOpen: boolean;
  isErrorShowing: boolean;
  errorMsg: string;
  billingAccounts: BillingAccount[];
}

const initialState: GlobalState = {
  spinnerCounter: 0,
  showLoadingVisual: false,
  previousURL: null,
  isSideNavOpen: true,
  isErrorShowing: false,
  errorMsg: '',
  billingAccounts: [],
};

function toggleBillingAccountSelection(
  billingAccounts: BillingAccount[],
  billingaccountToToggle: BillingAccount,
  site: Site = null
): BillingAccount[] {
  if (site === null) {
    return billingAccounts.map(billingAccount => {
      if (billingAccount.id === billingaccountToToggle.id) {
        // Toggle selection for the clicked billingAccount
        billingAccount = {
          ...billingAccount,
          selected: !billingAccount.selected,
        };

        if (billingAccount.sites.length) {
          billingAccount = {
            ...billingAccount,
            sites: billingAccount.sites.map(site => ({
              ...site,
              selected: billingAccount.selected,
            })),
          };
        }
      }

      return billingAccount;
    });
  }
  // only update a site
  return billingAccounts.map(billingAccount => {
    if (billingAccount.id === billingaccountToToggle.id) {
      if (billingAccount.sites.length) {
        billingAccount = {
          ...billingAccount,
          sites: billingAccount.sites.map(s => {
            if (s.id === site.id) {
              return { ...s, selected: !s.selected };
            }
            return s;
          }),
        };
      }
    }

    return billingAccount;
  });
}

export const globalReducer = createReducer(
  initialState,
  on(GlobalActions.showSpinner, state => ({
    ...state,
    showLoadingVisual: true,
  })),
  on(GlobalActions.hideSpinner, state => ({
    ...state,
    showLoadingVisual: false,
  })),
  on(GlobalActions.setPreviousURL, (state, { previousURL }) => ({
    ...state,
    previousURL,
  })),
  on(GlobalActions.toggleSideNav, state => ({
    ...state,
    isSideNavOpen: !state.isSideNavOpen,
  })),
  on(GlobalActions.showError, (state, { errorMsg }) => ({
    ...state,
    isErrorShowing: true,
    errorMsg: errorMsg,
  })),
  on(GlobalActions.hideError, state => ({
    ...state,
    isErrorShowing: false,
    errorMsg: '',
  })),
  on(GlobalActions.setGlobalFilters, (state, { billingAccounts }) => ({
    ...state,
    billingAccounts,
  })),
  on(
    GlobalActions.toggleBillingAccountSelection,
    (state, { billingAccount, site }) => {
      return {
        ...state,
        billingAccounts: toggleBillingAccountSelection(
          state.billingAccounts,
          billingAccount,
          site
        ),
      };
    }
  )
);
