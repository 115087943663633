import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AnalyticsState } from '..';

export const analyticsSlice =
  createFeatureSelector<AnalyticsState>('analytics');

export const showReportsListLoading = createSelector(
  analyticsSlice,
  (state: AnalyticsState) => state.showReportsListLoading
);

export const getReportList = createSelector(
  analyticsSlice,
  (analytics: AnalyticsState) => analytics.reportList
);

export const getReportToken = createSelector(
  analyticsSlice,
  (analytics: AnalyticsState) => {
    return analytics.token;
  }
);

export const isDemoRequestsuccess = createSelector(
  analyticsSlice,
  (state: AnalyticsState) => state.demoRequestsuccess
);

export const getReportRegionList = createSelector(
  analyticsSlice,
  (analytics: AnalyticsState) => analytics.reportRegionList
);

export const getRegionSiteList = createSelector(
  analyticsSlice,
  (analytics: AnalyticsState) => analytics.regionSiteList
);
