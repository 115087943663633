import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-submit-input',
  templateUrl: './submit-input.component.html',
  styleUrls: ['./submit-input.component.scss'],
})
export class SubmitInputComponent {
  @Input() parentForm: UntypedFormGroup;
  @Input() controlName: string;
  @Input() value: string;
  @Input() disabled: boolean;

  constructor() {}
}
