<div id="{{ controlName }}-input-container" [formGroup]="parentForm">
  <mat-form-field class="date-picker" appearance="outline">
    <mat-label>{{ label }}</mat-label>
    <input
      matInput
      id="{{ controlName }}-input"
      [formControlName]="controlName"
      [max]="maxDate"
      [min]="minDate"
      [matDatepicker]="picker"
      [placeholder]="placeholder" />
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
    <mat-error
      *ngIf="
        showErrors &&
        !parentForm.controls[controlName].hasError('required') &&
        parentForm.controls[controlName].errors
      ">
      Please enter a valid {{ label ? label : placeholder }}
    </mat-error>
    <mat-error
      *ngIf="
        showErrors && parentForm.controls[controlName].hasError('required')
      ">
      {{ label ? label : placeholder }} is <strong>required</strong>
    </mat-error>
  </mat-form-field>
</div>
