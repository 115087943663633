import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ContainerDevicesState } from '@app/store/reducers/container-devices.reducer';
import { ContainerDeviceResponse } from '@app/core/api';
import { SequoiaDeviceModel } from '@app/core/models';

export const selectContainerDeviceSlice =
  createFeatureSelector<ContainerDevicesState>('containerDevices');

export const showPelloDevicesLoading = createSelector(
  selectContainerDeviceSlice,
  (state: ContainerDevicesState) => state.showPelloDevicesLoading
);

export const showSequoiaDevicesLoading = createSelector(
  selectContainerDeviceSlice,
  (state: ContainerDevicesState) => state.showSequoiaDevicesLoading
);

export const getPelloDevices = createSelector(
  selectContainerDeviceSlice,
  (state: ContainerDevicesState) => {
    let devices = state.allPelloDevices;

    if (devices) {
      devices = [...state.allPelloDevices].sort((a, b) => {
        if (b.fullnessLevel === null) return -1;
        return b.fullnessLevel - a.fullnessLevel;
      });
    }

    return devices;
  }
);

export const getSequoiaDevices = createSelector(
  selectContainerDeviceSlice,
  (state: ContainerDevicesState) => {
    const sequoiaDevices: SequoiaDeviceModel[] = state.allSequoiaDevices;
    const devices = [];

    if (sequoiaDevices && sequoiaDevices.length) {
      devices.push(...sequoiaDevices);

      devices.sort((a, b) => {
        if (b.fullnessLevel === null) return -1;
        return b.fullnessLevel - a.fullnessLevel;
      });
    }

    return devices;
  }
);

export const getComplogyAndPelloDeviceCSVReport = createSelector(
  getPelloDevices,
  (allContainerDevices: ContainerDeviceResponse[]) =>
    allContainerDevices.map((containerDevice: any) => ({
      locationId: containerDevice.locationId,
      fullAddress: containerDevice.fullAddress,
      materialType: containerDevice.materialType
        ? containerDevice.materialType
        : 'N/A',
      contamination: containerDevice.cumulativeContamination
        ? containerDevice.cumulativeContamination
            .map(
              (item: any) =>
                item.contaminationType + ' = ' + item.contaminationCount
            )
            .join(', ')
        : 'N/A',
      isContaminated: containerDevice.isContaminated,
      fullnessLevel: containerDevice.fullnessLevel
        ? containerDevice.fullnessLevel
        : 'N/A',
      projectedPickupDate: containerDevice.projectedPickupDate,
      lastImageTimestamp: containerDevice.lastImageTimestamp,
      lastImageUrl: containerDevice.lastImageUrl,
    }))
);

export const getSequoiaDeviceLoggedFullness = createSelector(
  selectContainerDeviceSlice,
  (state: ContainerDevicesState) =>
    state.sequoiaLoggedFullness.map(value => ({
      ...value,
      color: '#21409A',
      tooltipText: 'Percentage Full: ',
    }))
);

export const getSequoiaDeviceForecastedFullness = createSelector(
  selectContainerDeviceSlice,
  (state: ContainerDevicesState) =>
    state.sequoiaForecastedFullness.map(value => ({
      ...value,
      color: '#98E1B8',
      tooltipText: 'Forecasted Fullness: ',
    }))
);

export const getSequoiaDeviceCombinedFullness = createSelector(
  getSequoiaDeviceLoggedFullness,
  getSequoiaDeviceForecastedFullness,
  (loggedFullness, forecastedFullness) => {
    const combinedData = loggedFullness.map(value => {
      const data = { ...value };

      if (data.percentFullness < 0) {
        data.percentFullness = null;
      }
      return data;
    });

    if (forecastedFullness.length > 0) {
      for (let i = 0; i < 12; i += 1) {
        // just grab the first 3 days of forecasted data
        if (forecastedFullness[i]) {
          // only add actual values (sometimes there are less than 12)
          combinedData.push(forecastedFullness[i]);
        }
      }
    }
    return combinedData;
  }
);

export const getSequoiaDeviceDataReady = createSelector(
  selectContainerDeviceSlice,
  devices => {
    if (devices.sequoiaForecastedFullness && devices.sequoiaLoggedFullness) {
      return true;
    }
  }
);

export const isDemoRequestsuccess = createSelector(
  selectContainerDeviceSlice,
  (state: ContainerDevicesState) => state.demoRequestsuccess
);
