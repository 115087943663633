import {
  Directive,
  Output,
  EventEmitter,
  HostBinding,
  HostListener,
} from '@angular/core';

@Directive({ selector: '[appDragAndDrop]' })
export class DragAndDropDirective {
  @HostBinding('class.file-over') fileOver: boolean;
  @Output() fileDropped = new EventEmitter<any>();
  @Output() onFileWarningMessage = new EventEmitter<string>();

  // Dragover listener
  @HostListener('dragover', ['$event']) onDragOver(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = true;
  }

  // Dragleave listener
  @HostListener('dragleave', ['$event']) public onDragLeave(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = false;
  }

  // Drop listener
  @HostListener('drop', ['$event']) public ondrop(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = false;

    const { files } = evt.dataTransfer;

    // if user drops more than one file, show message
    if (files.length > 1) {
      this.onFileWarningMessage.emit('Please select one file');
      return;
    }

    if (files.length > 0) {
      if (['image/png', 'image/jpeg', 'image/heic'].includes(files[0].type)) {
        this.fileDropped.emit(files);
      } else {
        this.onFileWarningMessage.emit(
          'Please select a supported file type - .jpg, .png, .heic'
        );
      }
    }
  }
}
