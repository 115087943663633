import { NgModule } from '@angular/core';
import {
  ExtraOptions,
  PreloadAllModules,
  RouterModule,
  Routes,
} from '@angular/router';
// import { FeatureGuard } from '@app/core/services/permissions/feature.guard';
import { AccountComponent } from '@app/features/account/account.component';
import { AnalyticsComponent } from '@app/features/analytics/analytics.component';
import { DashboardComponent } from '@app/features/dashboard/dashboard.component';
import { DocumentsComponent } from '@app/features/documents/documents.component';
import { LandingPageComponent } from '@app/features/landing-page/landing-page.component';
import { ServicesComponent } from '@app/features/services/services.component';
import { SupportComponent } from '@app/features/support/support.component';
import { Error404Component } from '@app/shared/components/error-404/error-404.component';
import { LegalDocumentComponent } from '@app/shared/components/legal-document/legal-document.component';
import { MsalGuard } from '@azure/msal-angular';
import { InvoicesComponent } from '@app/features/invoices/invoices.component';
import { GuestInvoicePaymentComponent } from '@app/features/guest-invoice-pmt/guest-invoice-pmt.component';
import { MuniSupportComponent } from '@app/features/muni-support/muni-support.component';
import { SensorsComponent } from '@app/features/sensors/sensors.component';
import { SupportPublicComponent } from '@app/features/support-public/support-public.component';

const routerSettings: ExtraOptions = {
  // enableTracing: true, // show router logs
  preloadingStrategy: PreloadAllModules,
  scrollPositionRestoration: 'enabled',
};

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: LandingPageComponent,
    loadChildren: () =>
      import('./features/landing-page/landing-page.module').then(
        m => m.LandingPageModule
      ),
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    loadChildren: () =>
      import('./features/dashboard/dashboard.module').then(
        m => m.DashboardModule
      ),
    pathMatch: 'full',
    data: { title: 'Dashboard' },
    canActivate: [MsalGuard],
  },
  {
    path: 'terms-and-conditions',
    component: LegalDocumentComponent,
    pathMatch: 'full',
    data: { type: 'terms' },
  },
  {
    path: 'privacy-policy',
    component: LegalDocumentComponent,
    pathMatch: 'full',
    data: { type: 'privacy' },
  },
  {
    path: 'organization',
    component: ServicesComponent,
    loadChildren: () =>
      import('./features/services/services.module').then(m => m.ServicesModule),
    data: {
      title: 'Sites',
      preload: true,
    },
    canActivate: [MsalGuard],
  },
  {
    path: 'one-time-services',
    component: ServicesComponent,
    loadChildren: () =>
      import('./features/services/services.module').then(m => m.ServicesModule),
    data: {
      title: 'One-time Services',
      preload: true,
    },
    canActivate: [MsalGuard],
  },
  {
    path: 'account',
    component: AccountComponent,
    loadChildren: () =>
      import('./features/account/account.module').then(m => m.AccountModule),
    canActivate: [MsalGuard],
    data: {
      title: 'Account',
      preload: true,
    },
  },
  {
    path: 'analytics',
    component: AnalyticsComponent,
    loadChildren: () =>
      import('./features/analytics/analytics.module').then(
        m => m.AnalyticsModule
      ),
    canActivate: [MsalGuard],
    data: {
      title: 'Analytics',
      preload: true,
      featureFlag: 'analytics',
    },
  },
  {
    path: 'sustainability',
    component: AnalyticsComponent,
    loadChildren: () =>
      import('./features/analytics/analytics.module').then(
        m => m.AnalyticsModule
      ),
    canActivate: [MsalGuard],
    data: {
      title: 'Sustainability',
      preload: true,
      featureFlag: 'Sustainability',
    },
  },
  {
    path: 'support-signed-in',
    component: SupportComponent,
    loadChildren: () =>
      import('./features/support/support.module').then(m => m.SupportModule),
    canActivate: [MsalGuard],
    data: {
      title: 'Support',
      preload: true,
      featureFlag: 'support',
    },
  },
  {
    path: 'support',
    component: SupportPublicComponent,
    loadChildren: () =>
      import('./features/support-public/support-public.module').then(
        m => m.SupportPublicModule
      ),
    data: {
      title: 'Submit a Support Ticket',
      preload: true,
      featureFlag: 'support-public',
    },
  },
  {
    path: 'invoices',
    component: InvoicesComponent,
    loadChildren: () =>
      import('./features/invoices/invoices.module').then(m => m.InvoicesModule),
    canActivate: [MsalGuard],
    data: {
      title: 'invoices',
      preload: true,
    },
  },
  {
    path: 'muni-support',
    component: MuniSupportComponent,
    loadChildren: () =>
      import('@app/features/muni-support/muni-support.module').then(
        m => m.MuniSupportModule
      ),
    data: {
      title: 'Submit a Municipal Support Ticket',
      preload: true,
      featureFlag: 'muni-support',
    },
  },
  {
    path: 'guest-invoice-pmt',
    component: GuestInvoicePaymentComponent,
    loadChildren: () =>
      import('./features/guest-invoice-pmt/guest-invoice-pmt.module').then(
        m => m.GuestInvoicePaymentModule
      ),
    // canActivate: [AuthGuard], TODO: Can I use a guard to check token validity?
    data: {
      title: 'GuestInvoicePayment',
      preload: true,
      featureFlag: 'guest-invoice-pmt',
    },
  },
  {
    path: 'documents',
    component: DocumentsComponent,
    loadChildren: () =>
      import('./features/documents/documents.module').then(
        m => m.DocumentsModule
      ),
    canActivate: [MsalGuard],
    data: {
      title: 'Documents',
      preload: true,
    },
  },
  {
    path: 'sensors',
    component: SensorsComponent,
    loadChildren: () =>
      import('./features/sensors/sensors.module').then(m => m.SensorsModule),
    data: {
      title: 'Sensors',
      preload: true,
    },
    canActivate: [MsalGuard],
  },
  {
    path: '**',
    component: Error404Component,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, routerSettings)],
  exports: [RouterModule],
  declarations: [Error404Component],
})
export class AppRoutingModule {}
