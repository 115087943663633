import {
  PhoneVerification,
  PhoneVerificationResponse,
} from '@app/core/models/twilio.model';
import { createAction, props } from '@ngrx/store';

export const verifyPhoneNumber = createAction(
  '[User Data] Verify Phone Number',
  props<{ requestObj: PhoneVerification }>()
);

export const verifyPhoneNumberSuccess = createAction(
  '[User Data] Verify Phone Number Success',
  props<{ response: PhoneVerificationResponse }>()
);

export const verifyPhoneNumberFailure = createAction(
  '[User Data] Verify Phone Number Failure',
  props<{ errorMsg: string }>()
);

export const verifyOTP = createAction(
  '[User Data] Verify OTP',
  props<{ requestObj: PhoneVerification }>()
);

export const verifyOTPSuccess = createAction(
  '[User Data] Verify OTP Success',
  props<{ response: PhoneVerificationResponse }>()
);

export const verifyOTPFailure = createAction(
  '[User Data] Verify OTP Failure',
  props<{ errorMsg: string }>()
);
