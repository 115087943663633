import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ContainerDeviceResponse } from '@app/core/api';
import { AzureBaseService } from '@app/core/services/azure-functions/azure-base.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class PelloService extends AzureBaseService {
  constructor(protected http: HttpClient) {
    super(http, '/func-customerportal-pello-');
  }

  getPelloDevicesByCustomerId(
    requestBody: string[]
  ): Observable<ContainerDeviceResponse[]> {
    if (!requestBody.length) {
      return;
    }

    const requestUrl = `${this.azureBaseUrl}${this.functionUri}${this.azureEnv}/GetPelloDevicesByCustomerId`;

    return this.http
      .post<HttpResponse<any>>(requestUrl, requestBody, this.httpOptions)
      .pipe(
        map(response => {
          return JSON.parse(response.body).map(pelloDeviceDetails => ({
            ...pelloDeviceDetails,
            isPelloDevice: true,
          })) as ContainerDeviceResponse[];
        })
      );
  }
}
