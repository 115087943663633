import { Injectable } from '@angular/core';
import {
  ScheduleAResponseService,
  ServiceSchedule,
} from '@app/core/api-azure-functions/dataverse-functions';
import { OnDemandProductModel } from '@app/core/models/on-demand-product.model';
import {
  ActiveSiteModel,
  SiteDetailsModel,
  SiteHaulerContractDocuments,
  SiteModel,
} from '@app/core/models/site.model';
import { DataverseSiteService } from '@app/core/services/azure-functions/dataverse-site.service';
import { RtsProductsService } from '@app/core/services/azure-functions/rts-products.service';
import { InvoicesService } from '@app/core/services/azure-functions/invoices.service';
import { SiteActions } from '@app/store/actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

@Injectable()
export class SiteEffects {
  constructor(
    private actions$: Actions,
    private serviceScheduleService: ScheduleAResponseService,
    private rtsProductsService: RtsProductsService,
    private dataverseSiteService: DataverseSiteService,
    private invoicesService: InvoicesService
  ) {}

  getSite$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SiteActions.getSite),
      switchMap(({ customerId, siteId }) => {
        if (customerId && siteId) {
          return this.dataverseSiteService.getSite(customerId, siteId).pipe(
            map((currentSite: SiteDetailsModel[]) =>
              SiteActions.getSiteSuccess({
                currentSite: currentSite[0],
              })
            ),
            catchError(err =>
              of(SiteActions.getSiteFailure({ errorMsg: err.message }))
            )
          );
        }
        return of(
          SiteActions.getSiteFailure({
            errorMsg: 'Customer ID or Site ID not found for site',
          })
        );
      })
    )
  );

  getSites$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SiteActions.getSites),
      switchMap(({ requestBody }) =>
        this.dataverseSiteService.getSites(requestBody).pipe(
          map((allSites: SiteModel[]) =>
            SiteActions.getSitesSuccess({ allSites })
          ),
          catchError(err =>
            of(SiteActions.getSitesFailure({ errorMsg: err.message }))
          )
        )
      )
    )
  );

  getActiveSites$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SiteActions.getActiveSites),
      switchMap(({ billingAccountIds }) => {
        if (billingAccountIds.length > 0) {
          return this.dataverseSiteService
            .getActiveSites(billingAccountIds)
            .pipe(
              map((allActiveSites: ActiveSiteModel[]) =>
                SiteActions.getActiveSitesSuccess({ allActiveSites })
              ),
              catchError(err =>
                of(SiteActions.getActiveSitesFailure({ errorMsg: err.message }))
              )
            );
        }

        return of(SiteActions.getActiveSitesFailure({ errorMsg: '' }));
      })
    )
  );

  getServiceScheduleDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SiteActions.getServiceScheduleDetails),
      switchMap(({ siteId }) =>
        this.serviceScheduleService.scheduleADetails(siteId).pipe(
          map((currentServiceSchedules: ServiceSchedule[]) => {
            // if api returns null, set schedules to empty array
            currentServiceSchedules =
              currentServiceSchedules === null ? [] : currentServiceSchedules;
            return SiteActions.getServiceSchedulesSuccess({
              currentServiceSchedules,
            });
          }),
          catchError(err =>
            of(
              SiteActions.getServiceSchedulesFailure({ errorMsg: err.message })
            )
          )
        )
      )
    )
  );

  getServiceSchedules$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SiteActions.getServiceSchedules),
      switchMap(({ requestBody }) =>
        this.serviceScheduleService.readScheduleAs(requestBody).pipe(
          map((currentServiceSchedules: ServiceSchedule[]) => {
            // if api returns null, set schedules to empty array
            currentServiceSchedules =
              currentServiceSchedules === null ? [] : currentServiceSchedules;
            return SiteActions.getServiceSchedulesSuccess({
              currentServiceSchedules,
            });
          }),
          catchError(err =>
            of(
              SiteActions.getServiceSchedulesFailure({
                errorMsg: err.message,
              })
            )
          )
        )
      )
    )
  );

  getAllAccountServiceSchedules$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SiteActions.getAllAccountServiceSchedules),
      switchMap(({ requestBody }) =>
        this.serviceScheduleService.readScheduleAs(requestBody).pipe(
          map((allAccountServiceSchedules: ServiceSchedule[]) =>
            SiteActions.getAllAccountServiceSchedulesSuccess({
              allAccountServiceSchedules,
            })
          ),
          catchError(err =>
            of(
              SiteActions.getAllAccountServiceSchedulesFailure({
                errorMsg: err.message,
              })
            )
          )
        )
      )
    )
  );

  getOnDemandProducts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SiteActions.getOnDemandProducts),
      switchMap(() =>
        this.rtsProductsService.getOnDemandProducts().pipe(
          map((onDemandProducts: OnDemandProductModel[]) =>
            SiteActions.getOnDemandProductsSuccess({
              onDemandProducts: onDemandProducts,
            })
          ),
          catchError(err =>
            of(
              SiteActions.getOnDemandProductsFailure({ errorMsg: err.message })
            )
          )
        )
      )
    )
  );

  getSiteHaulerContractDocuments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SiteActions.getSiteHaulerContractDocuments),
      switchMap(({ siteId }) =>
        this.invoicesService.getSiteHaulerContractDocuments(siteId).pipe(
          map((siteHaulerContractDocuments: SiteHaulerContractDocuments[]) =>
            SiteActions.getSiteHaulerContractDocumentsSuccess({
              siteHaulerContractDocuments,
            })
          ),
          catchError(err =>
            of(
              SiteActions.getSiteHaulerContractDocumentsFailure({
                errorMsg: err.message,
              })
            )
          )
        )
      )
    )
  );
}
