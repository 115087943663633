import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ContainerDeviceResponse } from '@app/core/api';
import { SequoiaDeviceModel } from '@app/core/models';
import { CP_PERMISSIONS, REGEX_PATTERN } from '@app/shared/app.constants';
import {
  AuthSelectors,
  ContainerDeviceActions,
  ContainerDeviceSelectors,
  GlobalSelectors,
  RouterSelectors,
} from '@app/store';
import { AppState } from '@app/store/app.state';
import { SerializedRouterStateSnapshot } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, switchMap } from 'rxjs/operators';

import { Router } from '@angular/router';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { SupportTicketSubmitModalComponent } from '@app/shared/components/support-ticket-submit-modal/support-ticket-submit-modal.component';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'book-a-demo-dialog',
  template: `<h1 mat-dialog-title class="rts-header-3">
      Request For Book A Demo
    </h1>
    <div mat-dialog-content class="border-top border-bottom pt-3">
      <p class="mb-5 rts-body-1">
        Please enter your email address and submit,then our executive will
        connect with you soon.
      </p>
      <form name="demoRequestForm" [formGroup]="demoRequestForm">
        <mat-form-field appearance="outline" [style.width.%]="100">
          <mat-label>Email Address</mat-label>
          <input matInput type="email" formControlName="email" required />
        </mat-form-field>
      </form>
    </div>
    <div mat-dialog-actions align="end">
      <button mat-button (click)="onCancelClick()">Cancel</button>
      <button
        mat-raised-button
        color="accent"
        [disabled]="!demoRequestForm.valid"
        [mat-dialog-close]="data.email"
        (click)="onDemoRequest()"
        cdkFocusInitial>
        Submit
      </button>
    </div>`,
})
export class BookADemoDialogComponent {
  demoRequestDisabled = false;

  demoRequestForm = this.fb.group({
    email: [
      null,
      [Validators.required, Validators.pattern(REGEX_PATTERN.EMAIL)],
    ],
  });
  constructor(
    private store: Store<AppState>,
    public dialogRef: MatDialogRef<BookADemoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { email: string },
    private fb: FormBuilder
  ) {}

  onDemoRequest(): void {
    if (this.demoRequestForm.valid) {
      this.store.dispatch(
        ContainerDeviceActions.createDemoRequest({
          email: this.demoRequestForm.controls.email.value,
        })
      );
    }
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'app-sensors',
  templateUrl: './sensors.component.html',
  styleUrls: ['./sensors.component.scss'],
})
export class SensorsComponent implements OnInit, OnDestroy {
  routerState$: Observable<SerializedRouterStateSnapshot> = this.store.select(
    RouterSelectors.getRouterState
  );
  pelloDeviceData$: Observable<ContainerDeviceResponse[]> = this.store.select(
    ContainerDeviceSelectors.getPelloDevices
  );
  sequoiaDeviceData$: Observable<SequoiaDeviceModel[]> = this.store.select(
    ContainerDeviceSelectors.getSequoiaDevices
  );

  showPelloDevicesLoading$: Observable<boolean> = this.store.select(
    ContainerDeviceSelectors.showPelloDevicesLoading
  );
  showSequoiaDevicesLoading$: Observable<boolean> = this.store.select(
    ContainerDeviceSelectors.showSequoiaDevicesLoading
  );

  isDemoRequestsuccess$: Observable<boolean> = this.store.select(
    ContainerDeviceSelectors.isDemoRequestsuccess
  );

  subscriptions: Subscription = new Subscription();
  email: string;
  hasPelloDevices = undefined;
  hasSequoiaDevices = undefined;
  loadingDevices = true;

  constructor(
    private store: Store<AppState>,
    private router: Router,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.isDemoRequestsuccess$.subscribe(success => {
        if (success !== null) {
          this.dialog.open(SupportTicketSubmitModalComponent, {
            disableClose: true,
            data: {
              demoRequest: success,
            },
          });

          this.email = null;
          this.store.dispatch(ContainerDeviceActions.resetDemoRequest());
        }
      })
    );

    this.subscriptions.add(
      this.pelloDeviceData$.subscribe(pelloDevices => {
        if (pelloDevices?.length) {
          if (
            (this.router.url === '/sensors' ||
              this.router.url === '/sensors/compactor-monitors') &&
            !this.hasPelloDevices
          ) {
            this.router.navigate(['sensors/container-devices']);
          }
          this.hasPelloDevices = true;
        }
        // else {
        //   if (!this.hasSequoiaDevices) {
        //     this.router.navigate(['sensors/container-devices']);
        //     this.hasPelloDevices = true;
        //   }
        // }
        this.loadingDevices = false;
      })
    );

    this.subscriptions.add(
      this.sequoiaDeviceData$.subscribe(sequoiaDevices => {
        if (sequoiaDevices?.length) {
          this.hasSequoiaDevices = true;
          if (this.router.url === '/sensors') {
            if (this.hasPelloDevices) {
              this.router.navigate(['sensors/container-devices']);
            } else {
              this.router.navigate(['sensors/compactor-monitors']);
            }
          }
        }
        this.loadingDevices = false;
      })
    );

    this.getSensorsData();
  }

  openBookADemoDialog(): void {
    const dialogRef = this.dialog.open(BookADemoDialogComponent, {
      maxWidth: '90%',
      data: { email: this.email },
    });

    dialogRef.afterClosed().subscribe(emailId => {
      this.email = emailId;

      // TODO: send mail and clean emailId
    });
  }

  filterDevices = (
    deviceIds: {
      pelloId: string;
      sequoiaId: string;
    }[]
  ) => {
    const { pelloIds, sequoiaIds } = deviceIds.reduce(
      (acc, { pelloId, sequoiaId }) => {
        if (pelloId) {
          acc.pelloIds.push(pelloId);
        }
        if (sequoiaId) {
          acc.sequoiaIds.push(sequoiaId);
        }
        return acc;
      },
      { pelloIds: [], sequoiaIds: [] }
    );

    return { pelloIds, sequoiaIds };
  };

  getSensorsData(): void {
    this.subscriptions.add(
      this.store
        .select(AuthSelectors.getUserPermissions)
        .pipe(
          switchMap(userPermissions => {
            const hasPelloDevicePermission = userPermissions.includes(
              CP_PERMISSIONS.SENSORS_PELLODATA_READ
            );

            const hasSequoiaDevicePermission = userPermissions.includes(
              CP_PERMISSIONS.SENSORS_SEQUOIADATA_READ
            );

            return this.store.select(GlobalSelectors.getSelectedDeviceIds).pipe(
              filter(() => {
                return hasPelloDevicePermission || hasSequoiaDevicePermission;
              }),
              distinctUntilChanged((prev, curr) => {
                const prevDeviceIds = this.filterDevices(prev);
                const currDeviceIds = this.filterDevices(curr);

                return (
                  JSON.stringify(prevDeviceIds) ===
                  JSON.stringify(currDeviceIds)
                );
              })
            );
          })
        )
        .subscribe(deviceIds => {
          const { pelloIds, sequoiaIds } = this.filterDevices(deviceIds);

          if (pelloIds.length) {
            this.store.dispatch(
              ContainerDeviceActions.showPelloDevicesLoading()
            );
            this.store.dispatch(
              ContainerDeviceActions.getPelloDevices({
                requestBody: pelloIds,
              })
            );
          } else {
            this.store.dispatch(
              ContainerDeviceActions.getPelloDevicesSuccess({
                allPelloDevices: [],
              })
            );
          }

          if (sequoiaIds.length) {
            this.store.dispatch(
              ContainerDeviceActions.showSequoiaDevicesLoading()
            );
            this.store.dispatch(
              ContainerDeviceActions.getSequoiaDevices({
                requestBody: sequoiaIds,
              })
            );
          } else {
            this.store.dispatch(
              ContainerDeviceActions.getSequoiaDevicesSuccess({
                allSequoiaDevices: [],
              })
            );
          }
        })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
