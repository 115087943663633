import { Pipe, PipeTransform } from '@angular/core';
import { ServiceFrequency } from '@app/core/models';

@Pipe({ name: 'serviceFrequencyPipe' })
export class ServiceFrequencyPipe implements PipeTransform {
  transform(serviceFrequencyId: number, effectiveDate: string): string {
    // 'On-Call' = 'On Demand'(5) AND no effectiveDate
    if (serviceFrequencyId === 5 && effectiveDate === null) {
      return 'On-Call';
    }
    return ServiceFrequency[serviceFrequencyId];
  }
}
