import { createFeatureSelector, createSelector } from '@ngrx/store';
import { GlobalState } from '@app/store';

export const selectGlobalSlice = createFeatureSelector<GlobalState>('global');

export const showLoadingVisual = createSelector(
  selectGlobalSlice,
  (state: GlobalState) => state.showLoadingVisual
);

export const getPreviousURL = createSelector(
  selectGlobalSlice,
  (state: GlobalState) => state.previousURL
);

export const isSideNavOpen = createSelector(
  selectGlobalSlice,
  (state: GlobalState) => state.isSideNavOpen
);

export const isErrorShowing = createSelector(
  selectGlobalSlice,
  (state: GlobalState) => state.isErrorShowing
);

export const errorMsg = createSelector(
  selectGlobalSlice,
  (state: GlobalState) => state.errorMsg
);

export const getGlobalFilters = createSelector(
  selectGlobalSlice,
  (state: GlobalState) => state.billingAccounts
);

export const getSelectedGlobalFilters = createSelector(
  selectGlobalSlice,
  (state: GlobalState) => {
    const selectedBillingAccounts = state.billingAccounts
      .filter(billingAccount => billingAccount.selected)
      .map(({ id, sites }) => {
        return {
          id,
          sites: sites.filter(site => site.selected).map(site => site.id),
        };
      });

    return { billingAccounts: selectedBillingAccounts };
  }
);

export const getSelectedGlobalFiltersWithDetails = createSelector(
  selectGlobalSlice,
  (state: GlobalState) => {
    const selectedBillingAccounts = state.billingAccounts
      .filter(billingAccount => billingAccount.selected)
      .map(({ id, name, sites, accountNumber }) => {
        return {
          id,
          name,
          accountNumber,
          sites: sites.filter(site => site.selected),
        };
      });

    return selectedBillingAccounts;
  }
);

export const getSelectedDeviceIds = createSelector(
  selectGlobalSlice,
  (state: GlobalState) => {
    return state.billingAccounts
      .filter(billingAccount => billingAccount.selected)
      .map(({ pelloId, sequoiaId }) => {
        return { pelloId, sequoiaId };
      });
  }
);
