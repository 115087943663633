import { AzureBaseService } from '@app/core/services/azure-functions/azure-base.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { OnDemandProductModel } from '@app/core/models/on-demand-product.model';

@Injectable({ providedIn: 'root' })
export class RtsProductsService extends AzureBaseService {
  constructor(protected http: HttpClient) {
    super(http, '/func-portal-api-');
  }

  getOnDemandProducts(): Observable<OnDemandProductModel[]> {
    const requestUrl =
      this.azureBaseUrl +
      this.functionUri +
      this.azureEnv +
      '/USA1/ondemand/products';

    return this.http
      .get<HttpResponse<any>>(requestUrl, this.httpOptions)
      .pipe(
        map(response => JSON.parse(response.body) as OnDemandProductModel[])
      );
  }
}
