<div [formGroup]="parentForm" [ngClass]="showErrors ? 'mb-2' : null">
  <mat-form-field
    class="select-input {{ isMultiple ? 'multi-select-input' : '' }}"
    appearance="outline">
    <mat-label>{{ labelText }}</mat-label>
    <mat-select
      placeholder="{{ placeholderText }}"
      panelClass="{{ isMultiple ? 'multi-select-panel' : '' }}"
      [multiple]="isMultiple"
      id="{{ controlName }}Container"
      [formControlName]="controlName"
      [errorStateMatcher]="errorStateMatcher">
      <!-- selectedValues - only used for multi-select dropdown -->
      <mat-select-trigger *ngIf="isMultiple">
        <span class="selected-text" *ngFor="let value of selectedValues">
          {{ value.text }}
        </span>
      </mat-select-trigger>

      <mat-option
        *ngFor="let option of options"
        [value]="option.value"
        (onSelectionChange)="selectionChanged(option)"
        class="{{
          isMultiple && maxSelected === selectedValues.length
            ? 'disable-option'
            : ''
        }}">
        {{ option.text }}
      </mat-option>
    </mat-select>

    <mat-error
      *ngIf="
        showErrors &&
        !parentForm.controls[controlName].hasError('required') &&
        parentForm.controls[controlName].errors
      ">
      Please enter a valid {{ labelText }}
    </mat-error>
    <mat-error
      *ngIf="
        showErrors && parentForm.controls[controlName].hasError('required')
      ">
      {{ labelText }} is <strong>required</strong>
    </mat-error>
  </mat-form-field>
</div>
