import { createReducer, on } from '@ngrx/store';
import { DocumentActions } from '@app/store/actions/';
import { DocumentDetailsModel } from '@app/core/models/document-details.model';

export const documentsFeatureKey = 'documents';

export interface DocumentsState {
  documents: DocumentDetailsModel[];
}

const initialState: DocumentsState = { documents: null };

export const documentsReducer = createReducer(
  initialState,
  on(DocumentActions.getDocumentsSuccess, (state, { documents }) => ({
    ...state,
    documents,
  }))
);
