export const REGEX_PATTERN = Object.freeze({
  EMAIL: '^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,}$',
  ZIPCODE: '^[0-9]{5}(?:-[0-9]{4})?$',
  CA_POSTALCODE: '^[A-Za-z][0-9][A-Za-z][ -]?[0-9][A-Za-z][0-9]$',
  // prettier-ignore
  // eslint-disable-next-line no-useless-escape
  PHONENUMBER: '^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$',
});

export const SSRS_REPORT_BASE_URL =
  'https://reports.rts.com/ReportServer/Pages/ReportViewer.aspx?%2fReports%2f';

export const POWER_BI_REPORT_BASE_URL =
  'https://app.powerbi.com/reportEmbed?autoAuth=true&filter=Portal_Users%2F&ctid=36ff142e-a1a0-4517-9258-e9179df35506';

export const CP_PERMISSIONS = Object.freeze({
  DASHBOARD_READ: 'CP:Dashboard:OrgRead',
  DOCUMENTS_READ: 'CP:Documents:OrgRead',

  SITES_SERVICESCHEDULE_READ: 'CP:Sites:ServiceSchedule:OrgRead',
  SITES_SITEINFO_READ: 'CP:Sites:SiteInfo:OrgRead',
  SITES_SCHEDULE_READ: 'CP:Sites:Schedule:OrgRead',

  SUPPORTREQUEST_READ: 'CP:SupportRequest:OrgRead',
  SUPPORTREQUEST_WRITE: 'CP:SupportRequest:OrgWrite',

  SERVICEREQUEST_READ: 'CP:ServiceRequest:OrgRead',
  SERVICEREQUEST_WRITE: 'CP:ServiceRequest:OrgWrite',
  SERVICEMGMT_SITEDETAILS_READ: 'CP:ServiceMgmt:SiteDetails:OrgRead',
  SERVICEMGMT_SCHEDULE_READ: 'CP:ServiceMgmt:Schedule:OrgRead',

  SENSORS_SEQUOIADATA_READ: 'CP:Sensors:SequoiaData:OrgRead',
  SENSORS_SEQUOIAIMAGES_READ: 'CP:Sensors:SequoiaImages:OrgRead',
  SENSORS_PELLODATA_READ: 'CP:Sensors:PelloData:OrgRead',
  SENSORS_PELLOIMAGES_READ: 'CP:Sensors:PelloImages:OrgRead',
  ONETIME_READ: 'CP:One-Time:OrgRead',

  ANALYTICS_REPORTS_READ: 'CP:Analytics:Reports:OrgRead',
  ANALYTICS_SUSTAINABILITY_READ: 'CP:Analytics:Sustainability:OrgRead',
  ANALYTICS_SENSORS_READ: 'CP:Analytics:Sensors:OrgRead',
  // REPORTS_DIVERSIONREPORTS_READ: 'CP:Reports:DiversionReports:OrgRead',
  // REPORTS_SUSTAINABILITY_READ: 'CP:Reports:Sustainability:OrgRead',
  // REPORTS_CYCLECOLLECTION_READ: 'CP:Reports:CycleCollection:OrgRead',
  // REPORTS_GAMIFICATION_READ: 'CP:Reports:Gamification:OrgRead',

  SUPPORTREQUEST_SUSTAINABILITY_WRITE:
    'CP:SupportRequest:Sustainability:OrgWrite',
  SUPPORTREQUEST_REPORTING_WRITE: 'CP:SupportRequest:Reporting:OrgWrite',

  INVOICES_READ: 'CP:Invoices:OrgRead',
  // INVOICES_ORGPAY: 'CP:Invoices:OrgPay',
  // INVOICES_ORGDISPUTE: 'CP:Invoices:OrgDispute',
  HAULERINVOICES_READ: 'CP:HaulerInvoices:OrgRead',
  VENDORINVOICE_READ: 'CP:VendorInvoices:OrgRead',

  HAULERCONTRACT_READ: 'CP:HaulerMgmt:Contracts:OrgRead',

  SERVICECHANGE_WRITE: 'CP:ServiceChange:OrgWrite',
  CANCELSITE_WRITE: 'CP:CancelSite:OrgWrite',
});

export const SUPPORT_TYPE = Object.freeze({
  SERVICE_REQUEST: '3', // Request
  MISSED_SERVICE: '118100006', // Missed Service
  DAMAGED_EQUIPMENT: '118100002', // Damaged/Replace Equipment
  INCIDENTS_ACCIDENTS: '118100007', // Incidents/Accidents
  FINANCE: '118100000', // Finance form
  SUSTAINIBILITY: '118100014', // sustainability
  REPORTING: '118100016',
});
