import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'phone' })
export class PhonePipe implements PipeTransform {
  transform(rawNum) {
    if (rawNum === null) {
      return '';
    }

    if (rawNum.toString().length !== 10) {
      return '';
    }

    const phoneString = rawNum.toString();
    const firstPart = phoneString.substr(0, 3);
    const secondPart = phoneString.substr(3, 3);
    const thirdPart = phoneString.substr(6, 4);

    return '(' + firstPart + ') ' + secondPart + '-' + thirdPart;
  }
}
