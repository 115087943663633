import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { UserDataActions } from '@app/store';
import { catchError, map, switchMap } from 'rxjs/operators';
import { UserDataService } from '@app/core/services/azure-functions/user-data.service';
import { of } from 'rxjs';
import { UserDataModel } from '@app/core/models/UserDataModel';

@Injectable()
export class UserDataEffects {
  constructor(
    private actions$: Actions,
    private userService: UserDataService
  ) { }

  userGetByAdObjectId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserDataActions.userGetByAdObjectId),
      switchMap(({ adObjectId }) =>
        this.userService.userGetByAdId(adObjectId).pipe(
          map((user: UserDataModel) => {
            return UserDataActions.userGetByAdObjectIdSuccess({ user });
          }),
          catchError(err =>
            of(UserDataActions.userGetByAdObjectIdFailure({ errorMsg: err }))
          )
        )
      )
    )
  );

  userUpdateById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserDataActions.userUpdateById),
      switchMap(({ user }) =>
        this.userService.userUpdateById(user).pipe(
          map((user: UserDataModel) =>
            UserDataActions.userUpdateByIdSuccess({ user })
          ),
          catchError(err =>
            of(UserDataActions.userUpdateByIdFailure({ errorMsg: err }))
          )
        )
      )
    )
  );
  userSaveLoggedInTime$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserDataActions.userSaveLoggedInTime),
      switchMap(({ adObjectId }) =>
        this.userService.userSaveLoggedInTime(adObjectId).pipe(
          map((isSuccess: boolean) =>
            UserDataActions.userSaveLoggedInTimeSuccess({ isSuccess })
          ),
          catchError(err =>
            of(UserDataActions.userSaveLoggedInTimeFailure({ errorMsg: err }))
          )
        )
      )
    )
  );
}
