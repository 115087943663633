import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import * as d3 from 'd3-selection';
import * as d3Scale from 'd3-scale';
import * as d3Axis from 'd3-axis';
import { Observable, Subscription } from 'rxjs';
import { color } from 'd3';
import { Store } from '@ngrx/store';
import { AppState } from '@app/store/app.state';
import { ContainerDeviceActions } from '@app/store';

@Component({
  selector: 'app-bar',
  templateUrl: './bar.component.html',
  styleUrls: ['./bar.component.scss'],
})
export class BarComponent implements OnInit, OnDestroy {
  @Input() dataIn: Observable<any[]>;
  @Input() width: number = 1400;
  @Input() height: number = 500;
  @Input() margin: number = 50;
  subscriptions: Subscription = new Subscription();
  svg;

  tooltip = d3
    .select('body')
    .append('div')
    .style('position', 'absolute')
    .style('z-index', '10')
    .style('visibility', 'hidden')
    .style('background', '#000')
    .style('color', '#FFFFFF');

  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    this.createSVG();
    const dataSubscription = this.dataIn.subscribe(dataResult => {
      if (dataResult) {
        const dataPoints: {
          x: string;
          y: number;
          color: string;
          tooltipText: string;
        }[] = [];

        const oDataKeys = Object.keys(dataResult[0]);

        dataResult.forEach(value => {
          dataPoints.push({
            x: value[oDataKeys[0]] || '',
            y: value[oDataKeys[1]] || 0,
            color: value[oDataKeys[2]] || '#FFCB65',
            tooltipText: value[oDataKeys[3]] || '',
          });
        });
        d3.selectAll('g > *').remove(); // clears any previously drawn bars
        this.drawBars(dataPoints);
      }
    });

    this.subscriptions.add(dataSubscription);
  }

  createSVG(): void {
    this.svg = d3
      .select('figure#bar')
      .append('svg')
      .attr('viewBox', '0, 0, 1500, 600')
      .append('g')
      .attr('transform', 'translate(' + this.margin + ',' + this.margin + ')')
      .classed('svg-responsive-container', true);
  }

  private drawBars(data: any[]): void {
    // Create the X-axis band scale
    const x = d3Scale
      .scaleBand()
      .range([0, this.width])
      .domain(data.map(d => d.x))
      .padding(0.2);

    const xAxisTickDataPoints = data.filter(
      (_value, index) => index % Math.round(data.length / 10) === 0
    );
    const xAxisTickValues: string[] = [];

    xAxisTickDataPoints.forEach(value => xAxisTickValues.push(value.x));

    // Draw the X-axis on the DOM
    this.svg
      .append('g')
      .attr('transform', 'translate(0,' + this.height + ')')
      .call(
        d3Axis
          .axisBottom(x)
          .tickValues(xAxisTickValues)
          .tickFormat(d => d.split(' ')[0])
      )
      .selectAll('text')
      .attr('transform', 'translate(25, 0)')
      .style('text-anchor', 'end')
      .style('font-size', '1rem');

    // Create the Y-axis band scale
    const y = d3Scale.scaleLinear().domain([0, 115]).range([this.height, 0]);

    // Draw the Y-axis on the DOM
    this.svg.append('g').call(d3Axis.axisLeft(y)).style('font-size', '1rem');

    // Create and fill the bars
    this.svg
      .selectAll('bars')
      .data(data)
      .enter()
      .append('rect')
      .attr('x', d => x(d.x))
      .attr('y', d => y(d.y))
      .attr('width', x.bandwidth())
      .attr('height', d => this.height - y(d.y))
      .attr('fill', d => color(d.color))
      .on('mouseover', (_event, d) => {
        this.tooltip.html(d.tooltipText + d.y + '<br>' + d.x);
        return this.tooltip.style('visibility', 'visible');
      })
      .on('mousemove', event => {
        this.tooltip
          .style('top', event.pageY - 10 + 'px')
          .style('left', event.pageX + 10 + 'px');
      })
      .on('mouseout', () => {
        this.tooltip.style('visibility', 'hidden');
      });
  }

  ngOnDestroy(): void {
    this.store.dispatch(ContainerDeviceActions.resetSequoiaDeviceData());
  }
}
