import { AzureBaseService } from '@app/core/services/azure-functions/azure-base.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BillingAccountsReqObj } from '@app/core/models/UserDataModel';
import { WorkOrderModel, WorkOrderDetailsModel } from '@app/core/models/workorder.model';

@Injectable({ providedIn: 'root' })
export class DataverseWorkorderService extends AzureBaseService {
    constructor(protected http: HttpClient) {
        super(http, '/func-customerportal-dataverse-');
    }

    getWorkOrders(requestBody: BillingAccountsReqObj): Observable<WorkOrderModel[]> {
        const requestUrl = `${this.azureBaseUrl}${this.functionUri}${this.azureEnv}/workorder`;

        return this.http
            .post<HttpResponse<any>>(requestUrl, requestBody, this.httpOptions)
            .pipe(map(response => JSON.parse(response.body) as WorkOrderModel[]));
    }

    getWorkOrderDetails(workOrderId): Observable<WorkOrderDetailsModel[]> {
        const requestUrl = `${this.azureBaseUrl}${this.functionUri}${this.azureEnv}/workorderwithproducts/workorderid/${workOrderId}`;

        return this.http
            .get<HttpResponse<any>>(requestUrl, this.httpOptions)
            .pipe(map(response => JSON.parse(response.body) as WorkOrderDetailsModel[]));
    }

    getWorkOrdersWithProducts(requestBody: BillingAccountsReqObj): Observable<WorkOrderModel[]> {
        const requestUrl = `${this.azureBaseUrl}${this.functionUri}${this.azureEnv}/workorderswithproducts`;
        return this.http
            .post<HttpResponse<any>>(requestUrl, requestBody, this.httpOptions)
            .pipe(map(response => JSON.parse(response.body) as WorkOrderModel[]));
    }


}
