<div id="error-404-container">
  <div id="error-404-content">
    <div id="error-404-title">404: Page Not Found</div>
    <div id="error-404-description">
      <span>
        Unfortunately this page does not exist. Please check your URL or return
        to the <a (click)="reRouteOnClick()">Home</a> page.
      </span>
    </div>
  </div>
</div>
