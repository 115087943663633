import { CorporateActions } from '@app/store/actions';
import { createReducer, on } from '@ngrx/store';
import { Account } from '@app/core/api-azure-functions/dataverse-functions';

export const corporateFeatureKey = 'corporate';

export interface CorporateState {
  corporateAccount: Account | null;
}

const initialState: CorporateState = { corporateAccount: null };

export const corporateReducer = createReducer(
  initialState,
  on(
    CorporateActions.getCorporateAccountSuccess,
    (state, { corporateAccount }) => ({ ...state, corporateAccount })
  )
);
