import { AuthActions } from '@app/store/actions';
import { createReducer, on } from '@ngrx/store';
import { UserIds } from '@app/core/models';
import { AccountInfo } from '@azure/msal-browser';

export const authFeatureKey = 'auth';

export interface AuthState {
  user?: AccountInfo;
  userIds: UserIds | null;
  userPermissions: string[];
  compologyId: string;
  sequoiaId: string;
  pelloId: string;
}

const initialState: AuthState = {
  user: null,
  userIds: null,
  userPermissions: [],
  compologyId: null,
  sequoiaId: null,
  pelloId: null,
};

export const authReducer = createReducer(
  initialState,
  on(AuthActions.getAuthUserSuccess, (state, { user }) => ({ ...state, user })),
  on(AuthActions.setAuthUserIds, (state, { userIds }) => ({
    ...state,
    userIds,
  })),
  on(AuthActions.setAuthUserPermissions, (state, { userPermissions }) => ({
    ...state,
    userPermissions,
  }))
);
