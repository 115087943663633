import { Component, HostBinding } from '@angular/core';

@Component({
  selector: 'app-grid-item-loading-visual',
  templateUrl: './grid-item-loading-visual.component.html',
  styleUrls: ['./grid-item-loading-visual.component.scss'],
})
export class GridItemLoadingVisualComponent {
  @HostBinding('class') classAttribute = 'col-sm-6 col-md-4 col-lg-3';

  constructor() {}
}
