import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { SerializedRouterStateSnapshot } from '@ngrx/router-store';
import { RouterSelectors } from '@app/store';
import { Store } from '@ngrx/store';
import { AppState } from '@app/store/app.state';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss'],
})
export class AccountComponent {
  routerState$: Observable<SerializedRouterStateSnapshot> = this.store.select(
    RouterSelectors.getRouterState
  );

  constructor(private store: Store<AppState>) {}
}
