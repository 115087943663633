import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'usernameDisplayPipe' })
export class UsernameDisplayPipe implements PipeTransform {
  transform(username: string): string {
    return username ? username.split(' ')[0] : '';
  }
}

@Pipe({ name: 'userInitialsDisplayPipe' })
export class UserInitialsDisplayPipe implements PipeTransform {
  transform(username: string): string {
    const names = username.split(' ');
    let initials = '';

    for (const name of names) {
      initials += name[0];
    }

    return initials.length > 0 ? initials : '?';
  }
}
