import { RouterStateSnapshot } from '@angular/router';
import {
  Action,
  ActionReducer,
  ActionReducerMap,
  MetaReducer,
} from '@ngrx/store';
import { InjectionToken } from '@angular/core';
import { localStorageSync } from 'ngrx-store-localstorage';

import { environment as env } from '@env/environment';
import * as fromRouter from '@ngrx/router-store';
import * as fromRoot from './reducers';

// application state
export interface AppState {
  [fromRouter.DEFAULT_ROUTER_FEATURENAME]?: fromRouter.RouterReducerState<RouterStateSnapshot>;
  [fromRoot.authFeatureKey]?: fromRoot.AuthState;
  [fromRoot.corporateFeatureKey]?: fromRoot.CorporateState;
  [fromRoot.globalFeatureKey]?: fromRoot.GlobalState;
  [fromRoot.servicesFeatureKey]?: fromRoot.ServicesState;
  [fromRoot.sitesFeatureKey]?: fromRoot.SitesState;
  [fromRoot.containerDevicesFeatureKey]?: fromRoot.ContainerDevicesState;
  [fromRoot.billingFeatureKey]?: fromRoot.BillingState;
  [fromRoot.contentfulFeatureKey]?: fromRoot.ContentfulState;
  [fromRoot.userFeatureKey]?: fromRoot.UserDataState;
  [fromRoot.caseFeatureKey]?: fromRoot.CaseState;
  [fromRoot.documentsFeatureKey]?: fromRoot.DocumentsState;
  [fromRoot.invoicesFeatureKey]?: fromRoot.InvoicesState;
  [fromRoot.analyticsFeatureKey]?: fromRoot.AnalyticsState;
  [fromRoot.workOrderFeatureKey]?: fromRoot.WorkOrderState;
}

// top level reducer object
export const ROOT_REDUCERS = new InjectionToken<
  ActionReducerMap<AppState, Action>
>('Root reducers token', {
  factory: () => ({
    [fromRouter.DEFAULT_ROUTER_FEATURENAME]: fromRouter.routerReducer,
    [fromRoot.authFeatureKey]: fromRoot.authReducer,
    [fromRoot.corporateFeatureKey]: fromRoot.corporateReducer,
    [fromRoot.globalFeatureKey]: fromRoot.globalReducer,
    [fromRoot.servicesFeatureKey]: fromRoot.servicesReducer,
    [fromRoot.sitesFeatureKey]: fromRoot.sitesReducer,
    [fromRoot.containerDevicesFeatureKey]: fromRoot.containerDevicesReducer,
    [fromRoot.billingFeatureKey]: fromRoot.billingReducer,
    [fromRoot.contentfulFeatureKey]: fromRoot.contentfulReducer,
    [fromRoot.userFeatureKey]: fromRoot.userDataReducer,
    [fromRoot.caseFeatureKey]: fromRoot.caseReducer,
    [fromRoot.documentsFeatureKey]: fromRoot.documentsReducer,
    [fromRoot.invoicesFeatureKey]: fromRoot.invoicesReducer,
    [fromRoot.analyticsFeatureKey]: fromRoot.analyticsReducer,
    [fromRoot.workOrderFeatureKey]: fromRoot.workOrderReducer,
  }),
});

// on the rehydration from localstorage back to NGRX store
// it performs a full deepmerge on an INIT_ACTION or an UPDATE_ACTION
// see mergeReducer in ngrx-store-localstorage docs
function localStorageSyncReducer(
  reducer: ActionReducer<AppState>
): ActionReducer<AppState> {
  return localStorageSync({
    // set store feature(contentFeatureKey) and specific slices(whatsNew) that you want to save in localstorage
    keys: [{ [fromRoot.contentfulFeatureKey]: ['whatsNew'] }],
    rehydrate: true,
    restoreDates: false, // allows us to save ISO strings and have localStorage return as a string
  })(reducer);
}

// console.log all actions
export function logger(
  reducer: ActionReducer<AppState>
): ActionReducer<AppState> {
  return (state, action) => {
    const result = reducer(state, action);

    console.groupCollapsed(action.type);
    console.log('prev state', state);
    console.log('action', action);
    console.log('next state', result);
    console.groupEnd();

    return result;
  };
}

// additional logging for development
export const metaReducers: MetaReducer<AppState>[] =
  env.production === 'false'
    ? [localStorageSyncReducer, logger]
    : [localStorageSyncReducer];
