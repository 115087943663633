import { createReducer, on } from '@ngrx/store';
import { ContentfulActions } from '@app/store';
import { ContentfulModel } from '@app/core/models/contentful.model';

export const contentfulFeatureKey = 'contentful';

export interface ContentfulState {
  whatsNew: ContentfulModel | null;
  termsAndConditions: ContentfulModel | null;
  privacyPolicy: ContentfulModel | null;
}

const initialState: ContentfulState = {
  whatsNew: null,
  termsAndConditions: null,
  privacyPolicy: null,
};

export const contentfulReducer = createReducer(
  initialState,
  on(ContentfulActions.getWhatsNewSuccess, (state, { whatsNew }) => ({
    ...state,
    whatsNew: whatsNew
      ? {
          // if contentful API returns nothing, set store to null
          content: whatsNew.content,
          contentPostId: whatsNew.contentPostId,
          updatedDate: whatsNew.updatedDate,
          // if the current whatsNew value in the store is null, this is the users first time loading this data
          // they have NOT seen the content
          // OR
          // if at any point the updatedDates do not match
          // new content has been published that the user has NOT seen
          // OTHERWISE
          // keep the original state (which could be true or false)
          hasSeen:
            state.whatsNew === null ||
            state.whatsNew.updatedDate !== whatsNew.updatedDate
              ? false
              : state.whatsNew.hasSeen,
        }
      : null,
  })),
  on(
    ContentfulActions.getTermsAndConditionsSuccess,
    (state, { termsAndConditions }) => ({
      ...state,
      termsAndConditions,
    })
  ),
  on(ContentfulActions.getPrivacyPolicySuccess, (state, { privacyPolicy }) => ({
    ...state,
    privacyPolicy,
  })),
  on(ContentfulActions.markWhatsNewAsSeen, state => ({
    ...state,
    // only mark as seen if whatsNew exists
    whatsNew: state.whatsNew
      ? {
          ...state.whatsNew,
          hasSeen: true,
        }
      : null,
  }))
);
