import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

import { ServiceDetailResponse } from '@app/core/api';
import { ServiceActions } from '@app/store/actions';
import { ServiceDetailsService } from '@app/core/api-azure-functions/dataverse-functions';

@Injectable()
export class ServiceEffects {
  constructor(
    private actions$: Actions,
    private accessServiceDetailService: ServiceDetailsService
  ) {}

  getServices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ServiceActions.getServices),
      switchMap(({ mitAccessAccountId }) =>
        this.accessServiceDetailService
          .readServiceDetailCollection(mitAccessAccountId, mitAccessAccountId)
          .pipe(
            map((allServices: ServiceDetailResponse[]) =>
              ServiceActions.getServicesSuccess({ allServices })
            ),
            catchError(err =>
              of(ServiceActions.getServicesFailure({ errorMsg: err.message }))
            )
          )
      )
    )
  );
}
