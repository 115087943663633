/**
 * OpenAPI Document on Azure Functions
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface ServiceDetailResponseFinal {
  accountName?: string;
  status?: ServiceDetailResponseFinal.StatusEnum;
  addressId?: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  serviceType?: string;
  debrisType?: string;
  equipmentType?: string;
  serviceWindow?: string;
  date?: string;
  servicedTime?: string;
  actualIn?: string;
  actualOut?: string;
  duration?: string;
  weight?: string;
  tenantId?: string;
  accountId?: string;
  id?: string;
  createdDate?: string;
  createdBy?: string;
  updatedDate?: string;
  updatedBy?: string;
}
export namespace ServiceDetailResponseFinal {
  export type StatusEnum = 0 | 1 | 2 | 3 | 4;
  export const StatusEnum = {
    NUMBER_0: 0 as StatusEnum,
    NUMBER_1: 1 as StatusEnum,
    NUMBER_2: 2 as StatusEnum,
    NUMBER_3: 3 as StatusEnum,
    NUMBER_4: 4 as StatusEnum,
  };
}
