import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { animateCollapsible } from '@app/_animations/animations';

@Component({
  selector: 'app-collapsible-section',
  templateUrl: './collapsible-section.component.html',
  styleUrls: ['./collapsible-section.component.scss'],
  animations: [animateCollapsible],
})
export class CollapsibleSection {
  @Input() contentId: string = '';
  @Input() buttonTitle: string = '';
  @Input() isOpen: boolean = false;
  @Input() content: string = null;
  @Input() title: string = null;
  @Input() date: string = null;
  @Output() sectionOpened: EventEmitter<any> = new EventEmitter();

  constructor(public datePipe: DatePipe) {}

  toggleSection() {
    this.isOpen = !this.isOpen;

    if (this.isOpen) {
      this.sectionOpened.emit();
    }
  }
}
