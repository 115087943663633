import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DocumentsState } from '@app/store/reducers/documents.reducer';

export const selectDocumentsSlice =
  createFeatureSelector<DocumentsState>('documents');

export const getDocuments = createSelector(
  selectDocumentsSlice,
  (state: DocumentsState) => state.documents
);
