<div class="container-fluid form-loading-wrapper">
  <div class="row">
    <div class="col-md-5 form-loading-field">
      <div class="input-loading"></div>
    </div>
    <div class="col-md-5 form-loading-field">
      <div class="input-loading"></div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-4 form-loading-field">
      <div class="input-loading"></div>
    </div>
    <div class="col-md-4 form-loading-field">
      <div class="input-loading"></div>
    </div>
    <div class="col-md-4 form-loading-field">
      <div class="input-loading"></div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6 form-loading-field">
      <div class="input-loading"></div>
    </div>
    <div class="col-md-6 form-loading-field">
      <div class="input-loading"></div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 form-loading-description">
      <div class="input-loading"></div>
    </div>
  </div>
</div>
