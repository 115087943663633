<div class="legalDocument">
  <img
    alt="rts logo dark"
    src="/assets/images/rts-new-logo-midnight-blue.png"
    (click)="redirectToHome()" />
  <div *ngIf="content$ | async as content" class="contentContainer">
    <h3 class="title" [innerHTML]="content.title"></h3>
    <br />
    <div>
      Last Modified:
      {{ datePipe.transform(content.updatedDate, 'longDate') }}
    </div>
    <br />
    <div [innerHTML]="content.content"></div>
  </div>
</div>
