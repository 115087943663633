import { Component } from '@angular/core';

@Component({
  selector: 'app-table-loading-visual',
  templateUrl: './table-loading-visual.component.html',
  styleUrls: ['./table-loading-visual.component.scss'],
})
export class TableLoadingVisualComponent {
  constructor() {}
}
