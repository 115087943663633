import { Injectable } from '@angular/core';
import { GlobalActions } from '@app/store';
import { Store } from '@ngrx/store';
import { AppState } from '@app/store/app.state';

@Injectable({ providedIn: 'root' })
export class ErrorHandlerService {
  constructor(public store: Store<AppState>) {}

  show(errorMsg) {
    this.store.dispatch(GlobalActions.showError({ errorMsg: errorMsg }));
  }

  hide() {
    this.store.dispatch(GlobalActions.hideError());
  }
}
