import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment as env } from '@env/environment';

const stripTrailingSlash = (str: string) =>
  str.endsWith('/') ? str.slice(0, -1) : str;

@Injectable({ providedIn: 'root' })
export class AzureBaseService {
  httpOptions: Record<string, unknown> = {
    headers: new HttpHeaders(),
    responseType: 'text',
    observe: 'response',
    contentType: 'application/json',
  };
  azureBaseUrl = stripTrailingSlash(env.azureCustomerPortalAPIMGatewayBaseUrl);
  azureEnv = env.azureEnvironment;
  functionUri = '';

  constructor(
    protected http: HttpClient,
    functionUri: string
  ) {
    this.functionUri = functionUri;
  }
}
