import { Injectable } from '@angular/core';
import { WorkOrderModel, WorkOrderDetailsModel } from '@app/core/models/workorder.model';
import { DataverseWorkorderService } from '@app/core/services/azure-functions/dataverse-workorder.service';
import { WorkOrderActions } from '@app/store/actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

@Injectable()
export class WorkOrderEffects {
    constructor(
        private actions$: Actions,
        private dataverseWorkorderService: DataverseWorkorderService
    ) { }

    getWorkOrders$ = createEffect(() =>
        this.actions$.pipe(
            ofType(WorkOrderActions.getWorkOrders),
            switchMap(({ requestBody }) =>
                this.dataverseWorkorderService.getWorkOrders(requestBody).pipe(
                    map((allWorkOrder: WorkOrderModel[]) =>
                        WorkOrderActions.getWorkOrdersSuccess({ allWorkOrder })
                    ),
                    catchError(err =>
                        of(WorkOrderActions.getWorkOrdersFailure({ errorMsg: err.message }))
                    )
                )
            )
        )
    );

    getWorkOrderDetails$ = createEffect(() =>
        this.actions$.pipe(
            ofType(WorkOrderActions.getWorkOrderDetails),
            switchMap(({ workOrderId }) => {
                if (workOrderId) {
                    return this.dataverseWorkorderService.getWorkOrderDetails(workOrderId).pipe(
                        map((currentWorkOrder: WorkOrderDetailsModel[]) =>
                            WorkOrderActions.getWorkOrderDetailsSuccess({
                                currentWorkOrder: currentWorkOrder[0],
                            })
                        ),
                        catchError(err =>
                            of(WorkOrderActions.getWorkOrderDetailsFailure({ errorMsg: err.message }))
                        )
                    );
                }
                return of(
                    WorkOrderActions.getWorkOrderDetailsFailure({
                        errorMsg: 'Work order details no not found.',
                    })
                );
            })
        )
    );

    getWorkOrdersWithProducts$ = createEffect(() =>
        this.actions$.pipe(
            ofType(WorkOrderActions.getWorkOrdersWithProducts),
            switchMap(({ requestBody }) =>
                this.dataverseWorkorderService.getWorkOrdersWithProducts(requestBody).pipe(
                    map((allWorkOrdersWithProducts: WorkOrderModel[]) =>
                        WorkOrderActions.getWorkOrdersWithProductsSuccess({ allWorkOrdersWithProducts })
                    ),
                    catchError(err =>
                        of(WorkOrderActions.getWorkOrdersWithProductsFailure({ errorMsg: err.message }))
                    )
                )
            )
        )
    );

}