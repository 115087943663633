export * from './accountAddressResponse.service';
import { AccountAddressResponseService } from './accountAddressResponse.service';
export * from './accountDetailsResponse.service';
import { AccountDetailsResponseService } from './accountDetailsResponse.service';
export * from './caseResponse.service';
import { CaseResponseService } from './caseResponse.service';
export * from './caseStatusResponse.service';
import { CaseStatusResponseService } from './caseStatusResponse.service';
export * from './caseTypesResponse.service';
import { CaseTypesResponseService } from './caseTypesResponse.service';
export * from './equipmentResponse.service';
import { EquipmentResponseService } from './equipmentResponse.service';
export * from './equipmentSizeResponse.service';
import { EquipmentSizeResponseService } from './equipmentSizeResponse.service';
export * from './equipmentTypeResponse.service';
import { EquipmentTypeResponseService } from './equipmentTypeResponse.service';
export * from './scheduleAResponse.service';
import { ScheduleAResponseService } from './scheduleAResponse.service';
export * from './scheduleResponse.service';
import { ScheduleResponseService } from './scheduleResponse.service';
export * from './serviceDetails.service';
import { ServiceDetailsService } from './serviceDetails.service';
export * from './sitesResponse.service';
import { SitesResponseService } from './sitesResponse.service';
export * from './workOrderResponse.service';
import { WorkOrderResponseService } from './workOrderResponse.service';
export const APIS = [
  AccountAddressResponseService,
  AccountDetailsResponseService,
  CaseResponseService,
  CaseStatusResponseService,
  CaseTypesResponseService,
  EquipmentResponseService,
  EquipmentSizeResponseService,
  EquipmentTypeResponseService,
  ScheduleAResponseService,
  ScheduleResponseService,
  ServiceDetailsService,
  SitesResponseService,
  WorkOrderResponseService,
];
