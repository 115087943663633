import { ErrorHandler, Injectable } from '@angular/core';
import { environment as env } from '@env/environment';
import { TelemetryService } from '../app-insights/telemetry.service';

@Injectable({ providedIn: 'root' })
export class ErrorLoggerHandlerService extends ErrorHandler {
  constructor(private telemetryService: TelemetryService) {
    super();
  }

  handleError(error: Error) {
    if (env.production === 'false') {
      return;
    }
    this.telemetryService.logException(error); // Manually log exception
  }
}
