<mat-form-field class="date-picker" appearance="outline">
  <mat-date-range-input
    [formGroup]="dateRangeForm"
    [rangePicker]="picker"
    [max]="maxDate">
    <input
      matStartDate
      formControlName="startDate"
      [placeholder]="startPlaceholder" />
    <input
      matEndDate
      formControlName="endDate"
      [placeholder]="endPlaceholder" />
  </mat-date-range-input>
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-date-range-picker #picker></mat-date-range-picker>
</mat-form-field>
<mat-error *ngIf="!isDateRangeValid"
  >Date range cannot exceed 90 days.</mat-error
>
