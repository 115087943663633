<div class="dialog-container success-dialog">
  <div>
    <img class="modal-image" src="assets/images/task_alt.svg" alt="success" />
    <ng-container *ngIf="caseNumber">
      <div class="modal-title text-center">Successfully Submitted</div>
      <div class="modal-body text-center">
        Thanks for submitting your request! Please check your inbox for a
        confirmation email with your case number.
      </div>
      <div class="modal-case-number text-center">
        Case Number: {{ caseNumber }}
      </div>
    </ng-container>

    <ng-container *ngIf="demoRequest">
      <div class="modal-title text-center">
        Your Service Request Successfully Submitted
      </div>
      <div class="modal-body text-center">
        Our executive will connect with you soon.
      </div>
    </ng-container>
    <div class="text-center">
      <button id="close-button" (click)="reRouteOnClick()" mat-dialog-close>
        Okay
      </button>
    </div>
  </div>
</div>
