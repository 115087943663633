import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UserDataState } from '@app/store';

export const selectUserSlice = createFeatureSelector<UserDataState>('userData');

export const getUserInfo = createSelector(
  selectUserSlice,
  (state: UserDataState) => state
);

export const getUserAdObjectIdInfo = createSelector(
  selectUserSlice,
  (state: UserDataState) => state.adObjectId
);

export const getUserHasAcceptedLegalInfo = createSelector(
  selectUserSlice,
  (state: UserDataState) => state.hasAcceptedCustomerPrivacyAndTerms
);

export const getUserBillingAccounts = createSelector(
  selectUserSlice,
  (state: UserDataState) => state.billingAccounts
);
