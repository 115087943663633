import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { switchMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import {
  Account,
  AccountDetailsResponseService,
} from '@app/core/api-azure-functions/dataverse-functions';
import { CorporateActions } from '@app/store/actions';

@Injectable()
export class CorporateEffects {
  constructor(
    private actions$: Actions,
    private accountService: AccountDetailsResponseService
  ) {}

  getCorporateAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CorporateActions.getCorporateAccount),
      switchMap(({ userIds }) =>
        this.accountService
          .readAccountDetails(userIds.tenantId, userIds.accountId)
          .pipe(
            map((corporateAccount: Account[]) =>
              CorporateActions.getCorporateAccountSuccess({
                corporateAccount: corporateAccount[0],
              })
            ),
            catchError(err =>
              of(
                CorporateActions.getCorporateAccountFailure({
                  errorMsg: err.message,
                })
              )
            )
          )
      )
    )
  );
}
