import { AzureBaseService } from '@app/core/services/azure-functions/azure-base.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BillingAccountsReqObj } from '@app/core/models/UserDataModel';
import {
  ActiveSiteModel,
  SiteDetailsModel,
  SiteModel,
} from '@app/core/models/site.model';

@Injectable({ providedIn: 'root' })
export class DataverseSiteService extends AzureBaseService {
  constructor(protected http: HttpClient) {
    super(http, '/func-customerportal-dataverse-');
  }

  getSites(requestBody: BillingAccountsReqObj): Observable<SiteModel[]> {
    const requestUrl = `${this.azureBaseUrl}${this.functionUri}${this.azureEnv}/sites`;

    return this.http
      .post<HttpResponse<any>>(requestUrl, requestBody, this.httpOptions)
      .pipe(map(response => JSON.parse(response.body) as SiteModel[]));
  }

  getActiveSites(billingAccountIds: string[]): Observable<ActiveSiteModel[]> {
    const requestUrl = `${this.azureBaseUrl}${this.functionUri}${this.azureEnv}/activesites`;

    return this.http
      .post<HttpResponse<any>>(requestUrl, billingAccountIds, this.httpOptions)
      .pipe(map(response => JSON.parse(response.body) as ActiveSiteModel[]));
  }

  getSite(customerId, siteId): Observable<SiteDetailsModel[]> {
    const requestUrl = `${this.azureBaseUrl}${this.functionUri}${this.azureEnv}/sites/customerid/${customerId}/accountid/${siteId}`;

    return this.http
      .get<HttpResponse<any>>(requestUrl, this.httpOptions)
      .pipe(map(response => JSON.parse(response.body) as SiteDetailsModel[]));
  }
}
