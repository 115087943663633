import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CorporateState } from '@app/store';

export const selectCorporateAccountSlice =
  createFeatureSelector<CorporateState>('corporate');

export const getCorporateAccount = createSelector(
  selectCorporateAccountSlice,
  (state: CorporateState) => state.corporateAccount
);

export const getCorporateAccountNumber = createSelector(
  selectCorporateAccountSlice,
  (account: CorporateState) => account.corporateAccount.accountNumber
);
