import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CaseState, statusCodeMap } from '@app/store';
import { OptionModel } from '@app/core/models/option.model';
import { CaseModel } from '@app/core/models/case.model';

export const selectCaseSlice = createFeatureSelector<CaseState>('case');

export const getAllCases = createSelector(
  selectCaseSlice,
  (state: CaseState) => state.allCases
);

export const getSupportCaseTypes = createSelector(
  selectCaseSlice,
  (state: CaseState) =>
    state.caseTypes.filter((caseType: OptionModel) =>
      [
        '118100006',
        '118100002',
        '118100007',
        '118100000',
        '3',
        '100000001',
        '118100014',
        '118100016',
      ].includes(caseType.id)
    )
);

export const getCaseDetailsMap = createSelector(
  selectCaseSlice,
  (state: CaseState) => state.caseDetailsMap
);

export const getFormattedCases = createSelector(
  getSupportCaseTypes,
  getAllCases,
  getCaseDetailsMap,
  (caseTypes, allCases, caseDetailsMap) => {
    if (allCases && caseTypes) {
      const caseTypeFilter = caseTypes.map(caseType => caseType.id);

      // filter cases by case type and filter out Corrigo Pro with Fiannce
      allCases = allCases.filter(
        (caseModel: CaseModel) =>
          (caseModel.caseOriginCode.toString() !== '4' ||
            caseModel.caseTypeCode.toString() !== '118100000') &&
          caseTypeFilter.includes(caseModel.caseTypeCode.toString())
      );

      const formattedCases: CaseModel[] = [];

      // add string values for each case
      for (let i = 0; i < allCases.length; i += 1) {
        formattedCases.push({
          ...allCases[i],
          stateCodeString: allCases[i].stateCode === 0 ? 'Open' : 'Resolved',
          statusCodeString: statusCodeMap[allCases[i].statusCode],
          caseTypeCodeString: caseTypes.find(
            caseType => caseType.id === allCases[i].caseTypeCode.toString()
          ).name,
          details: caseDetailsMap[allCases[i].caseNumber],
        });
      }

      return formattedCases;
    }
  }
);

export const getOrderedSupportCaseTypes = createSelector(
  selectCaseSlice,
  (state: CaseState) => {
    if (state.caseTypes.length > 0) {
      const theOrder = [
        '118100006', // Missed Service
        '118100002', // Damaged/Replace Equipment
        '118100007', // Incidents/Accidents
        '118100000', // Finance
        '118100014', // Sustainability
        '118100016', // Reporting
      ];
      const orderedCaseTypes: OptionModel[] = [];

      for (let i = 0; i < theOrder.length; i += 1) {
        const caseType = state.caseTypes.find(
          caseType => caseType.id === theOrder[i]
        );

        orderedCaseTypes.push(caseType);
      }

      return orderedCaseTypes;
    }
  }
);

export const getSupportCaseStatuses = createSelector(
  selectCaseSlice,
  (state: CaseState) => state.caseStatuses
);

export const getCreatedCaseNumber = createSelector(
  selectCaseSlice,
  (state: CaseState) => state.createdCaseNumber
);

export const getCaseCreationError = createSelector(
  selectCaseSlice,
  (state: CaseState) => state.caseCreationError
);

export const getCreatedCaseFollowUpNumber = createSelector(
  selectCaseSlice,
  (state: CaseState) => state.createdCaseFollowUp
);

export const getCaseFollowUpError = createSelector(
  selectCaseSlice,
  (state: CaseState) => state.caseCreationError
);

export const getCasesTypeSummary = createSelector(
  selectCaseSlice,
  (state: CaseState) => state.caseTypeSummary
);

export const isCasesLoading = createSelector(
  selectCaseSlice,
  (state: CaseState) => state.casesLoading
);

export const isCaseStatusLoading = createSelector(
  selectCaseSlice,
  (state: CaseState) => state.caseStatusLoading
);
