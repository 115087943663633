import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { ErrorStateMatcher } from '@angular/material/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Subscription } from 'rxjs';

import { REGEX_PATTERN } from '@app/shared/app.constants';

@Component({
  selector: 'app-multiple-email-input',
  templateUrl: './multiple-email-input.html',
  styleUrls: ['./multiple-email-input.scss'],
})
export class MultipleEmailInputComponent implements OnInit, OnDestroy {
  separatorKeysCodes: number[] = [ENTER, COMMA];
  emails: string[] = [];
  filteredEmails = [];
  @Input() parentForm: FormGroup;
  @Input() controlName: string;
  @Input() label: string = 'Additional Emails';
  @Input() placeholder: string = 'Additional Emails';
  @Input() maxLength: number = 255;
  @Input() errorStateMatcher: ErrorStateMatcher = null;
  @Input() showErrors: boolean = true;
  @Input() appearance: string = 'outline';

  subscriptions: Subscription = new Subscription();

  emailControl = new FormControl('', [Validators.required]);

  @ViewChild('emailInput') emailInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  ngOnInit() {
    const formSubscription = this.emailControl.valueChanges.subscribe(value => {
      this.multipleEmailControl.markAsDirty();
      this.multipleEmailControl.markAsTouched();
      this.validate(value);
    });

    this.subscriptions.add(formSubscription);
  }

  get multipleEmailControl() {
    return this.parentForm.controls[this.controlName];
  }

  get totalEmailsLength(): number {
    return this.multipleEmailControl.value.length;
  }

  add(event: MatChipInputEvent): void {
    const { input } = event;
    const value = (event.value || '').trim();

    if (this.validate(value)) {
      this.addEmail(value);
      if (input) {
        input.value = '';
      }
    }
  }

  private validate(value): boolean {
    this.multipleEmailControl.setErrors(null);

    if (value.length) {
      if (this.isValidLength(value)) {
        if (RegExp(REGEX_PATTERN.EMAIL).test(value)) {
          if (this.isUniqueEmail(value)) {
            return true;
          }
          this.multipleEmailControl.setErrors({ duplicateEmailId: true });
          return false;
        }
        this.multipleEmailControl.setErrors({ emailPattern: true });
        return false;
      }
      this.multipleEmailControl.setErrors({ maxLength: true });
      return false;
    }

    return true;
  }

  remove(email: string): void {
    const index = this.emails.indexOf(email);

    if (index >= 0) {
      this.emails.splice(index, 1);
      this.multipleEmailControl.setValue(this.emails.join(';'));
    }
  }

  private isUniqueEmail(email: string): boolean {
    return this.emails.indexOf(email) === -1;
  }

  private isValidLength(email: string): boolean {
    return this.totalEmailsLength + email.length <= this.maxLength;
  }

  private addEmail(email: string): void {
    if (this.emails.indexOf(email) === -1) {
      this.emails.push(email);
      this.multipleEmailControl.setValue(this.emails.join(';'));
    }
  }

  get errorMessage(): string | null {
    let errorMessage = `Type and press enter or comma to add ${this.label}`;

    if (this.multipleEmailControl.hasError('maxLength')) {
      errorMessage = `You have reached the max limit of ${this.maxLength} characters`;
    } else if (this.multipleEmailControl.hasError('emailPattern')) {
      errorMessage = 'Please enter a valid Email Address';
    } else if (this.multipleEmailControl.hasError('duplicateEmailId')) {
      errorMessage = 'Entered Email-id already exists';
    }
    return errorMessage;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
