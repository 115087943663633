import { Component, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { REGEX_PATTERN } from '@app/shared/app.constants';
import { AnalyticsActions } from '@app/store/actions';
import { AppState } from '@app/store/app.state';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-book-ademo-dialog',
  templateUrl: './book-ademo-dialog.component.html',
  styleUrls: ['./book-ademo-dialog.component.scss'],
})
export class BookADemoDialogComponent {
  demoRequestDisabled = false;

  demoRequestForm = this.fb.group({
    email: [
      null,
      [Validators.required, Validators.pattern(REGEX_PATTERN.EMAIL)],
    ],
  });
  constructor(
    private store: Store<AppState>,
    public dialogRef: MatDialogRef<BookADemoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { email: string },
    private fb: FormBuilder
  ) {}

  onDemoRequest(): void {
    if (this.demoRequestForm.valid) {
      this.store.dispatch(
        AnalyticsActions.createAnalyticDemoRequest({
          email: this.demoRequestForm.controls.email.value,
        })
      );
    }
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }
}
