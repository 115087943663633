import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { Injectable } from '@angular/core';
import { environment as env } from '@env/environment';

@Injectable({ providedIn: 'root' })
export class TelemetryService {
  appInsights: ApplicationInsights;

  constructor() {
    // only init appInsights when in production
    if (env.production === 'false') {
      return;
    }

    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: env.azureAppInsightsSubscriptionKey,
        enableAutoRouteTracking: true, // option to log all route changes
      },
    });

    const telemetryInitializer = envelope => {
      envelope.tags['ai.cloud.role'] = 'Finch';
      envelope.tags['ai.cloud.roleInstance'] = 'Finch role instance';
    };

    this.appInsights.loadAppInsights();
    this.appInsights.addTelemetryInitializer(telemetryInitializer);
  }

  logPageView(name?: string, url?: string) {
    // option to call manually
    this.appInsights.trackPageView({
      name: name,
      uri: url,
    });
  }

  logEvent(name: string, properties?: { [key: string]: any }) {
    this.appInsights.trackEvent({ name: name }, properties);
  }

  logMetric(
    name: string,
    average: number,
    properties?: { [key: string]: any }
  ) {
    this.appInsights.trackMetric({ name: name, average: average }, properties);
  }

  logException(exception: Error, severityLevel?: number) {
    this.appInsights.trackException({
      exception: exception,
      severityLevel: severityLevel,
    });
  }

  logTrace(message: string, properties?: { [key: string]: any }) {
    this.appInsights.trackTrace({ message: message }, properties);
  }
}
