import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';

export const onSideNavChange = trigger('onSideNavChange', [
  state('close', style({ 'min-width': '4.5rem' })),
  state('open', style({ 'min-width': '14rem' })),
  transition(
    'close => open',
    animate('.3s cubic-bezier(.21, -.07, .18, 1.32)')
  ),
  transition(
    'open => close',
    animate('.3s cubic-bezier(.21, -.07, .18, 1.32)')
  ),
]);

export const onSideNavChangeMainContent = trigger(
  'onSideNavChangeMainContent',
  [
    state('close', style({ 'margin-left': '4.5rem' })),
    state('open', style({ 'margin-left': '14rem' })),
    transition(
      'close => open',
      animate('.3s cubic-bezier(.21, -.07, .18, 1.32)')
    ),
    transition(
      'open => close',
      animate('.3s cubic-bezier(.21, -.07, .18, 1.32)')
    ),
  ]
);

export const animateText = trigger('animateText', [
  state('hide', style({ opacity: 0 })),
  state('show', style({ opacity: 1 })),
  transition('hide => show', animate('50ms linear')),
  transition('show => hide', animate('50ms linear')),
]);

export const animateError = trigger('animateError', [
  state('show', style({ transform: 'translateY(0)' })),
  transition('void => *', [
    style({ transform: 'translateY(-100%)' }),
    animate(140),
  ]),
  transition('* => void', [
    animate(140, style({ transform: 'translateY(-100%)' })),
  ]),
]);

export const animateCollapsible = trigger('animateCollapsible', [
  state('hide', style({ height: '0rem' })),
  state('show', style({ height: '12rem' })),
  transition('hide => show', animate('100ms linear')),
  transition('show => hide', animate('100ms linear')),
]);

export const animateTableCollapsible = trigger('detailExpand', [
  state('collapsed', style({ height: '0px', minHeight: '0' })),
  state('expanded', style({ height: '*' })),
  transition(
    'expanded <=> collapsed',
    animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
  ),
]);
