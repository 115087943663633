import {
  LogLevel,
  Configuration,
  BrowserCacheLocation,
} from '@azure/msal-browser';

import { environment as env } from '@env/environment';

const tenant = env.adB2CTenant;
const acceptInvite = 'B2C_1A_INV_REDEEM';
const forgotPassword = 'B2C_1_reset_v3';
const editProfile = 'B2C_1_edit_profile_v2';
const signUpSignIn = 'B2C_1A_SIGNUP_SIGNIN';

const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;

const stripTrailingSlash = (str: string) =>
  str.endsWith('/') ? str.slice(0, -1) : str;

export const b2cPolicies = {
  names: {
    acceptInvite: acceptInvite,
    forgotPassword: forgotPassword,
    editProfile: editProfile,
    signUpSignIn: signUpSignIn,
  },
  authorities: {
    acceptInvite: {
      authority: `https://${tenant}.b2clogin.com/${tenant}.onmicrosoft.com/${acceptInvite}`,
    },
    forgotPassword: {
      authority: `https://${tenant}.b2clogin.com/${tenant}.onmicrosoft.com/${forgotPassword}`,
    },
    editProfile: {
      authority: `https://${tenant}.b2clogin.com/${tenant}.onmicrosoft.com/${editProfile}`,
    },
    signUpSignIn: {
      authority: `https://${tenant}.b2clogin.com/${tenant}.onmicrosoft.com/${signUpSignIn}`,
    },
  },
  authorityDomain: `${tenant}.b2clogin.com`,
};

export const msalConfig: Configuration = {
  auth: {
    clientId: env.adB2CClientId,
    authority: b2cPolicies.authorities.signUpSignIn.authority,
    knownAuthorities: [b2cPolicies.authorityDomain],
    redirectUri: '/dashboard',
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: BrowserCacheLocation.SessionStorage,
    storeAuthStateInCookie: isIE,
  },
  system: {
    loggerOptions: {
      // loggerCallback: (logLevel, message, containsPii) => {
      //   // console.log(message);
      // },
      logLevel: LogLevel.Verbose,
      piiLoggingEnabled: false,
    },
  },
};

export const protectedResources = {
  tokenAuthApim: {
    baseUrl: stripTrailingSlash(env.azureCustomerPortalAPIMGatewayBaseUrl),
    scopes: [`https://${tenant}.onmicrosoft.com/usermanagement/admin`],
  },
};
export const loginRequest = { scopes: [] };
