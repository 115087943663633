import {
  Component,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatAccordion } from '@angular/material/expansion';
import { MatMenuTrigger } from '@angular/material/menu';
import { BillingAccount } from '@app/core/models/UserDataModel';
import { GlobalActions, GlobalSelectors, UserDataSelectors } from '@app/store';
import { AppState } from '@app/store/app.state';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-global-billing-filter',
  templateUrl: './global-billing-filter.component.html',
  styleUrls: ['./global-billing-filter.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class GlobalBillingFilterComponent implements OnInit, OnDestroy {
  @ViewChild('matMenuTrigger', { read: MatMenuTrigger })
  private matButtonRef: MatButton;
  @ViewChildren('billingAccounts')
  private menuItemsRef: QueryList<MatCheckbox>;

  subscriptions: Subscription = new Subscription();
  billingAccounts$: Observable<BillingAccount[]>;

  billingAccounts: BillingAccount[] = null;
  @ViewChild(MatAccordion) accordion: MatAccordion;

  selectedBillingAccounts: BillingAccount[] = [];
  selectedBAWithFullPortfolio: number = 0;
  selectedBillingAccountNames: string[] = ['Select Billing account'];
  getSelectedSites = [];

  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    this.billingAccounts$ = this.store.select(GlobalSelectors.getGlobalFilters);

    this.subscriptions.add(
      this.store
        .select(UserDataSelectors.getUserBillingAccounts)
        .subscribe((billingAccounts: BillingAccount[] | undefined) => {
          if (billingAccounts !== null && billingAccounts !== undefined) {
            const ba = billingAccounts.map(ba => {
              return {
                ...ba,
                selected: true,
                sites: ba.sites.map(site => {
                  return {
                    ...site,
                    selected: true,
                  };
                }),
              };
            });

            this.store.dispatch(
              GlobalActions.setGlobalFilters({ billingAccounts: ba })
            );
          }
        })
    );

    this.subscriptions.add(
      this.store
        .select(GlobalSelectors.getGlobalFilters)
        .subscribe(billingAccounts => {
          this.billingAccounts = billingAccounts;
        })
    );

    this.billingAccounts$.subscribe({
      next: value => {
        const selectedBillingAccounts = value.filter(ba => ba.selected);

        this.selectedBillingAccounts = selectedBillingAccounts;

        // Extract names of selected billing accounts
        this.selectedBillingAccountNames = selectedBillingAccounts.map(
          ba => ba.name
        );

        // Calculate selectedBAWithFullPortfolio
        let selectedBAWithFullPortfolio = 0;
        const getSelectedSites = [];

        selectedBillingAccounts.forEach(ba => {
          if (ba.sites.length === 0 && ba.totalSitesCount > 0) {
            selectedBAWithFullPortfolio += ba.totalSitesCount;
          }

          getSelectedSites.push(...ba.sites.filter(site => site.selected));
        });

        this.selectedBAWithFullPortfolio = selectedBAWithFullPortfolio;
        this.getSelectedSites = getSelectedSites;
      },
    });
  }

  public onMenuOpened() {
    this.setFocusOnFirstItem();
  }

  public onMenuClosed() {
    this.matButtonRef.focus();
    this.accordion.closeAll();
  }

  private setFocusOnFirstItem(): void {
    const firstCheckbox = this.menuItemsRef.first;

    firstCheckbox.focus();
  }

  isBillingAccChecked(billingAccounts: BillingAccount): boolean {
    return billingAccounts.sites.length
      ? billingAccounts.sites.every(s => s.selected)
      : billingAccounts.selected;
  }

  isBillingAccountIndeterminate(billingAccount: BillingAccount): boolean {
    if (!billingAccount.sites.length) {
      return false;
    }

    const selectedChildren = billingAccount.sites.filter(site => site.selected);

    return (
      selectedChildren.length > 0 &&
      selectedChildren.length < billingAccount.sites.length
    );
  }

  toggleBillingAccountSelection(
    billingAccount: BillingAccount,
    site: BillingAccount = null
  ) {
    this.store.dispatch(
      GlobalActions.toggleBillingAccountSelection({ billingAccount, site })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
