<ng-container *ngIf="
  isCurrentWorkOrderLoading$ | async;
    else content
  ">
  <mat-spinner class="loading-spinner" strokeWidth="6" diameter="75"></mat-spinner>
</ng-container>

<ng-template #content>
  <div *ngIf="workOrder$ | async as workOrder">
    <h2 mat-dialog-title> {{workOrder.serviceAccountName}}</h2>
    <mat-dialog-content class="row pd0">
      <div class="col-lg-12 col-md-12 col-sm-12 pd0">
        <div class="workorder-info">
          <div class="row">
            <div class="col-lg-2 col-md-6 col-sm-12">
              <div class="workorder-title">Work Order Number</div>
              <div class="workorder-data-top">{{ workOrder.workOrderNumber }}</div>
            </div>

            <div class="col-lg-2 col-md-6 col-sm-12">
              <div class="workorder-title">Service Account</div>
              <div class="workorder-data-top">{{ workOrder.serviceAccountName }}</div>
            </div>

            <div class="col-lg-2 col-md-6 col-sm-12">
              <div class="workorder-title">Request Type</div>
              <div class="workorder-data-top">{{ workOrder.requestType }}</div>
            </div>

            <div class="col-lg-4 col-md-12 col-sm-12">
              <div class="workorder-title">Equipment</div>
              <div class="workorder-data-top">{{ workOrder.equipmentName }}</div>
            </div>

            <div class="col-lg-2 col-md-6 col-sm-12">
              <div class="workorder-title">System Status</div>
              <div class="workorder-data-top">
                <mat-chip disableRipple ngClass={{workOrder.systemStatus}}>
                  {{ workOrder.systemStatus }}
                </mat-chip>
              </div>
            </div>
          </div>
          <!-- <div class="row workorder-title">
            <div class="col-2">Work Order Number</div>
            <div class="col-2">Service Account</div>
            <div class="col-2">Request Type</div>
            <div class="col-4">Equipment</div>
            <div class="col-2">System Status</div>
          </div> -->
          <!-- <ng-container>
            <div class="row workorder-data-top">
              <div class="col-2">
                <div>{{ workOrder.workOrderNumber }}</div>
              </div>
              <div class="col-2">
                <div>{{ workOrder.serviceAccountName }}</div>
              </div>
              <div class="col-2">
                <div>{{ workOrder.requestType }}</div>
              </div>
              <div class="col-4">
                <div>{{ workOrder.equipmentName }}</div>
              </div>
              <div class="col-2">
                <mat-chip disableRipple ngClass={{workOrder.systemStatus}}>
                  {{ workOrder.systemStatus }}
                </mat-chip>
              </div>
            </div>
          </ng-container> -->
        </div>
      </div>


      <div class="col-lg-6 col-md-12 col-sm-12 pd0">
        <div class="workorder-info">
          <div class="row">
            <div class="col-lg-5 col-md-12 col-sm-12">
              <div class="workorder-header-title brdbottom">Start Time</div>
              <div class="workorder-data">{{ workOrder.requestDate | date : "MM/dd/yyyy h:mm a" }}</div>
            </div>

            <div class="col-lg-5 col-md-12 col-sm-12">
              <div class="workorder-header-title brdbottom">End Time</div>
              <div class="workorder-data">{{ workOrder.confirmedServiceDate | date : "MM/dd/yyyy h:mm a" }}</div>
            </div>

            <div class="col-lg-2 col-md-12 col-sm-12">
              <div class="workorder-header-title brdbottom">Duration</div>
              <div class="workorder-data">{{ workOrder.totalEstimatedDuration }}</div>
            </div>
          </div>
        </div>
      </div>

      <div class=" col-lg-6 col-md-12 col-sm-12 pd0">
        <div class="workorder-info">
          <div class="row workorder-header-title brdbottom">
            <div class="col-lg-5 col-md-12 col-sm-12">Product</div>
            <div class="col-lg-1 col-md-12 col-sm-12">Quality</div>
            <div class="col-lg-6 col-md-12 col-sm-12 text-right">Estimate Unit Price to Customer</div>
          </div>
          <ng-container *ngFor="let product of workOrder.products">
            <div class="row workorder-data">
              <div class="col-lg-5 col-md-12 col-sm-12">
                <div>{{ product.productName }}</div>
              </div>
              <div class="col-lg-1 col-md-12 col-sm-12">
                <div>{{ product.quantity }}</div>
              </div>
              <div class="col-lg-6 col-md-12 col-sm-12 text-right">
                <div>{{ product.estimateUnitPrice }}</div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
      <button class="rts-filled-button" alt="close" mat-dialog-close>Okay</button>
    </mat-dialog-actions>
  </div>
</ng-template>