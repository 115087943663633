import { createAction, props } from '@ngrx/store';
import { DocumentDetailsModel } from '@app/core/models/document-details.model';
import { BillingAccountsReqObj } from '@app/core/models/UserDataModel';

export const getDocuments = createAction(
  '[Documents] Get Documents',
  props<{ requestBody: BillingAccountsReqObj }>()
);

export const getDocumentsSuccess = createAction(
  '[Documents] Get Documents Success',
  props<{ documents: DocumentDetailsModel[] }>()
);

export const getDocumentsFailure = createAction(
  '[Documents] Get Documents Failure',
  props<{ errorMsg: string }>()
);
