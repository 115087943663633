import { ServiceActions } from '@app/store/actions';
import { createReducer, on } from '@ngrx/store';
import { ServiceDetailResponse } from '@app/core/api';

export const servicesFeatureKey = 'services';

export interface ServicesState {
  allServices: ServiceDetailResponse[] | null;
}

const initialState: ServicesState = { allServices: null };

export const servicesReducer = createReducer(
  initialState,
  on(ServiceActions.getServicesSuccess, (state, { allServices }) => ({
    ...state,
    allServices,
  }))
);
