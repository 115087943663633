import { AzureBaseService } from '@app/core/services/azure-functions/azure-base.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DocumentDetailsModel } from '@app/core/models/document-details.model';
import { BillingAccountsReqObj } from '@app/core/models/UserDataModel';

@Injectable({ providedIn: 'root' })
export class SharepointDocumentsService extends AzureBaseService {
  constructor(protected http: HttpClient) {
    super(http, '/func-portal-api-');
  }

  getDocumentDetails(
    requestBody: BillingAccountsReqObj
  ): Observable<DocumentDetailsModel[]> {
    const requestUrl = `${this.azureBaseUrl}${this.functionUri}${this.azureEnv}/documents`;

    return this.http
      .post<HttpResponse<any>>(requestUrl, requestBody, this.httpOptions)
      .pipe(
        map(response => JSON.parse(response.body) as DocumentDetailsModel[])
      );
  }

  downloadDocument(documentId: string): Observable<DocumentDetailsModel> {
    const requestUrl = `${this.azureBaseUrl}${this.functionUri}${this.azureEnv}/document/${documentId}/download`;

    return this.http
      .get<HttpResponse<any>>(requestUrl, this.httpOptions)
      .pipe(map(response => JSON.parse(response.body) as DocumentDetailsModel));
  }
  downloadSiteAgreementDocument(
    documentId: string
  ): Observable<DocumentDetailsModel> {
    const requestUrl = `${this.azureBaseUrl}${this.functionUri}${this.azureEnv}/siteagreementdocument/${documentId}/download`;

    return this.http
      .get<HttpResponse<any>>(requestUrl, this.httpOptions)
      .pipe(map(response => JSON.parse(response.body) as DocumentDetailsModel));
  }
}
