import { createAction, props } from '@ngrx/store';
import { UserIds } from '@app/core/models';
import { AccountInfo } from '@azure/msal-browser';

export const checkAuth = createAction('[Auth] Check User Authentication');

export const checkAuthFailure = createAction(
  '[Auth] Check User Authentication Failure',
  props<{ errorMsg: string }>()
);

// get all auth related things - the user info AND tenant/account id
export const getAllAuths = createAction(
  '[Auth] Get All Auths - User Info/AccountId/TenantId'
);

// set user info from auth
export const getAuthUserSuccess = createAction(
  '[Auth] Get Auth User Success',
  props<{ user: AccountInfo }>()
);

export const getAuthUserFailure = createAction(
  '[Auth] Get Auth User Failure',
  props<{ errorMsg: string }>()
);

// set tenant/account ids from auth
export const setAuthUserIds = createAction(
  '[Auth] Set Auth Tenant/Account ID',
  props<{ userIds: UserIds }>()
);

// set userPermissions from Auth
export const setAuthUserPermissions = createAction(
  '[Auth] Set Auth User Permissions',
  props<{ userPermissions: string[] }>()
);

// set auth access token
export const setAuthAccessToken = createAction(
  '[Auth] Set Auth Access Token',
  props<{ accessToken: string }>()
);

export const getAndDecodeAccessTokenFailure = createAction(
  '[Auth] Get and decode Ad Access Token Failure',
  props<{ errorMsg: string }>()
);
