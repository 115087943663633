<h1 mat-dialog-title class="rts-header-3">Request For Book A Demo</h1>
<div mat-dialog-content class="border-top border-bottom pt-3">
  <p class="mb-5 rts-body-1">
    Please enter your email address and submit,then our executive will connect
    with you soon.
  </p>
  <form name="demoRequestForm" [formGroup]="demoRequestForm">
    <mat-form-field appearance="outline" [style.width.%]="100">
      <mat-label>Email Address</mat-label>
      <input matInput type="email" formControlName="email" required />
    </mat-form-field>
  </form>
</div>
<div mat-dialog-actions align="end">
  <button mat-button (click)="onCancelClick()">Cancel</button>
  <button
    mat-raised-button
    color="accent"
    [disabled]="!demoRequestForm.valid"
    [mat-dialog-close]="data.email"
    (click)="onDemoRequest()"
    cdkFocusInitial>
    Submit
  </button>
</div>
