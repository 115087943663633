import { Injectable } from '@angular/core';
import { PhoneVerificationResponse } from '@app/core/models/twilio.model';
import { TwilioService } from '@app/core/services/azure-functions/twilio.service';
import { TwilioActions } from '@app/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

@Injectable()
export class TwilioEffects {
  constructor(
    private actions$: Actions,
    private TwilioService: TwilioService
  ) {}

  verifyPhoneNumber$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TwilioActions.verifyPhoneNumber),
      switchMap(({ requestObj }) =>
        this.TwilioService.verifyPhoneNumber(requestObj).pipe(
          map((response: PhoneVerificationResponse) =>
            TwilioActions.verifyPhoneNumberSuccess({ response })
          ),
          catchError(err =>
            of(TwilioActions.verifyPhoneNumberFailure({ errorMsg: err }))
          )
        )
      )
    )
  );

  VerifyOTP$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TwilioActions.verifyOTP),
      switchMap(({ requestObj }) =>
        this.TwilioService.verifyOTP(requestObj).pipe(
          map((response: PhoneVerificationResponse) =>
            TwilioActions.verifyOTPSuccess({ response })
          ),
          catchError(err =>
            of(TwilioActions.verifyOTPFailure({ errorMsg: err }))
          )
        )
      )
    )
  );
}
