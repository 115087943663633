import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AuthActions, AuthSelectors, RouterSelectors } from '@app/store';
import { AppState } from '@app/store/app.state';
import { Subscription } from 'rxjs';
import { SupportPublicService } from '@app/core/api/api/support-public.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-support-public',
  templateUrl: './support-public.component.html',
  styleUrls: ['./support-public.component.scss'],
})
export class SupportPublicComponent implements OnInit, OnDestroy {
  showBackButton: boolean;
  hideSupportButtons: boolean;
  subNavTitle = '';
  backButtonUrl: string;
  heroImageCol: number;
  styleClass: string;
  serviceSubscription: Subscription;
  selectedCase: any;
  languageAndCountry: null;

  constructor(
    private store: Store<AppState>,
    private supportPublicService: SupportPublicService,
    private router: Router
  ) {
    this.supportPublicService.selectedCaseObservable.subscribe(selectedCase => {
      this.selectedCase = selectedCase;
    });
    this.serviceSubscription = new Subscription();
  }

  ngOnInit() {
    this.getRouterState();

    const subscription = this.store
      .select(AuthSelectors.getUser)
      .subscribe(b2cAccountInfo => {
        if (b2cAccountInfo) {
          this.router.navigate(['support-signed-in']);
        }
      });

    this.serviceSubscription.add(subscription);

    this.store.dispatch(AuthActions.getAllAuths());
  }

  ngOnDestroy() {
    this.serviceSubscription.unsubscribe();
  }

  getRouterState() {
    this.serviceSubscription.add(
      this.store
        .select(RouterSelectors.getRouterState)
        .subscribe((state: any) => {
          const data = state?.data;

          this.showBackButton = data?.showBackButton;
          this.hideSupportButtons = data?.hideSupportButtons;
          // we use the "title" to manage the active state on the navigation
          // AND show a title for the page in the sub nav
          // alternateTitle is just to change the display name
          this.subNavTitle =
            this.selectedCase?.displayName &&
            data?.styleClass === 'createTicket'
              ? this.selectedCase?.displayName
              : data?.title;
          this.backButtonUrl = data?.backButtonUrl.includes(
            ':languageAndCountry'
          )
            ? data?.backButtonUrl.replace(
                ':languageAndCountry',
                state.params.languageAndCountry
              )
            : data?.backButtonUrl;
          this.heroImageCol = data?.heroImageCol ? data?.heroImageCol : null;
          this.styleClass = `px-0 full-width ${data?.styleClass}`;
          if (!this.selectedCase) {
            this.selectedCase = { id: state.params.caseTypeId };
          }
        })
    );
  }
}
