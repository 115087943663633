import { AfterViewInit, Component, Input, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';

import { PageLink } from '@app/core/models/page-link';

@Component({
  selector: 'app-top-sub-nav',
  templateUrl: './top-sub-nav.component.html',
  styleUrls: ['./top-sub-nav.component.scss'],
})
export class TopSubNavComponent implements AfterViewInit {
  @Input() subNavItems: PageLink[] = [];
  @Input() showBackButton = false;
  @Input() title = '';
  @Input() backButtonUrl = '';
  @Input() backButtonQueryParams = '';
  @Input() hideSupportButtons = false;
  @Input() styleClass = null;
  @ViewChildren('subNavItem') createdNavItems;
  totalNavItems: number; // add class depending on rendered items in nav

  constructor(private router: Router) { }

  ngAfterViewInit(): void {
    this.countNavItems();
  }

  countNavItems(): void {
    this.totalNavItems = this.createdNavItems.toArray().length;
  }

  rerouteOnClick(url) {
    this.router.navigate([url]);
  }
}
