import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '@app/store/app.state';
import { UserDataActions, UserDataSelectors } from '@app/store';
import { UserDataModel } from '@app/core/models/UserDataModel';

@Component({
  selector: 'app-legal-document-modal',
  templateUrl: './legal-document-modal.component.html',
  styleUrls: ['./legal-document-modal.component.scss'],
})
export class LegalDocumentModalComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription();
  user$ = this.store.select(UserDataSelectors.getUserInfo);
  user: UserDataModel;

  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.user$.subscribe(user => {
        this.user = user;
      })
    );
  }

  closeDialog(): void {
    this.store.dispatch(
      UserDataActions.userUpdateById({
        user: {
          ...this.user,
          hasAcceptedCustomerPrivacyAndTerms: true,
        },
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
