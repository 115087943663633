import { Injectable } from '@angular/core';
import { catchError, filter } from 'rxjs/operators';
import { EMPTY, Observable } from 'rxjs';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Router, NavigationStart } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '@app/store/app.state';
import { ErrorHandlerService } from '@app/core/services/handlers/error-handler.service';

@Injectable({ providedIn: 'root' })
export class LoaderErrorInterceptorService implements HttpInterceptor {
  constructor(
    public store: Store<AppState>,
    public router: Router,
    private loaderErrorService: ErrorHandlerService
  ) {
    // watch for when the page changes and clear the error message.
    router.events
      .pipe(filter((evt: any) => evt instanceof NavigationStart))
      .subscribe(() => {
        this.hideLoadError();
      });
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 422) {
          // Pass this error on to be handled since it's a problem with the request data.
          return next.handle(req);
        }
        this.onError(error);
        return EMPTY;
      })
    );
  }

  private onError(error) {
    let errorMsg = 'Network error. Please try again later.';

    if (error.status === 401) {
      errorMsg = 'Unauthorized Access';
    }

    this.showLoadError(errorMsg);
  }

  private showLoadError(errorMsg) {
    this.loaderErrorService.show(errorMsg);
  }

  private hideLoadError() {
    this.loaderErrorService.hide();
  }
}
