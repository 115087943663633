import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ContentfulModel } from '@app/core/models/contentful.model';
import { ContentfulSelectors } from '@app/store';
import { Store } from '@ngrx/store';
import { AppState } from '@app/store/app.state';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-legal-document',
  templateUrl: 'legal-document.component.html',
  styleUrls: ['legal-document.component.scss'],
})
export class LegalDocumentComponent implements OnInit {
  @Input() type: string = '';
  content$: Observable<ContentfulModel>;

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private route: ActivatedRoute,
    public datePipe: DatePipe
  ) {}

  ngOnInit() {
    this.route.data.subscribe(data => {
      if (data.type === 'terms' || this.type === 'terms') {
        this.content$ = this.store.select(
          ContentfulSelectors.getTermsAndConditions
        );
      } else if (data.type === 'privacy' || this.type === 'privacy') {
        this.content$ = this.store.select(ContentfulSelectors.getPrivacyPolicy);
      }
    });
  }

  redirectToHome() {
    this.router.navigate(['/']);
  }
}
