import { createAction, props } from '@ngrx/store';
import { OptionModel } from '@app/core/models/option.model';
import {
  CaseFollowUpModel,
  CaseModel,
  CasePublicModel,
  CaseDetailsModel,
  CaseTypeSummary,
} from '@app/core/models/case.model';
import {
  BillingAccountsReqObj,
  BillingAccountsDatesReqObj,
} from '@app/core/models/UserDataModel';

export const getCases = createAction(
  '[Case] Get Cases',
  props<{ requestBody: BillingAccountsDatesReqObj }>()
);

export const getCasesSuccess = createAction(
  '[Case] Get Cases Success',
  props<{ allCases: CaseModel[] }>()
);

export const getCasesFailure = createAction(
  '[Case] Get Cases Failure',
  props<{ errorMsg: string }>()
);

export const casesStartLoading = createAction('[Case] Start Cases Loading');

export const getCase = createAction('[Case] Get Case');

export const getCaseTypes = createAction('[Case] Get Case Types');

export const getCaseTypesSuccess = createAction(
  '[Case] Get Case Types Success',
  props<{ caseTypes: OptionModel[] }>()
);

export const getCaseTypesFailure = createAction(
  '[Case] Get Case Types Failure',
  props<{ errorMsg: string }>()
);

export const getCaseStatuses = createAction('[Case] Get Case Statuses');

export const getCaseStatusesSuccess = createAction(
  '[Case] Get Case Statuses Success',
  props<{ caseStatuses: OptionModel[] }>()
);

export const getCaseStatusesFailure = createAction(
  '[Case] Get Case Statuses Failure',
  props<{ errorMsg: string }>()
);

export const createCase = createAction(
  '[Case] Create Case',
  props<{ newCase: CaseModel }>()
);

export const createCasePublic = createAction(
  '[Case] Create Case Public',
  props<{ newCase: CasePublicModel }>()
);

export const createCaseSuccess = createAction(
  '[Case] Create Case Success',
  props<{ createdCaseNumber: string }>()
);

export const createCaseFailure = createAction(
  '[Case] Create Case Failure',
  props<{ errorMsg: string }>()
);

export const clearCreatedCaseNumber = createAction(
  '[Case] Clear createdCaseNumber'
);

export const clearCaseCreationError = createAction(
  '[Case] Clear caseCreationError'
);

export const clearCases = createAction('[Case] Clear all cases');

export const getCaseDetails = createAction(
  '[Case] Get Case Details',
  props<{ caseNumber: string }>()
);

export const getCaseDetailsSuccess = createAction(
  '[Case] Get Case Details Success',
  props<{ selectedCaseDetails: CaseDetailsModel }>()
);

export const getCaseDetailsFailure = createAction(
  '[Case] Get Case Details Failure',
  props<{ errorMsg: string }>()
);
export const createCaseFollowUp = createAction(
  '[Case] Create Case Follow-Up',
  props<{ caseFollowUp: CaseFollowUpModel }>()
);

export const resetCaseFollowUpNumber = createAction(
  '[Case] Reset Case Follow-Up Number'
);

export const createCaseFollowUpSuccess = createAction(
  '[Case] Create Case Follow-Up Success',
  props<{ createdCaseNumber: string }>()
);

export const createCaseFollowUpFailure = createAction(
  '[Case] Create Case Follow-Up Failure',
  props<{ errorMsg: string }>()
);

export const clearCaseFollowUpError = createAction(
  '[Case] Clear Case Follow-Up Error'
);

export const caseStatusStartLoading = createAction(
  '[Case] Start Case status Loading'
);

export const caseStatusStopLoading = createAction(
  '[Case] Stop Case status Loading'
);

export const getCaseTypeSummary = createAction(
  '[Case] Get Cases Type Summary',
  props<{ requestBody: BillingAccountsReqObj }>()
);

export const getCaseTypeSummarySuccess = createAction(
  '[Case] Get Cases Type Summary Success',
  props<{ caseTypeSummary: CaseTypeSummary[] }>()
);

export const getCaseTypeSummaryFailure = createAction(
  '[Case] Get Cases Type Summary Failure',
  props<{ errorMsg: string }>()
);
