import { AzureBaseService } from '@app/core/services/azure-functions/azure-base.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { OptionModel } from '@app/core/models/option.model';
import {
  CaseDetailsModel,
  CaseFollowUpModel,
  CaseModel,
  CasePublicModel,
  CaseTypeSummary,
} from '@app/core/models/case.model';
import {
  BillingAccountsReqObj,
  BillingAccountsDatesReqObj,
} from '@app/core/models/UserDataModel';

@Injectable({ providedIn: 'root' })
export class DataverseService extends AzureBaseService {
  constructor(protected http: HttpClient) {
    super(http, '/func-customerportal-dataverse-');
  }

  getCases(requestBody: BillingAccountsDatesReqObj): Observable<CaseModel[]> {
    const requestUrl = `${this.azureBaseUrl}${this.functionUri}${this.azureEnv}/cases`;

    return this.http
      .post<HttpResponse<any>>(requestUrl, requestBody, this.httpOptions)
      .pipe(map(response => JSON.parse(response.body) as CaseModel[]));
  }

  caseTypeGet(): Observable<OptionModel[]> {
    const requestUrl = `${this.azureBaseUrl}${this.functionUri}${this.azureEnv}/option/case/type`;

    return this.http
      .get<HttpResponse<any>>(requestUrl, this.httpOptions)
      .pipe(map(response => JSON.parse(response.body) as OptionModel[]));
  }

  caseStatusGet(): Observable<OptionModel[]> {
    const requestUrl = `${this.azureBaseUrl}${this.functionUri}${this.azureEnv}/option/case/status`;

    return this.http
      .get<HttpResponse<any>>(requestUrl, this.httpOptions)
      .pipe(map(response => JSON.parse(response.body) as OptionModel[]));
  }

  equipmentTypeGet(): Observable<OptionModel[]> {
    const requestUrl = `${this.azureBaseUrl}${this.functionUri}${this.azureEnv}/option/equipment/type`;

    return this.http
      .get<HttpResponse<any>>(requestUrl, this.httpOptions)
      .pipe(map(response => JSON.parse(response.body) as OptionModel[]));
  }

  equipmentSizeGet(): Observable<OptionModel[]> {
    const requestUrl = `${this.azureBaseUrl}${this.functionUri}${this.azureEnv}/option/equipment/size`;

    return this.http
      .get<HttpResponse<any>>(requestUrl, this.httpOptions)
      .pipe(map(response => JSON.parse(response.body) as OptionModel[]));
  }

  createCase(caseModel: CaseModel): Observable<string> {
    const url = `${this.azureBaseUrl}${this.functionUri}${this.azureEnv}/case/create`;

    return this.http
      .post<HttpResponse<any>>(url, caseModel, this.httpOptions)
      .pipe(map(response => JSON.parse(response.body) as string));
  }

  caseCreatePublic(casePublicModel: CasePublicModel): Observable<string> {
    const url = `${this.azureBaseUrl}${this.functionUri}${this.azureEnv}/opensite/case/create`;

    return this.http
      .post<HttpResponse<string>>(url, casePublicModel, this.httpOptions)
      .pipe(map(response => JSON.parse(response.body) as string));
  }

  getCaseDetails(caseNumber: string): Observable<CaseDetailsModel[]> {
    const requestUrl = `${this.azureBaseUrl}${this.functionUri}${this.azureEnv}/case/${caseNumber}/details`;

    return this.http
      .get<HttpResponse<any>>(requestUrl, this.httpOptions)
      .pipe(map(response => JSON.parse(response.body) as CaseDetailsModel[]));
  }

  createCaseFollowUp(caseFollowUpModel: CaseFollowUpModel): Observable<string> {
    const requestUrl = `${this.azureBaseUrl}${this.functionUri}${this.azureEnv}/case/followup`;

    return this.http
      .post<HttpResponse<any>>(requestUrl, caseFollowUpModel, this.httpOptions)
      .pipe(map(response => JSON.parse(response.body) as string));
  }

  getCasesTypeSummary(
    requestBody: BillingAccountsReqObj
  ): Observable<CaseTypeSummary[]> {
    const requestUrl = `${this.azureBaseUrl}${this.functionUri}${this.azureEnv}/casesstatus`;

    return this.http
      .post<HttpResponse<any>>(requestUrl, requestBody, this.httpOptions)
      .pipe(map(response => JSON.parse(response.body) as CaseTypeSummary[]));
  }
}
