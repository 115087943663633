import { createAction, props } from '@ngrx/store';
import { ContentfulModel } from '@app/core/models/contentful.model';

export const getWhatsNew = createAction('[Contentful] Get Whats New Content');

export const getWhatsNewSuccess = createAction(
  '[Contentful] Get Whats New Content Success',
  props<{ whatsNew: ContentfulModel }>()
);

export const getWhatsNewFailure = createAction(
  '[Contentful] Get Whats New Content Failure',
  props<{ errorMsg: string }>()
);

export const getTermsAndConditions = createAction(
  '[Contentful] Get Terms And Conditions Content'
);

export const getTermsAndConditionsSuccess = createAction(
  '[Contentful] Get Terms And Conditions Content Success',
  props<{ termsAndConditions: ContentfulModel }>()
);

export const getTermsAndConditionsFailure = createAction(
  '[Contentful] Get Terms And Conditions Content Failure',
  props<{ errorMsg: string }>()
);
export const getPrivacyPolicy = createAction(
  '[Contentful] Get Privacy Policy Content'
);

export const getPrivacyPolicySuccess = createAction(
  '[Contentful] Get Privacy Policy Content Success',
  props<{ privacyPolicy: ContentfulModel }>()
);

export const getPrivacyPolicyFailure = createAction(
  '[Contentful] Get Privacy Policy Content Failure',
  props<{ errorMsg: string }>()
);

export const markWhatsNewAsSeen = createAction(
  '[Contentful] Mark Whats New Content as Seen'
);
