<table>
  <tr class="loading-table-header">
    <th></th>
    <th></th>
    <th></th>
    <th></th>
    <th></th>
  </tr>
  <ng-container *ngFor="let i of [].constructor(6)">
    <tr class="loading-table-row">
      <td>
        <div class="loading-table-data"></div>
      </td>
      <td>
        <div class="loading-table-data"></div>
      </td>
      <td>
        <div class="loading-table-data"></div>
      </td>
      <td>
        <div class="loading-table-data"></div>
      </td>
      <td>
        <div class="loading-table-data"></div>
      </td>
    </tr>
  </ng-container>
</table>
