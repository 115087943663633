import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { SerializedRouterStateSnapshot } from '@ngrx/router-store';
import { InvoicesSelectors, RouterSelectors } from '@app/store';
import { Store } from '@ngrx/store';

import { AppState } from '@app/store/app.state';
import { InvoiceDetail } from '@app/core/api-azure-functions/dataverse-functions/model/invoice';

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss'],
})
export class InvoicesComponent {
  routerState$: Observable<SerializedRouterStateSnapshot> = this.store.select(
    RouterSelectors.getRouterState
  );

  invoiceDetails$: Observable<InvoiceDetail> = this.store.select(
    InvoicesSelectors.getInvoiceDetails
  );

  constructor(private store: Store<AppState>) {}
}
