import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'convertFrom24To12Pipe' })
export class ConvertFrom24To12Pipe implements PipeTransform {
  transform(time: any): any {
    if (time === null) {
      return '';
    }

    let hour = time.split(':')[0];
    const min = time.split(':')[1];
    const part = hour > 12 ? 'PM' : 'AM';

    hour = hour > 12 ? hour - 12 : hour;

    if (hour === '00') {
      hour = '12';
    }

    if (hour.toString().substr(0, 1) === '0') {
      hour = hour.substr(1);
    }

    return `${hour}:${min}${part}`;
  }
}
