export enum ServiceFrequency {
  'Inactive',
  'Suspended',
  'Weekly',
  'Bi-Weekly',
  'Once a Month',
  'On-Demand',
  'Pickup Open Top',
  'Deliver Open Top',
  'Switch Open Top',
  'Active',
}
