import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthActions, AuthSelectors } from '@app/store';
import { AppState } from '@app/store/app.state';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss'],
})
export class LandingPageComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription();
  constructor(
    private router: Router,
    private store: Store<AppState>
  ) {}

  rerouteOnClick(url: string) {
    this.router.navigate([url]);
  }

  ngOnInit(): void {
    const subscription = this.store
      .select(AuthSelectors.getUser)
      .subscribe(b2cAccountInfo => {
        if (b2cAccountInfo) {
          this.rerouteOnClick('dashboard');
        }
      });

    this.subscriptions.add(subscription);

    this.store.dispatch(AuthActions.getAllAuths());
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
