import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class FileService {
  constructor() { }

  // download any type of file
  downloadFile(blob: Blob, fileName: string = null) {
    // create <a> tag, add file info/file name, click to download
    const downloadLink = document.createElement('a');

    downloadLink.href = window.URL.createObjectURL(
      new Blob([blob], { type: blob.type })
    );
    downloadLink.download = fileName;
    downloadLink.click();
  }

  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  }

  formatBytes(bytes, decimals = 2) {
    if (!+bytes) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
  }

  createAndDownloadBlobFile(body, filename) {
    const byteArray = this.base64ToArrayBuffer(body);
    const blob = new Blob([byteArray]);
    const fileName = `${filename}`;
    const link = document.createElement('a');

    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);

      link.setAttribute('href', url);
      link.setAttribute('download', fileName);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
  base64ToArrayBuffer(base64: any) {
    const binaryString = window.atob(base64);
    const bytes = new Uint8Array(binaryString.length);

    return bytes.map((_byte, i) => binaryString.charCodeAt(i));
  }
}
