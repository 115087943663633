<app-wrapper-landing-login class="container-fluid p-0" [showSubNav]="false">
  <div class="page-container d-flex align-items-center button-wrapper">
    <div class="row button-container justify-content-center no-gutters">
      <button
        class="col-12 rts-filled-primary-button"
        (click)="rerouteOnClick('support')">
        Submit Ticket
      </button>
      <button
        class="col-12 rts-filled-button"
        (click)="rerouteOnClick('dashboard')">
        Sign In
      </button>
      <a href="terms-and-conditions">Terms and Conditions/ Privacy Policy</a>
    </div>
  </div>
</app-wrapper-landing-login>
